import React from "react";
import Cell from "./Cell";

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: props.gridContent,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.gridContent !== prevProps.gridContent) {
      this.setState({ gridData: this.props.gridContent });
    }
  }

  updateCell = (row, col, content) => {
    const newGridData = this.state.gridData.map((rowContent, rowIndex) => {
      if (rowIndex === row) {
        return rowContent.map((cellContent, colIndex) => {
          if (colIndex === col) {
            return content;
          }
          return cellContent;
        });
      }
      return rowContent;
    });
    this.setState({ gridData: newGridData });
  };

  getCell = (row, col) => {
    return this.state.gridData[row][col];
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {this.state.gridData.map((rowContent, rowIndex) => (
          <div key={rowIndex} style={{ display: "flex", gap: "10px" }}>
            {rowContent.map((cellContent, colIndex) => (
              <Cell
                key={`${rowIndex}-${colIndex}`}
                content={cellContent}
                onClick={() => this.props.onCellClick(rowIndex, colIndex)}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default Grid;

export const diagnosticCs = {
  diagnostics: {
    toolTips: {
      edit: "Upravit diagnostiku {diagnostic}",
      delete: "Odstranit diagnostiku {diagnostic}",
    },
    editTitle: "Upravit diagnostiku",
    newTitle: "Nová diagnostika",
    addDiagnostic: "Přidat diagnostiku",
    errors: {
      notFound: "Diagnostika id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit diagnostiku id {id}",
      nameExists: "Diagnostika s nazvem `{name}` již existuje",
      commonGET_DIAGNOSTIC_BY_ID: "Chyba při získávání dat o diagnosticu",
      commonDELETE_DIAGNOSTIC_BY_ID: "Chyba při odstraňování diagnosticu",
      commonDELETE_DIAGNOSTICS: "Nepodařilo se odstranit diagnostic",
      cantGetDiagnostics: "Chyba při získávání dat o diagnosticech",
    },
    form: {
      name: "Název",
      active: "Aktivní",
      diagnosticChanged: "Diagnostika byla upravena",
      diagnosticChangedFail: "Nepodařilo se upravit diagnostiku",
      diagnosticAdded: "Nová diagnostika byla vytvořena",
      diagnosticAddFail: "Nepodařilo se vytvořit novou diagnostiku",
    },
    table: {
      header: {
        name: "Název",
        active: "Aktivní",
        actions: "Akce",
      },
      deleteDiagnosticQuestion:
        "Opravdu chcete odstranit diagnostiku <strong>{diagnostic}</strong>?",
      searchDiagnostics: "Hledat diagnostiku",
      diagnosticDeleted: "Diagnostika {diagnostic} byla odstraněna",
      diagnosticsDeleted: "Diagnostiky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné diagnostiky",
    },
  },

  diagnostic: {
    common: {
      yes: "Ano",
      no: "Ne",
      value: "Hodnota",
      pickADay: "Den záznamu",
      fileName: "Název",
    },
    history: {
      table: {
        delete: "Odstranit hodnotu {value}",
        deletePrompt: "Opravdu chcete odstranit hodnotu {value} ?",
        header: {
          date: "Datum",
          value: "Hodnota",
          actions: "Akce",
        },
      },
    },
    import: {
      diagnosticImportBatchSuccess: "Data byla úspěšně importována",
    },
    export: {
      diagnosticExportSuccess: "Data byla úspěšně exportována",
    },
    toolTips: {
      s2board: { detail: "Zobrazit mezičasy" },
      "vision-energy": { detail: "Zobrazit výsledky úkolů" },
      "in-office": { detail: "Zobrazit výsledky úkolů" },
      eye_flexibility: { detail: "Zobrazit výsledky úkolů" },
      eye_stability: { detail: "Zobrazit výsledky úkolů" },
    },
    valueNotSet: "Tato hodnota nebyla ještě nastavena",
    addHistoricValueFor:
      "<span class='small'>Nový záznam pro hodnotu</span> {name}",
    listHistoricValueFor:
      "<span class='small'>Záznamy pro hodnotu</span> {name}",
    title: "Diagnostika",
    note: {
      title: "Poznámka",
    },
    anamnesis: {
      title: "Anamnéza",
    },
    documents: {
      title: "Dokumenty",
      values: {
        anamnesis_docs: {
          title: "Dokumenty",
        },
      },
    },
    dominance: {
      noData: "Žádné údaje k zobrazení",
      title: "Dominance",
      feelTheLayout: "Pocitově zvolte rozložení",
      common: {
        left: "L",
        right: "P",
        yes: "Mám",
        no: "Nemám",
        leftFull: "Levá",
        rightFull: "Pravá",
      },
      help:
        ' <h5 class="mt-10">Nápověda:</h5>\n' +
        '            <h6 class="mt-5">Oko</h6>\n' +
        "            <div>\n" +
        "              Namiřte ukazovákem na předmět přímo před Vámi. Poté zavřete jedno\n" +
        "              a druhé oko. Zaznamenejte, při kterém otevřeném oku, Vám zůstal\n" +
        "              prst mířit na zvolený předmět.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Ruka</h6>\n' +
        "            <div>\n" +
        "              Zaznamenejte ruku, kterou máte šikovnější na jemnou práci.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Noha</h6>\n' +
        "            <div>\n" +
        "              Zaznamenejte nohu, kterou byste jako první kopli do balonu.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Rytmus</h6>\n' +
        "            <div>Slyšíte rytmus v hudbě?</div>",
      values: {
        dominance_eye: {
          title: "Oko",
        },
        dominance_hand: {
          title: "Ruka",
        },
        dominance_leg: {
          title: "Noha",
        },
        dominance_ear: {
          title: "Rytmus",
        },
      },
    },
    s2board: {
      newMeasurement: "Nové měření",
      valueDetail: {
        title: "Dílčí časy jednotlivých úkolů",
      },
      resultDetail: {
        title: "Detail měření",
      },
      resultDescription:
        "<strong>Rychlost zraku</strong><br />\n" +
        "                Rychlost okohybných svalů + kognitivní dovednost<br />\n" +
        "<br />\n" +
        "                <strong>Přesnost zraku</strong><br />\n" +
        "                Přesnost okohybných svalů + kognitivní dovednost.<br />\n" +
        "<br />\n" +
        "                <strong>Neurovision</strong><br />Celková schopnost zraku a\n" +
        "                kognitivních dovedností získávat informace z okolí",
      noData: "Žádné údaje k zobrazení",
      measurementStored: "Nové měření uloženo",
      measurementStoredFailed: "Nepovedlo se uložit nové měření",
      yourResult: "Váš výsledek",
      title: "SensesBoard",
      task: "Úkol",
      tasks: {
        "focus-far-close": "Přeostření dálka blízko",
        "focus-side-top": "Stranové přeostření zhora",
        "focus-side-bottom": "Stranové přeostření zespodu",
        "lookup-from-one": "Vyheldávaní od 1",
        "lookup-random": "Vyhledávání od náhodného čísla",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        s2board_enabled: {
          title: "Povoleno",
        },
        s2board_measurements: {
          title: "Měření",
        },
      },
    },
    "vision-energy": {
      newMeasurement: "Nové měření",
      valueDetail: {
        title: "Dílčí časy jednotlivých úkolů",
      },
      noData: "Žádné údaje k zobrazení",
      measurementStored: "Nové měření uloženo",
      measurementStoredFailed: "Nepovedlo se uložit nové měření",
      yourResult: "Váš výsledek",
      title: "Diagnostika na dálku",
      task: "Úkol",
      words: "slov",
      seconds: "s",
      enterReadWords: "Zadejte počet přečtených slov",
      tasks: {
        task1: "Přeostření obě oči dálka - blízko",
        task2: "Přeostření pravé oko dálka - blízko",
        task3: "Přeostření levé oko dálka - blízko",
        task4: "Flipr pravé oko",
        task5: "Flipr levé oko",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        vision_energy_enabled: {
          title: "Povoleno",
        },
        vision_energy_measurements: {
          title: "Měření",
        },
      },
    },
    "potentials-legend": {
      sportLevel: "sportovní úroveň",
      commonLife: "pro běžný život",
      workOn: "je na čem pracovat",
      healthIssues: "funkční potíže",
      worldClass: "Světová úroveň",
      europeanClass: "Evropská úroveň",
      noteOne: "Hranice trénovaných a netrénovaných jedinců.",
      noteTwo: "Může způsobovat potíže v bězném životě.",
    },
    "in-office": {
      newMeasurement: "Nové měření",
      valueDetail: {
        title: "Dílčí časy jednotlivých úkolů",
      },
      resultDetail: {
        title: "Detail měření",
      },
      resultDescription:
        "                <strong>Okohybné svaly</strong><br />Schopnost zraku udržet pohled delší dobu na jednom místě a získávat informace.<br />\n" +
        "                <br />\n" +
        "<strong>Flexibilita</strong><br />\n" +
        "                Schopnost okohybných svalů přizpůsobovat se změně vzdálenosti pohledu.<br/>\n" +
        "                <br />\n" +
        "                <strong>Pravé oko</strong><br />\n" +
        "                Svalová rychlost pravého oka.<br />\n" +
        "                <br />\n" +
        "                <strong>Levé oko</strong><br />Svalová rychlost levého oka.",

      noData: "Žádné údaje k zobrazení",
      measurementStored: "Nové měření uloženo",
      measurementStoredFailed: "Nepovedlo se uložit nové měření",
      yourResult: "Váš výsledek",
      title: "Vyšetření funkčnosti okohybných svalů",
      task: "Úkol",
      words: "slov",
      seconds: "s",
      turns: "otočení",
      enterValue: "Zadejte naměřenou hodnotu",
      enterNrOfTurns: "Zadejte počet otočení",
      tasks: {
        task1: "Okohybné svaly do dálky",
        task2: "Okohybné svaly do blízka",
        task3: "Flexibilita",
        task4: "Pravé oko",
        task5: "Levé oko",
      },
      common: {
        left: "L",
        leftFull: "Levá",
        right: "P",
        rightFull: "Pravá",
      },
      values: {
        in_office_enabled: {
          title: "Povoleno",
        },
        in_office_measurements: {
          title: "Měření",
        },
      },
    },
    eye_flexibility: {
      newMeasurement: "Nové měření",
      valueDetail: {
        title: "Dílčí časy jednotlivých úkolů",
      },
      resultDetail: {
        title: "Detail měření",
      },
      resultDescription:
        "<strong>Flexibilita</strong><br />\n" +
        "                Schopnost okohybných svalů přizpůsobovat se změně vzdálenosti pohledu.<br/>\n" +
        "                <br />\n" +
        "                <strong>Pravé oko</strong><br />\n" +
        "                Svalová rychlost pravého oka.<br />\n" +
        "                <br />\n" +
        "                <strong>Levé oko</strong><br />Svalová rychlost levého oka.",
      noData: "Žádné údaje k zobrazení",
      measurementStored: "Nové měření uloženo",
      measurementStoredFailed: "Nepovedlo se uložit nové měření",
      yourResult: "Váš výsledek",
      title: "Měření rychlosti okohybnych svalů",
      task: "Úkol",
      words: "slov",
      seconds: "s",
      turns: "otočení",
      enterValue: "Zadejte naměřenou hodnotu",
      enterNrOfTurns: "Zadejte počet otočení",
      tasks: {
        task1: "Flexibilita",
        task2: "Pravé oko",
        task3: "Levé oko",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        eye_flexibility_enabled: {
          title: "Povoleno",
        },
        eye_flexibility_measurements: {
          title: "Měření",
        },
      },
    },
    eye_stability: {
      newMeasurement: "Nové měření",
      valueDetail: {
        title: "Dílčí časy jednotlivých úkolů",
      },
      resultDetail: {
        title: "Detail měření",
      },
      resultDescription:
        "<strong>Stabilita zraku</strong><br />\n" +
        "<span>Schopnost zraku udržet pohled delší dobu na jednom místě a získávat informace.</span>",
      noData: "Žádné údaje k zobrazení",
      measurementStored: "Nové měření uloženo",
      measurementStoredFailed: "Nepovedlo se uložit nové měření",
      yourResult: "Váš výsledek",
      title: "Měření stability zraku",
      task: "Úkol",
      words: "slov",
      seconds: "s",
      turns: "otočení",
      enterValue: "Zadejte naměřenou hodnotu",
      enterNrOfTurns: "Zadejte počet otočení",
      tasks: {
        task1: "Okohybné svaly do dálky",
        task2: "Okohybné svaly do blízka",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        eye_stability_enabled: {
          title: "Povoleno",
        },
        eye_stability_measurements: {
          title: "Měření",
        },
      },
    },
    neurovision: {
      noData: "Žádné údaje k zobrazení",
      title: "Neurovizuální diagnostika",
      common: {},
      resultDescription:
        "<strong>Krátkodobá paměť</strong><br />\n" +
        "                Uchovávání informací na krátkou dobu pro aktuální zpracování.<br/>\n" +
        "                <br />\n" +
        "                <strong>Efektivita zorného pole</strong><br />\n" +
        "                Schopnost vnímat a rozlišovat objekty v širokém vizuálním prostoru.<br />\n",
      values: {
        neurovision_ShorTerm_memory: {
          title: "Krátkodobá paměť",
        },
        neurovision_field_of_view_efficiency: {
          title: "Efektivita zorného pole",
        },
      },
    },
    coordination: {
      noData: "Žádné údaje k zobrazení",
      title: "Koordinace",
      common: {},
      resultDescription:
        "                <strong>Ruka oko</strong><br />\n" +
        "                Schopnost synchronizovat vizuální vnímání s motorickými pohyby rukou.<br />\n" +
        "                <br />\n" +
        "                <strong>Go/no go</strong><br />\n" +
        "                Schopnost rozhodování pod časovým tlakem.<br />\n",
      values: {
        coordination_reaction_time: {
          title: "Reakční čas",
        },
        coordination_hand_eye: {
          title: "Ruka oko",
        },
        coordination_gono_go: {
          title: "Go/no go",
        },
      },
    },
    concentration: {
      noData: "Žádné údaje k zobrazení",
      title: "Koncentrace",
      common: {},
      resultDescription:
        "                <strong>Koncentrace</strong><br />\n" +
        "                Schopnost udržet pozornost zaměřenou na konkrétní aktivitu.<br />\n",
      values: {
        concentration_concentration: {
          title: "Koncentrace",
        },
      },
    },
    hearing: {
      noData: "Žádné údaje k zobrazení",
      title: "Sluch - preventivní test sluchu",
      common: {},
      values: {
        hearing_left_ear: {
          title: "Levé ucho",
        },
        hearing_right_ear: {
          title: "Pravé ucho",
        },
      },
    },
    balance: {
      noData: "Žádné údaje k zobrazení",
      title: "Rovnováha",
      common: {},
      groups: {
        middleEar: "",
        rightLeg: "Pravá noha",
        leftLeg: "Levá noha",
        note: "",
      },
      values: {
        balance_middleEar_value: {
          title: "Střední ucho",
        },
        balance_rightLeg_opened_eyes: {
          title: "Otevřené oči",
        },
        balance_rightLeg_closed_eyes: {
          title: "Zavřené oči",
        },
        balance_leftLeg_opened_eyes: {
          title: "Otevřené oči",
        },
        balance_leftLeg_closed_eyes: {
          title: "Zavřené oči",
        },
        balance_note_value: {
          title: "Poznámka",
        },
      },
    },
    eyesight: {
      noData: "Žádné údaje k zobrazení",
      title: "Diagnostiky zraku",
      common: {},
      groups: {
        diopter: "Dioptrie",
        correction: "Nošená korekce",
        sharpness: "Ostrost",
        refocusing: "Přeostření",
        motility: "Motilita",
        accommodation: "Akomodace",
        flexibility: "Flexibilita",
        contrastSensitivity: "Kontrastní citlivost",
        muscles: "Svaly",
        other: "Ostatní",
      },
      values: {
        eyesight_diopter_right_eye: {
          title: "Pravé oko",
        },
        eyesight_diopter_left_eye: {
          title: "Levé oko",
        },

        eyesight_correction_glasses: {
          title: "Brýle",
        },
        eyesight_correction_glasses_reading: {
          title: "Brýle na čtení",
        },
        eyesight_correction_contact_lenses_soft: {
          title: "Kontaktní čočky měkké",
        },
        eyesight_correction_contact_lenses_hard: {
          title: "Kontaktní čočky tvrdé",
        },

        eyesight_sharpness_both_eyes: {
          title: "Obě oči",
        },
        eyesight_sharpness_right_eye: {
          title: "Pravé oko",
        },
        eyesight_sharpness_left_eye: {
          title: "Levé oko",
        },
        eyesight_sharpness_depth: {
          title: "Hloubka ostrosti",
        },

        eyesight_refocusing_both_eyes: {
          title: "Obě oči",
        },
        eyesight_refocusing_side: {
          title: "Stranové",
        },
        eyesight_refocusing_right_eye: {
          title: "Pravé oko",
        },
        eyesight_refocusing_left_eye: {
          title: "Levé oko",
        },

        eyesight_motility_both_eyes: {
          title: "Obě oči",
        },
        eyesight_motility_right_eye: {
          title: "Pravé oko",
        },
        eyesight_motility_left_eye: {
          title: "Levé oko",
        },

        eyesight_accommodation_both_eyes: {
          title: "Obě oči",
        },
        eyesight_accommodation_right_eye: {
          title: "Pravé oko",
        },
        eyesight_accommodation_left_eye: {
          title: "Levé oko",
        },

        eyesight_flexibility_both_eyes: {
          title: "Obě oči",
        },

        eyesight_contrastSensitivity_both_eyes: {
          title: "Obě oči",
        },
        eyesight_contrastSensitivity_right_eye: {
          title: "Pravé oko",
        },
        eyesight_contrastSensitivity_left_eye: {
          title: "Levé oko",
        },

        eyesight_muscles_far: {
          title: "Do dálky",
        },
        eyesight_muscles_close: {
          title: "Do blízka",
        },
        eyesight_other_system_understanding: {
          title: "Pochopení systému",
        },
      },
    },
  },
};

export const countryCs = {
  countries: {
    errors: {
      commonGET_COUNTRIES: "Chyba při získávání seznamu zemí",
    },
    nameOverides: {
      CZ: "Česká republika",
    },
  },
};

export const userGameDataEn = {
  userGameData: {
    errors: {
      cantGetUserGroups: "Chyba při získávání dat o skupinách",
    },
    table: {
      header: {
        name: "Název",
        level: "Level",
        actions: "Akce",
      },
      searchUserGroups: "Hledat skupiny",
      noRecords: "Nebyly nalezeny žádné skupiny",
    },
  },
};

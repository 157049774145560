<template>
  <div
    class="modal fade modal-fullscreen"
    id="common_modal"
    aria-modal="true"
    role="dialog"
    ref="fileSelectorModalRef"
  >
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="common_modal_header">
          <!--begin::Modal title-->
          <h2>{{ title }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17">
          <!--begin::Scroll-->
          <div
            class="scroll-y me-n7 pe-7"
            id="common_modal_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#common_modal_header"
            data-kt-scroll-wrappers="#common_modal_scroll"
            data-kt-scroll-offset="300px"
            v-html="body"
          ></div>
          <!--end::Scroll-->
        </div>
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <button
            type="button"
            id="kt_modal_new_address_cancel"
            class="btn btn-white me-3"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            {{ translate("back", "common") }}
          </button>
          <!--end::Button-->
        </div>
        <!--end::Modal body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

export default defineComponent({
  name: "common-modal",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const translate = (text: string, root?: string): string => {
      text = (root !== undefined ? root + "." : "common.") + text;
      if (text) {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      } else {
        return "";
      }
    };
    const title = computed(() => {
      return store.getters.getModalTitle;
    });

    const body = computed(() => {
      return store.getters.getModalBody;
    });
    return {
      title,
      body,
      translate,
    };
  },
});
</script>

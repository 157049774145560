import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Exercise } from "@/store/interfaces/Exercise";

@Module
export default class ExerciseModule extends VuexModule {
  redirectTo = "";
  loadedExercise: Exercise = {} as Exercise;
  Exercises: Exercise[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedExercise(): Exercise {
    return this.loadedExercise.id
      ? this.loadedExercise
      : ({
          ...this.loadedExercise,
          ...{
            image: this.loadedExercise.image ? this.loadedExercise.image : {},
            icon: this.loadedExercise.icon ? this.loadedExercise.icon : {},
            video: this.loadedExercise.video ? this.loadedExercise.video : {},
            color: this.loadedExercise.color
              ? this.loadedExercise.color
              : "#1f87e8", //dedede
            accessories: this.loadedExercise.accessories
              ? this.loadedExercise.accessories
              : [],
            exercise_categories: this.loadedExercise.exercise_categories
              ? this.loadedExercise.exercise_categories
              : [],
            sports: this.loadedExercise.sports
              ? this.loadedExercise.sports
              : [],
            resources: this.loadedExercise.resources
              ? this.loadedExercise.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    client_name: "",
                    description: "",
                    exercise_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as Exercise);
  }

  /**
   * Retreive current loaded exercises
   */
  get getExercises(): Exercise[] {
    return this.Exercises;
  }

  /**
   * Get last pagination setup
   */
  get getExercisesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getExercisesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_EXERCISE](Exercise: Exercise) {
    this.loadedExercise = Exercise;
  }

  @Mutation
  [Mutations.SET_EXERCISES](data) {
    this.Exercises = data.data;
  }

  @Mutation
  [Mutations.SET_EXERCISES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_EXERCISES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_EXERCISES](payload) {
    ApiService.setHeader();
    return new Promise<Exercise[]>((resolve, reject) => {
      ApiService.post("exercises", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_EXERCISES, data["exercises"]);
            this.context.commit(
              Mutations.SET_EXERCISES_PAGINATION,
              data["exercises"].pagination
            );
            resolve(data["exercises"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exercises.errors.cantGetExercises"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_EXERCISE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Exercise>((resolve) => {
        const Exercise = {} as Exercise;
        this.context.commit(Mutations.SET_LOADED_EXERCISE, Exercise);
        resolve(Exercise);
      });
    }
    return new Promise<Exercise>((resolve, reject) => {
      ApiService.get(`exercise/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE,
              data["exercise"]
            );
            resolve(data["exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exercises.errors.commonGET_EXERCISE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EXERCISE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Exercise>((resolve, reject) => {
      ApiService.delete(`exercise/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE,
              data["exercise"]
            );
            resolve(data["exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exercises.errors.commonDELETE_EXERCISE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EXERCISE](Exercise: Exercise) {
    const payload = JSON.parse(JSON.stringify(Exercise));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`exercise/${Exercise.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE,
              data["exercise"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Exercise.id,
              code: Exercise.code,
              name: Exercise.code,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exercises.form.exerciseChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EXERCISE](Exercise: Exercise) {
    const payload = JSON.parse(JSON.stringify(Exercise));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`exercise`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE,
              data["exercise"]
            );
            resolve(data["exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Exercise.id,
              code: Exercise.code,
              name: Exercise.code,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exercises.form.exerciseAddFail"
          );
          reject();
        });
    });
  }
}

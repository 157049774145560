export const bodyPartSk = {
  bodyParts: {
    title: "Časti tela",
    toolTips: {
      edit: "Upraviť časť tela {bodyPart}",
      delete: "Odstrániť časť tela {bodyPart}",
    },
    editTitle: "Upraviť časť tela",
    newTitle: "Nová časť tela",
    addBodyPart: "Pridať časť tela",
    errors: {
      notFound: "Časť tela s id {id} nebola nájdená",
      notYourRole: "Nemáte práva zobraziť časť tela s id {id}",
      commonGET_BODY_PART_BY_ID: "Chyba pri získávaní údajov o častiach tela",
      commonDELETE_BODY_PART_BY_ID: "Chyba pri odstraňovaní časti tela",
      commonDELETE_BODY_PARTS: "Nepodarilo sa odstrániť časť tela",
      cantGetBodyParts: "Chyba pri získávaní údajov o častiach tela",
    },
    form: {
      addBodyPart: "Pridať časť tela",
      name: "Názov",
      company: "Firmy",
      bodyPartChanged: "Časť tela bola upravená",
      bodyPartChangedFail: "Nepodarilo sa upraviť časť tela",
      bodyPartAdded: "Nová časť tela bola vytvorená",
      bodyPartAddFail: "Nepodarilo sa vytvoriť novú časť tela",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firmy",
        actions: "Akcie",
      },
      deleteBodyPartQuestion:
        "Naozaj chcete odstrániť časť tela <strong>{bodyPart}</strong>?",
      searchBodyParts: "Hľadať časť tela",
      bodyPartDeleted: "Časť tela {bodyPart} bola odstránená",
      bodyPartsDeleted: "Časti tela boli odstránené",
      noRecords: "Neboli nájdené žiadne časti tela",
    },
  },
};

<style lang="scss"></style>
<template>
  <MetronomeComponent
    :bpm-min="35"
    :bpm-max="200"
    :beats-per-minute="prepareSettings(tool)['bpm']"
    :volume="getVolume()"
  ></MetronomeComponent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tool } from "@/store/interfaces/Tool";
import { useToolFormater } from "@/components/tools/composables/toolFormater";
import MetronomeComponent from "@/components/tools/_measurements/components/MetronomeComponent.vue";

export default defineComponent({
  name: "metronome-tool-",
  props: {
    tool: {
      type: Object as () => Tool,
      required: true,
    },
  },
  emits: ["game-finished"],
  components: { MetronomeComponent },
  setup(props, { emit }) {
    const { prepareSettings, printName } = useToolFormater(props.tool);
    const getVolume = () => {
      const volumePercent = prepareSettings(props.tool)["volume"]
        ? prepareSettings(props.tool)["volume"]
        : 75;

      return 2 * (volumePercent / 100);
    };

    return { prepareSettings, printName, getVolume };
  },
});
</script>

// ColorWordMatchGame.js

import React, { useState, useEffect, useRef } from "react";

const colors = ["Red", "Green", "Blue", "Black"];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

const ColorWordMatchGame = ({ onGameEnd }) => {
  const [leftColorName, setLeftColorName] = useState(getRandomColor());
  const [rightColorName, setRightColorName] = useState(getRandomColor());
  const [rightColorText, setRightColorText] = useState(getRandomColor());
  const [feedback, setFeedback] = useState("");
  const [score, setScore] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);

  const totalTime = 30;
  const [timer, setTimer] = useState(totalTime);
  const timerIdRef = useRef(null);

  const nextRound = () => {
    setLeftColorName(getRandomColor());
    setRightColorName(getRandomColor());
    setRightColorText(getRandomColor());
    setFeedback("");
  };

  useEffect(() => {
    // Set up keypress event listener
    const handleKeyPress = (event) => {
      if (feedback === "") {
        // Only proceed if there's no current feedback, indicating a delay
        if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
          const correctAnswer = leftColorName === rightColorName;
          const userAnswer = event.key === "ArrowRight";

          if (userAnswer === correctAnswer) {
            setScore(score + 1);
            setRightAnswers(rightAnswers + 1);
            setFeedback("Correct!");
          } else {
            setWrongAnswers(wrongAnswers + 1);
            setFeedback("Wrong!");
          }

          setTimeout(nextRound, 1000); // Delay before the next round
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [leftColorName, rightColorText, score, feedback]);

  useEffect(() => {
    if (timer > 0) {
      timerIdRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerIdRef.current);
      onGameEnd({
        games: [
          {
            id: "ColorWordMatchGame",
            score,
            right: rightAnswers,
            wrong: wrongAnswers,
          },
        ],
      });
    }

    return () => clearInterval(timerIdRef.current);
  }, [timer]);

  const timeBarWidth = (timer / totalTime) * 100;

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            width: "120px",
            height: "20px",
            borderRadius: "5px",
            backgroundColor: "#EFEFEF",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${timeBarWidth}%`,
              backgroundColor: "#368f58",
              transition: "width 1s linear",
            }}
          ></div>
        </div>
        <div>Score: {score}</div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "32px",
          marginBottom: "10px",
          borderRadius: "5px",
          backgroundColor: "#EFEFEF",
          padding: "15px",
        }}
      >
        <span
          style={{
            flex: 1,
            color: "black",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {leftColorName}
        </span>
        <span
          style={{
            flex: 1,
            color: rightColorName,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {rightColorText}
        </span>
      </div>

      {feedback && (
        <div
          style={{
            fontSize: "32px",
            color: feedback === "Correct!" ? "green" : "red",
            marginTop: "20px",
          }}
        >
          {feedback}
        </div>
      )}
    </div>
  );
};

export default ColorWordMatchGame;

import React, { useRef } from "react";
import { useRememberTheGridGame } from "./useRememberTheGridGame";
import { presets } from "./presets";

const RememberTheGridGame = ({ onGameEnd, settings }) => {
  const gameSettings = { ...settings, ...presets[settings.level - 1] };
  const gridRef = useRef(null);
  const { gridComponent, showStartButton, startGame, isRunning, targetLetter } =
    useRememberTheGridGame(gameSettings, onGameEnd, gridRef);

  return (
    <div className="App">
      <h1>Krátkodobá paměť (Pamatuj si písmeno)</h1>

      {gridComponent}

      {showStartButton && <button onClick={startGame}>Start Game</button>}
      {isRunning && !showStartButton && (
        <div>
          <h2>Najdi písmeno:</h2>
          <h2>{targetLetter}</h2>
        </div>
      )}
    </div>
  );
};

export default RememberTheGridGame;

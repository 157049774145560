import React from "react";
import { usePressAllMultipleGame } from "./usePressAllMultipleGame";
import Variations from "./variations";
import { presets } from "@/react_app/games/Games/RememberTheGridGame/presets";

const PressAllMultipleGame = ({ onGameEnd, settings }) => {
  const gameSettings = { ...settings, ...{ variation: Variations.Symbols } };
  const {
    gridComponent,
    showStartButton,
    handleStartGame,
    currentVariation,
    timer,
  } = usePressAllMultipleGame(gameSettings, onGameEnd);

  return (
    <div className="App">
      <h1>Rychlé dlaždice</h1>

      {gridComponent}

      {showStartButton && <button onClick={handleStartGame}>Start Game</button>}

      {!showStartButton && currentVariation && (
        <div>
          <h2>Zmáčkněte všechna pole obsahující:</h2>
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor:
                settings.variation === Variations.Colors
                  ? currentVariation
                  : "transparent",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "24px",
            }}
          >
            {settings.variation !== Variations.Colors ? currentVariation : ""}
          </div>
        </div>
      )}

      {!showStartButton && <h2>Zbývá čas: {timer}</h2>}
    </div>
  );
};

export default PressAllMultipleGame;

export const specializationSk = {
  specializations: {
    toolTips: {
      edit: "Upraviť špecializáciu {specialization}",
      delete: "Odstrániť špecializáciu {specialization}",
    },
    editTitle: "Upraviť špecializáciu",
    newTitle: "Nová špecializácia",
    addSpecialization: "Pridať špecializáciu",
    errors: {
      notFound: "Špecializácia s ID {id} nebola nájdená",
      notYourRole: "Nemáte oprávnenie zobraziť špecializáciu s ID {id}",
      nameExists: "Špecializácia s názvom `{name}` už existuje",
      commonGET_SPECIALIZATION_BY_ID:
        "Chyba pri získavaní údajov o špecializácii",
      commonDELETE_SPECIALIZATION_BY_ID: "Chyba pri odstraňovaní špecializácie",
      commonDELETE_SPECIALIZATIONS: "Nepodarilo sa odstrániť špecializácie",
      cantGetSpecializations: "Chyba pri získavaní údajov o špecializáciách",
    },
    form: {
      name: "Názov",
      company: "Firma",
      specializationChanged: "Špecializácia bola upravená",
      specializationChangedFail: "Nepodarilo sa upraviť špecializáciu",
      specializationAdded: "Nová špecializácia bola vytvorená",
      specializationAddFail: "Nepodarilo sa vytvoriť novú špecializáciu",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firma",
        actions: "Akcie",
      },
      deleteSpecializationQuestion:
        "Naozaj chcete odstrániť špecializáciu <strong>{specialization}</strong>?",
      searchSpecializations: "Hľadať špecializácie",
      specializationDeleted: "Špecializácia {specialization} bola odstránená",
      specializationsDeleted: "Špecializácie boli odstránené",
      noRecords: "Neboli nájdené žiadne špecializácie",
    },
  },
};

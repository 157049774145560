export const specializationEn = {
  specializations: {
    toolTips: {
      edit: "Edit specialization {specialization}",
      delete: "Delete specialization {specialization}",
    },
    editTitle: "Edit specialization",
    newTitle: "New specialization",
    addSpecialization: "Add specialization",
    errors: {
      notFound: "Specialization {id} not found",
      notYourRole: "Cant access data for specialization {id}",
      nameExists: "Specialization named `{name}` already exists",
      commonGET_SPECIALIZATION_BY_ID: "Error retrieving specialization data",
      commonDELETE_SPECIALIZATION_BY_ID: "Error deleting specialization",
      commonDELETE_SPECIALIZATIONS: "Error deleting specializations",
      cantGetSpecializations: "Failed to fetch specializations data",
    },
    form: {
      name: "Name",
      company: "Company",
      specializationChanged: "Specialization was stored",
      specializationChangedFail: "Failed to store specialization",
      specializationAdded: "New specialization was created",
      specializationAddFail: "Failed to add new specialization",
    },
    table: {
      header: {
        name: "Name",
        company: "Company",
        actions: "Actions",
      },
      deleteSpecializationQuestion:
        "Do you really want to delete specialization <strong>{specialization}</strong>?",
      searchSpecializations: "Search specializations",
      specializationDeleted: "Specialization {specialization} was deleted",
      specializationsDeleted: "Specializations were deleted",
      noRecords: "No specializations found",
    },
  },
};

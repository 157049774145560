export const accessoryEn = {
  accessories: {
    title: "Accessories",
    toolTips: {
      edit: "Edit accessory {accessory}",
      delete: "Delete accessory {accessory}",
    },
    editTitle: "Edit accessory",
    newTitle: "New accessory",
    addAccessory: "Add accessory",
    errors: {
      notFound: "Accessory {id} not found",
      notYourRole: "Cant access data for accessory {id}",
      nameExists: "Accessory named `{name}` already exists",
      commonGET_ACCESSORY_BY_ID: "Error retrieving accessory data",
      commonDELETE_ACCESSORY_BY_ID: "Error deleting accessory",
      commonDELETE_ACCESSORIES: "Error deleting accessories",
      cantGetAccessories: "Failed to fetch accessories data",
    },
    form: {
      addAccesory: "Add accessory",
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      code: "Code",
      description: "Description",
      image: "Image",
      icon: "Icon",
      company: "Companies",
      programs: "Products",
      accessoryChanged: "Accessory was stored",
      accessoryChangedFail: "Failed to store accessory",
      accessoryAdded: "New accessory was created",
      accessoryAddFail: "Failed to add new accessory",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        icon: "Icon",
        programs: "Products",
        company: "Companies",
        actions: "Actions",
      },
      deleteAccessoryQuestion:
        "Do you really want to delete  accessory <strong>{accessory}</strong>?",
      searchAccessories: "Search accessories",
      accessoryDeleted: "Accessory {accessory} was deleted",
      accessoriesDeleted: "Accessories were deleted",
      noRecords: "No accessories found",
    },
  },
};

export const specializationCs = {
  specializations: {
    toolTips: {
      edit: "Upravit specializaci {specialization}",
      delete: "Odstranit specializaci {specialization}",
    },
    editTitle: "Upravit specializaci",
    newTitle: "Nová specializace",
    addSpecialization: "Přidat specializaci",
    errors: {
      notFound: "Specializace id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit specializaci id {id}",
      nameExists: "Specializace s nazvem `{name}` již existuje",
      commonGET_SPECIALIZATION_BY_ID: "Chyba při získávání dat o specializaci",
      commonDELETE_SPECIALIZATION_BY_ID: "Chyba při odstraňování specializace",
      commonDELETE_SPECIALIZATIONS: "Nepodařilo se odstranit specializace",
      cantGetSpecializations: "Chyba při získávání dat o specializacích",
    },
    form: {
      name: "Název",
      company: "Firma",
      specializationChanged: "Specializace byla upravena",
      specializationChangedFail: "Nepodařilo se upravit specializaci",
      specializationAdded: "Nová specializace byla vytvořena",
      specializationAddFail: "Nepodařilo se vytvořit novou specializaci",
    },
    table: {
      header: {
        name: "Název",
        company: "Firma",
        actions: "Akce",
      },
      deleteSpecializationQuestion:
        "Opravdu chcete odstranit specializaci <strong>{specialization}</strong>?",
      searchSpecializations: "Hledat specializace",
      specializationDeleted: "Specializace {specialization} byla odstraněna",
      specializationsDeleted: "Specializace byly odstraněny",
      noRecords: "Nebyly nalezeny žádné specializace",
    },
  },
};

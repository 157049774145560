export const programSk = {
  programs: {
    toolTips: {
      edit: "Upraviť produkt {program}",
      delete: "Odstrániť produkt {program}",
      showEshopProduct: "Zobraziť naviazaný produkt eshopu",
    },
    editTitle: "Upraviť produkt",
    newTitle: "Nový produkt",
    addProgram: "Pridať produkt",
    errors: {
      notFound: "Produkt s ID {id} nebol nájdený",
      notYourRole: "Nemáte právo zobraziť produkt s ID {id}",
      nameExists: "Produkt s názvom `{name}` už existuje",
      commonGET_PROGRAM_BY_ID: "Chyba pri získavaní údajov o produkte",
      commonDELETE_PROGRAM_BY_ID: "Chyba pri odstraňovaní produktu",
      commonDELETE_PROGRAMS: "Nepodarilo sa odstrániť produkt",
      cantGetPrograms: "Chyba pri získávaní údajov o produktoch",
    },
    form: {
      name: "Názov",
      name_client: "Názov pre klienta",
      nrOfWeeks: "Dĺžka programu",
      weeks: "týždňov",
      wcProduct: "Eshop produkt",
      company: "Firma",
      specializations: "Specializácie",
      trainings: "Tréningy",
      programChanged: "Produkt bol upravený",
      programChangedFail: "Nepodarilo sa upraviť produkt",
      programAdded: "Nový produkt bol vytvorený",
      programAddFail: "Nepodarilo sa vytvoriť nový produkt",
    },
    table: {
      header: {
        name: "Názov",
        name_client: "Názov pre klienta",
        nrOfWeeks: "Dĺžka programu",
        company: "Firma",
        specializations: "Specializácie",
        actions: "Akcie",
      },
      deleteProgramQuestion:
        "Naozaj chcete odstrániť produkt <strong>{program}</strong>?",
      searchPrograms: "Hľadať produkty",
      programDeleted: "Produkt {program} bol odstránený",
      programsDeleted: "Produkty boli odstránené",
      noRecords: "Neboli nájdené žiadne produkty",
    },
  },
};

export const userContactEventsSk = {
  userContactEvents: {
    modal: {
      title: "Záznamy o kontaktovaní používateľa",
      last30: "Posledných 30 záznamov",
    },
    toolTips: {
      contact: "Záznam o kontaktovaní používateľa {name}",
    },
    errors: {
      cantAddUserContactEvent:
        "Chyba pri ukladaní záznamu o kontaktovaní používateľa",
      cantGetUserContactEvents:
        "Chyba pri získavaní údajov o kontaktovaní používateľa",
    },
    reasons: {
      "not-in-training": "nie je v tréningu",
      "after-diagnostic": "po diagnostike",
      "ending-program": "končiaci program",
      "order-diagnostic": "objednanie na diagnostiku",
      leads: "potenciálni",
      "scheduled-to-contact": "plánované kontaktovanie",
      "training-renewal": "obnovení tréninku",
    },
    via: {
      phone: "telefónom",
      email: "e-mailom",
      in_person: "osobne",
      not_contacted: "nebyl kontaktován",
    },
    title: "Záznam o kontaktovaní používateľa {name}.",
    form: {
      userContactEventAdded: "Záznam o kontaktovaní používateľa uložený",
      reason: "Dôvod",
      contacted_via: "Kontaktovaný",
      result: "Výsledok",
      nextContactEvent: "Kontaktovať znova",
      nextDay: "dňa",
      note: "poznámka",
    },
  },
};

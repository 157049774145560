export const trainingEventCs = {
  trainingEvents: {
    errors: {
      cantGetSchedule: "Nepodařilo se získat rozvrh tréninku",
      cantGetTrainingEventOverride:
        "Nepodařilo se změnit data pro tréninkový týden",
    },
    saveNote: "Uložit poznámku",
    saveSleepDiary: "Uložit spánkový deník",
    noteChanged: "Poznámka byla upravena",
    contentChanged: "Obsah byl upraven",
    sleepDiaryChanged: "Spánkový deník byl upraven",
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  DashboardData,
  DashboardModuleItem,
} from "@/store/interfaces/Dashboard";
import { Country } from "@/store/interfaces/Country";

@Module
export default class DashboardModule extends VuexModule {
  dashboardModules: DashboardModuleItem[] = [];
  loadedDashboard: DashboardData = {} as DashboardData;
  dashboardLoading = false;

  get isDashboardLoading(): boolean {
    return this.dashboardLoading;
  }
  get getLoadedDashboard(): DashboardData {
    return this.loadedDashboard;
  }

  get getDashboardModules(): DashboardModuleItem[] {
    return this.dashboardModules;
  }

  @Mutation
  [Mutations.SET_LOADED_DASHBOARD_MODULES](
    DashboardModules: DashboardModuleItem[]
  ) {
    this.dashboardModules = DashboardModules;
  }
  @Mutation
  [Mutations.SET_LOADED_DASHBOARD](Dashboard: DashboardData) {
    this.loadedDashboard = Dashboard;
  }
  @Mutation
  [Mutations.SET_DASHBOARD_LOADING](value: boolean) {
    this.dashboardLoading = value;
  }

  @Action
  [Actions.LOAD_DASHBOARD_MODULES]() {
    return new Promise<DashboardModuleItem[]>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`dashboard-modules`, {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DASHBOARD_MODULES,
              data.dashboard_modules.data
            );
            resolve(data.dashboard_modules.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "dashboard.errors.cantGetDashboardModules"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DASHBOARD](params: {
    company_id: number | null;
    group_id: number | null;
  }) {
    ApiService.setHeader();
    return new Promise<DashboardData>((resolve, reject) => {
      ApiService.post(`dashboard`, params)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DASHBOARD,
              data["dashboard"]
            );
            resolve(data["body_part"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "dashboard.errors.cantGetDashboard"
          );
          reject();
        });
    });
  }
}

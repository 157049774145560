import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Sport } from "@/store/interfaces/Sport";

@Module
export default class SportModule extends VuexModule {
  redirectTo = "";
  loadedSport: Sport = {} as Sport;
  Sports: Sport[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedSport(): Sport {
    return { ...this.loadedSport } as Sport;
  }

  /**
   * Retreive current loaded sports
   */
  get getSports(): Sport[] {
    return this.Sports;
  }

  /**
   * Get last pagination setup
   */
  get getSportsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getSportsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_SPORT](Sport: Sport) {
    this.loadedSport = Sport;
  }

  @Mutation
  [Mutations.SET_SPORTS](data) {
    this.Sports = data.data;
  }

  @Mutation
  [Mutations.SET_SPORTS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_SPORTS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_SPORTS](payload) {
    ApiService.setHeader();
    return new Promise<Sport[]>((resolve, reject) => {
      ApiService.post("sports", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_SPORTS, data["sports"]);
            this.context.commit(
              Mutations.SET_SPORTS_PAGINATION,
              data["sports"].pagination
            );
            resolve(data["sports"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "sports.errors.cantGetSports"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_SPORT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Sport>((resolve) => {
        const Sport = {} as Sport;
        this.context.commit(Mutations.SET_LOADED_SPORT, Sport);
        resolve(Sport);
      });
    }
    return new Promise<Sport>((resolve, reject) => {
      ApiService.get(`sport/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_SPORT, data["sport"]);
            resolve(data["sport"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "sports.errors.commonGET_SPORT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SPORT_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Sport>((resolve, reject) => {
      ApiService.delete(`sport/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_SPORT, data["sport"]);
            resolve(data["sport"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "sports.errors.commonDELETE_SPORT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SPORT](Sport: Sport) {
    const payload = JSON.parse(JSON.stringify(Sport));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`sport/${Sport.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_SPORT, data["sport"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Sport.id,
              name: Sport.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "sports.form.sportChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_SPORT](Sport: Sport) {
    const payload = JSON.parse(JSON.stringify(Sport));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`sport`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_SPORT, data["sport"]);
            resolve(data["sport"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Sport.id,
              name: Sport.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "sports.form.sportAddFail");
          reject();
        });
    });
  }
}

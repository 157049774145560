import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { TrainingTemplatePeriod } from "@/store/interfaces/TrainingTemplate";

@Module
export default class TrainingTemplatePeriodModule extends VuexModule {
  redirectTo = "";
  loadedTrainingTemplatePeriod: TrainingTemplatePeriod =
    {} as TrainingTemplatePeriod;
  TrainingTemplatePeriods: TrainingTemplatePeriod[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedTrainingTemplatePeriod(): TrainingTemplatePeriod {
    return this.loadedTrainingTemplatePeriod.id
      ? this.loadedTrainingTemplatePeriod
      : ({
          ...this.loadedTrainingTemplatePeriod,
          ...{
            exercises: [],
            is_template: false,
          },
        } as TrainingTemplatePeriod);
  }

  /**
   * Retreive current loaded trainingTemplates
   */
  get getTrainingTemplatePeriods(): TrainingTemplatePeriod[] {
    return this.TrainingTemplatePeriods;
  }

  /**
   * Get last pagination setup
   */
  get getTrainingTemplatePeriodsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getTrainingTemplatePeriodsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD](
    trainingTemplatePeriod: TrainingTemplatePeriod
  ) {
    this.loadedTrainingTemplatePeriod = trainingTemplatePeriod;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATE_PERIODS](data) {
    this.TrainingTemplatePeriods = data.data;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATE_PERIODS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATE_PERIODS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_TRAINING_TEMPLATE_PERIODS](payload) {
    ApiService.setHeader();
    return new Promise<TrainingTemplatePeriod[]>((resolve, reject) => {
      ApiService.post("training-template-periods", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_TRAINING_TEMPLATE_PERIODS,
              data["training-template-periods"]
            );
            this.context.commit(
              Mutations.SET_TRAINING_TEMPLATE_PERIODS_PAGINATION,
              data["training-template-periods"].pagination
            );
            resolve(data["training-template-periods"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.periods.errors.cantGetTrainingTemplatePeriods"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TRAINING_TEMPLATE_PERIOD_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<TrainingTemplatePeriod>((resolve) => {
        const trainingTemplatePeriod = {} as TrainingTemplatePeriod;
        this.context.commit(
          Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD,
          trainingTemplatePeriod
        );
        resolve(trainingTemplatePeriod);
      });
    }
    return new Promise<TrainingTemplatePeriod>((resolve, reject) => {
      ApiService.get(`training-template-period/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD,
              data["training-template-period"]
            );
            resolve(data["training-template-period"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.periods.errors.commonGET_TRAINING_TEMPLATE_PERIOD_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TRAINING_TEMPLATE_PERIOD_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TrainingTemplatePeriod>((resolve, reject) => {
      ApiService.delete(`training-template-period/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD,
              data["training-template-period"]
            );
            resolve(data["training-template-period"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.periods.errors.commonDELETE_TRAINING_TEMPLATE_PERIOD_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TRAINING_TEMPLATE_PERIOD](
    TrainingTemplatePeriod: TrainingTemplatePeriod
  ) {
    const payload = JSON.parse(JSON.stringify(TrainingTemplatePeriod));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `training-template-period/${TrainingTemplatePeriod.id}`,
        payload
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD,
              data["training-template-period"]
            );
            resolve();
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TrainingTemplatePeriod.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.periods.form.trainingTemplatePeriodChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TRAINING_TEMPLATE_PERIOD](
    TrainingTemplatePeriod: TrainingTemplatePeriod
  ) {
    const payload = JSON.parse(JSON.stringify(TrainingTemplatePeriod));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`training-template-period`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE_PERIOD,
              data["training-template-period"]
            );
            resolve(data["training-template-period"]);
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TrainingTemplatePeriod.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.periods.form.trainingTemplatePeriodAddFail"
          );
          reject();
        });
    });
  }
}

import { Tool } from "@/store/interfaces/Tool";
import { useTranslate } from "@/composables/translate";

export function useToolFormater(tool?: Tool) {
  const { getObjectResourceValueByLang } = useTranslate();

  /**
   * Retrieves and prints the name of a tool, prioritizing the client name if in client view mode.
   * @param clientView A flag indicating whether to prioritize the client name.
   * @returns The name of the tool to be printed.
   */
  const printName = (clientView = true, inTool?: Tool): string => {
    const realTool =
      tool !== undefined ? tool : inTool !== undefined ? inTool : null;
    if (realTool) {
      if (clientView) {
        const clientName = getObjectResourceValueByLang(
          realTool,
          "client_name"
        );
        return clientName || getObjectResourceValueByLang(realTool, "name");
      } else {
        return getObjectResourceValueByLang(realTool, "name");
      }
    }
    return "";
  };

  /**
   * Prepares settings for a given tool by filtering out keys that start with '#', and returning a new object with modified keys.
   * @param tool The tool object containing settings.
   * @returns A new object with modified keys.
   */
  const prepareSettings = (tool: Tool): object => {
    const settings = tool.pivot?.settings ? tool.pivot.settings : tool.settings;
    const newSettings = {};
    for (const setting in settings) {
      if (setting.startsWith("#")) {
        const splitted = setting.split("_");
        if (splitted.length > 1) {
          newSettings[splitted[1]] = settings[setting];
        }
      }
    }
    return newSettings;
  };

  return { printName, prepareSettings };
}

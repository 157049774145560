import Quill from "quill/dist/quill.js";

let Inline = Quill.import("blots/inline");

export class File extends Inline {
  static create(values) {
    let node = super.create(values);
    if (values && values.file) {
      node.setAttribute(
        "class",
        "btn file-content-icon file-icon--type-" + values.file.type
      );
      node.setAttribute("data-file-id", values.file.id);
      node.setAttribute("data-file-type", values.file.type);
      node.setAttribute("data-file-name", values.file.name);
      node.setAttribute(
        "data-file-original_file_name",
        values.file.original_file_name
      );
      node.setAttribute("data-file-path", values.file.path);
    }
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("class");
  }

  format(name, values) {
    if (name !== this.statics.blotName || !values)
      return super.format(name, values);
    if (values) {
      this.domNode.setAttribute(
        "class",
        "btn file-content-icon file-icon--type-" + values.file.type
      );
      this.domNode.setAttribute("data-file-id", values.file.id);
      this.domNode.setAttribute("data-file-type", values.file.type);
      this.domNode.setAttribute("data-file-name", values.file.name);
      this.domNode.setAttribute(
        "data-file-original_file_name",
        values.file.original_file_name
      );
      this.domNode.setAttribute("data-file-path", values.file.path);
    }
  }
}

export const declensionCs = {
  declensions: {
    day: {
      1: "den",
      2: "dny",
      3: "dny",
      4: "dny",
      5: "dnů",
    },
    week: {
      1: "týden",
      2: "týdny",
      3: "týdny",
      4: "týdny",
      5: "týdnů",
    },
    month: {
      1: "měsíc",
      2: "měsíce",
      3: "měsíce",
      4: "měsíce",
      5: "měsíců",
    },
    year: {
      1: "rok",
      2: "roky",
      3: "roky",
      4: "roky",
      5: "let",
    },
    minuta: {
      1: "minuta",
      2: "minuty",
      3: "minuty",
      4: "minuty",
      5: "minut",
    },
    sekunda: {
      1: "sekunda",
      2: "sekundy",
      3: "sekundy",
      4: "sekundy",
      5: "sekund",
    },
    slovo: {
      1: "slovo",
      2: "slova",
      3: "slova",
      4: "slova",
      5: "slov",
    },
    písmeno: {
      1: "písmeno",
      2: "písmena",
      3: "písmena",
      4: "písmena",
      5: "písmen",
    },
  },
};

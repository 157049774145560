import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import { UserGameData } from "@/store/interfaces/UserGameData";

@Module
export default class UserGameDataModule extends VuexModule {
  redirectTo = "";
  loadedData: UserGameData[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedUserGameData(): UserGameData[] {
    return this.loadedData;
  }

  /**
   * Get last pagination setup
   */
  get getUserGameDataPagination(): PaginationResponse {
    return this.pagination;
  }

  get getUserGameDataSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_USER_GAME_DATA](data) {
    this.loadedData = data.data;
  }

  @Mutation
  [Mutations.SET_USER_GAME_DATA_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_USER_GAME_DATA_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_USER_GAME_DATA_BY_USER_AND_GAME](payload) {
    ApiService.setHeader();
    return new Promise<UserGameData[]>((resolve, reject) => {
      ApiService.post("user-game-data/get-by-user-and-game", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_USER_GAME_DATA,
              data.userGameData
            );
            this.context.commit(
              Mutations.SET_USER_GAME_DATA_PAGINATION,
              data.userGameData.pagination
            );
            resolve(data.userGameData.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGameData.errors.cantGetData"
          );
          reject();
        });
    });
  }
}

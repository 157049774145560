export const countrySk = {
  countries: {
    errors: {
      commonGET_COUNTRIES: "Chyba pri získavaní zoznamu krajín",
    },
    nameOverrides: {
      CZ: "Česká republika",
    },
  },
};

export const companyBankAccountSk = {
  companyBankAccounts: {
    toolTips: {
      edit: "Upraviť bankový účet {account}",
      delete: "Odstrániť bankový účet {account}",
      lastSyncState: "Poslední synchronizace {date}",
    },
    editTitle: "Upraviť bankový účet",
    newTitle: "Nový bankový účet",
    addCompanyBankAccount: "Pridať bankový účet",
    errors: {
      notFound: "Bankový účet s id {id} nebol nájdený",
      notYourRole: "Nemáte práva zobraziť bankový účet s id {id}",
      currencyExists: "Bankový účet pre menu `{currency_id}` už existuje",
      commonGET_COMPANY_BANK_ACCOUNT_BY_ID:
        "Chyba pri získavaní údajov o bankovom účte",
      commonDELETE_COMPANY_BANK_ACCOUNT_BY_ID:
        "Chyba pri odstraňovaní bankového účtu",
      commonDELETE_COMPANY_BANK_ACCOUNTS:
        "Nepodarilo sa odstrániť bankový účet",
      cantGetCompanyBankAccounts:
        "Chyba pri získavaní údajov o bankových účtoch",
    },
    form: {
      company: "Firma",
      currency: "Mena",
      bank_name: "Názov banky",
      account: "Č.účtu",
      iban: "IBAN",
      swift_bic: "SWIFT/BIC",
      remote_access_data: "Nastavenia vzdialeného prístupu",
      companyBankAccountChanged: "Bankový účet bol upravený",
      companyBankAccountChangedFail: "Nepodarilo sa upraviť bankový účet",
      companyBankAccountAdded: "Nový bankový účet bol vytvorený",
      companyBankAccountAddFail: "Nepodarilo sa vytvoriť nový bankový účet",
    },
    log: {
      table: {
        date: "Datum",
        type: "Stav",
        text: "Text",
      },
    },
    modal: {
      titleAdd: "Pridať nový bankový účet",
      titleUpdate: "Upraviť bankový účet `{account}`",
    },
    table: {
      header: {
        company: "Firma",
        currency: "Mena",
        bank_name: "Názov banky",
        account: "Č.účtu",
        syncState: "API přístup",
        iban: "IBAN",
        actions: "Akcie",
      },
      runSync: "Spustit synchronizaci",
      getSyncLog: "Zobrazit log poslední synchronizace",
      deleteCompanyBankAccountQuestion:
        "Naozaj chcete odstrániť bankový účet <strong>{account}</strong>?",
      searchCompanyBankAccounts: "Hľadať bankový účet",
      companyBankAccountDeleted: "Bankový účet {account} bol odstránený",
      companyBankAccountsDeleted: "Bankové účty boli odstránené",
      noRecords: "Neboli nájdené žiadne bankové účty",
    },
  },
};

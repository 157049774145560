import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import i18n from "@/core/plugins/i18n";
import { User } from "@/store/interfaces/User";
import {
  validateNotUserIdAndRoles,
  validateUserIdAndRoles,
  validateUserRoles,
} from "@/router/validation";

const { t } = i18n.global;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/game",
        name: "game",
        component: () => import("@/views/dev/Dev.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/dev-games",
        name: "dev-games",
        component: () => import("@/views/dev/Games.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/playground",
        name: "playground",
        component: () => import("@/views/dev/Playground.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/language-manager",
        name: "languageManager",
        component: () => import("@/views/languageManager/LanguageManager.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/bulk-message",
        name: "bulk-message",
        component: () => import("@/views/bulkMessage/BulkMessage.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 4);
        },
      },
      {
        path: "/import-diagnostics",
        name: "import-diagnostics",
        component: () =>
          import("@/views/importDiagnostics/ImportDiagnostics.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 4);
        },
      },
      {
        path: "/import-leads",
        name: "import-leads",
        component: () => import("@/views/importLeads/ImportLeads.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 4);
        },
      },
      {
        path: "/export-diagnostics",
        name: "export-diagnostics",
        component: () =>
          import("@/views/exportDiagnostics/ExportDiagnostics.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2);
        },
      },
      {
        path: "/dashboard/company/:companyId(\\d+)",
        name: "companyDashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2);
        },
      },
      {
        path: "/dashboard/group/:groupId(\\d+)",
        name: "groupDashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2);
        },
      },
      {
        path: "/dashboard/results",
        name: "dashboardResults",
        component: () => import("@/views/dashboard/DashboardResults.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2, 2);
        },
      },
      {
        path: "/dashboard/diagnostics",
        name: "dashboardDiagnostics",
        component: () => import("@/views/dashboard/DashboardDiagnostics.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2, 2);
        },
      },
      {
        path: "/dashboard/games",
        name: "dashboardGames",
        component: () => import("@/views/dashboard/DashboardGames.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2, 2);
        },
      },

      {
        path: "/dashboard/training-exercise/:id(\\d+)",
        name: "dashboardTrainingExercise",
        component: () =>
          import("@/views/dashboard/DashboardTrainingExercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2, 2);
        },
      },
      {
        path: "/dashboard/training",
        name: "dashboardTraining",
        component: () => import("@/views/dashboard/DashboardTraining.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 2, 2);
        },
      },
      {
        path: "/dashboard/client-games/:id(\\d+)",
        name: "dashboardClientGames",
        component: () => import("@/views/dashboard/DashboardClientGames.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 3, 3);
        },
      },
      {
        path: "/user-groups",
        name: "userGroups",
        component: () => import("@/views/userGroups/UserGroups.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-user-group",
        name: "addUserGroup",
        component: () => import("@/views/userGroups/UserGroup.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/user-group/:id(\\d+)",
        name: "userGroup",
        component: () => import("@/views/userGroups/UserGroup.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },

      {
        path: "/files",
        name: "files",
        component: () => import("@/views/files/Files.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-file",
        name: "addFile",
        component: () => import("@/views/files/File.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/file/:id(\\d+)",
        name: "file",
        component: () => import("@/views/files/File.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/specializations",
        name: "specializations",
        component: () => import("@/views/specializations/Specializations.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-specialization",
        name: "addSpecialization",
        component: () => import("@/views/specializations/Specialization.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/specialization/:id(\\d+)",
        name: "specialization",
        component: () => import("@/views/specializations/Specialization.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/companies/Companies.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/add-company",
        name: "addCompany",
        component: () => import("@/views/companies/Company.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/company/:id(\\d+)",
        name: "company",
        component: () => import("@/views/companies/Company.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/programs",
        name: "programs",
        component: () => import("@/views/programs/Programs.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-program",
        name: "addProgram",
        component: () => import("@/views/programs/Program.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/program/:id(\\d+)",
        name: "program",
        component: () => import("@/views/programs/Program.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/sports",
        name: "sports",
        component: () => import("@/views/sports/Sports.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-sport",
        name: "addSport",
        component: () => import("@/views/sports/Sport.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/sport/:id(\\d+)",
        name: "sport",
        component: () => import("@/views/sports/Sport.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },

      {
        path: "/payments",
        name: "payments",
        component: () => import("@/views/payments/Payments.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-payment",
        name: "addPayment",
        component: () => import("@/views/payments/Payment.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/payment/:id(\\d+)",
        name: "payment",
        component: () => import("@/views/payments/Payment.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },

      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/invoices/Invoices.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-invoice",
        name: "addInvoice",
        component: () => import("@/views/invoices/Invoice.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-invoice-for-user/:user_id(\\d+)",
        name: "addInvoiceForUser",
        component: () => import("@/views/invoices/Invoice.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/clone-invoice/:invoice_id(\\d+)",
        name: "cloneInvoice",
        component: () => import("@/views/invoices/Invoice.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/invoice/:id(\\d+)",
        name: "invoice",
        component: () => import("@/views/invoices/Invoice.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },

      {
        path: "/registration-tokens",
        name: "registrationTokens",
        component: () =>
          import("@/views/registrationTokens/RegistrationTokens.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/add-registration-token",
        name: "addRegistrationToken",
        component: () =>
          import("@/views/registrationTokens/RegistrationToken.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },
      {
        path: "/registration-token/:id(\\d+)",
        name: "registrationToken",
        component: () =>
          import("@/views/registrationTokens/RegistrationToken.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 5);
        },
      },

      {
        path: "/company-bank-accounts",
        name: "companyBankAccounts",
        component: () =>
          import("@/views/companyBankAccounts/CompanyBankAccounts.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-company-bank-account",
        name: "addCompanyBankAccount",
        component: () =>
          import("@/views/companyBankAccounts/CompanyBankAccount.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/company-bank-account/:id(\\d+)",
        name: "companyBankAccount",
        component: () =>
          import("@/views/companyBankAccounts/CompanyBankAccount.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/accessories",
        name: "accessories",
        component: () => import("@/views/accessories/Accessories.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-accessory/:language_id(\\d+)",
        name: "addAccessory",
        component: () => import("@/views/accessories/Accessory.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/accessory/:id(\\d+)/:language_id(\\d+)",
        name: "accessory",
        component: () => import("@/views/accessories/Accessory.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/tools",
        name: "tools",
        component: () => import("@/views/tools/Tools.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-tool/:language_id(\\d+)",
        name: "addTool",
        component: () => import("@/views/tools/Tool.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/tool/:id(\\d+)/:language_id(\\d+)",
        name: "tool",
        component: () => import("@/views/tools/Tool.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/body-parts",
        name: "bodyParts",
        component: () => import("@/views/bodyParts/BodyParts.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-body-part/:language_id(\\d+)",
        name: "addBodyPart",
        component: () => import("@/views/bodyParts/BodyPart.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/body-part/:id(\\d+)/:language_id(\\d+)",
        name: "bodyPart",
        component: () => import("@/views/bodyParts/BodyPart.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/exercise-executions",
        name: "exerciseExecutions",
        component: () =>
          import("@/views/exerciseExecutions/ExerciseExecutions.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-exercise-execution/:language_id(\\d+)",
        name: "addExerciseExecution",
        component: () =>
          import("@/views/exerciseExecutions/ExerciseExecution.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/exercise-execution/:id(\\d+)/:language_id(\\d+)",
        name: "exerciseExecution",
        component: () =>
          import("@/views/exerciseExecutions/ExerciseExecution.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/training-templates",
        name: "trainingTemplates",
        component: () =>
          import("@/views/trainingTemplates/TrainingTemplates.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-training-template",
        name: "addTrainingTemplate",
        component: () =>
          import("@/views/trainingTemplates/TrainingTemplate.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/training-template/:id(\\d+)",
        name: "trainingTemplate",
        component: () =>
          import("@/views/trainingTemplates/TrainingTemplate.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/training-template-periods",
        name: "trainingTemplatePeriods",
        component: () =>
          import("@/views/trainingTemplatePeriods/TrainingTemplatePeriods.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-training-template-period",
        name: "addTrainingTemplatePeriod",
        component: () =>
          import("@/views/trainingTemplatePeriods/TrainingTemplatePeriod.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/training-template-period/:id(\\d+)",
        name: "trainingTemplatePeriod",
        component: () =>
          import("@/views/trainingTemplatePeriods/TrainingTemplatePeriod.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/template-period-exercises",
        name: "templatePeriodExercises",
        component: () =>
          import("@/views/templatePeriodExercises/TemplatePeriodExercises.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-template-period-exercise",
        name: "addTemplatePeriodExercise",
        component: () =>
          import("@/views/templatePeriodExercises/TemplatePeriodExercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/template-period-exercise/:id(\\d+)",
        name: "templatePeriodExercise",
        component: () =>
          import("@/views/templatePeriodExercises/TemplatePeriodExercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/clone-template-period-exercise/:template_period_exercise_id(\\d+)",
        name: "cloneTemplatePeriodExercise",
        component: () =>
          import("@/views/templatePeriodExercises/TemplatePeriodExercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/units",
        name: "units",
        component: () => import("@/views/units/Units.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-unit/:language_id(\\d+)",
        name: "addUnit",
        component: () => import("@/views/units/Unit.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/unit/:id(\\d+)/:language_id(\\d+)",
        name: "unit",
        component: () => import("@/views/units/Unit.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/exercises",
        name: "exercises",
        component: () => import("@/views/exercises/Exercises.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-exercise/:language_id(\\d+)",
        name: "addExercise",
        component: () => import("@/views/exercises/Exercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/clone-exercise/:exercise_id(\\d+)/:language_id(\\d+)",
        name: "cloneExercise",
        component: () => import("@/views/exercises/Exercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/exercise/:id(\\d+)/:language_id(\\d+)",
        name: "exercise",
        component: () => import("@/views/exercises/Exercise.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/exercise-categories",
        name: "exerciseCategories",
        component: () =>
          import("@/views/exerciseCategories/ExerciseCategories.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-exercise-category/:language_id(\\d+)",
        name: "addExerciseCategory",
        component: () =>
          import("@/views/exerciseCategories/ExerciseCategory.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/exercise-category/:id(\\d+)/:language_id(\\d+)",
        name: "exerciseCategory",
        component: () =>
          import("@/views/exerciseCategories/ExerciseCategory.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },

      {
        path: "/diagnostics",
        name: "diagnostics_management",
        component: () => import("@/views/diagnostics/Diagnostics.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/add-diagnostic",
        name: "addDiagnostic",
        component: () => import("@/views/diagnostics/Diagnostic.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },
      {
        path: "/diagnostic/:id(\\d+)",
        name: "diagnostic",
        component: () => import("@/views/diagnostics/Diagnostic.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 7);
        },
      },

      {
        path: "/trainings",
        name: "trainings",
        component: () => import("@/views/trainings/Trainings.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/add-training",
        name: "addTraining",
        component: () => import("@/views/trainings/Training.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/training/:id(\\d+)",
        name: "training",
        component: () => import("@/views/trainings/Training.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next, 6);
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/Users.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next);
        },
      },
      {
        path: "/add-user",
        name: "addUser",
        component: () => import("@/views/users/AddUser.vue"),
        beforeEnter(to, from, next) {
          validateUserRoles(to, from, next);
        },
      },
      {
        path: "/account/:id(\\d+)",
        redirect: (to) => {
          const { params } = to;
          return { path: `/account/${params.id}/overview` };
        },
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        beforeEnter(to, from, next) {
          validateUserIdAndRoles(to, from, next, 3);
        },
        children: [
          {
            path: "overview",
            name: "overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings/:scrollTo?",
            name: "settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
          {
            path: "assignment",
            name: "assignment",
            component: () => import("@/views/crafted/account/Assignment.vue"),
          },
          {
            path: "assigned-clients",
            name: "assignedClients",
            component: () =>
              import("@/views/crafted/account/AssignedClients.vue"),
          },
          {
            path: "results",
            name: "results",
            component: () => import("@/views/crafted/account/Results.vue"),
          },
          {
            path: "other-contacts",
            name: "otherContacts",
            component: () =>
              import("@/views/crafted/account/OtherContacts.vue"),
          },
          {
            path: "account-training",
            name: "accountTraining",
            component: () => import("@/views/crafted/account/Training.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next, 6);
            },
          },
          {
            path: "diagnostics",
            name: "diagnostics",
            component: () => import("@/views/crafted/account/Diagnostics.vue"),
            beforeEnter(to, from, next) {
              validateUserRoles(to, from, next, 2);
            },
          },
          {
            path: "account-programs",
            name: "accountPrograms",
            component: () => import("@/views/crafted/account/Programs.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next);
            },
          },
          {
            path: "privacy",
            name: "privacy",
            component: () => import("@/views/crafted/account/Privacy.vue"),
          },
          {
            path: "notifications",
            name: "notifications",
            component: () =>
              import("@/views/crafted/account/Notifications.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next);
            },
          },
          {
            path: "account-accessories",
            name: "accountAccessories",
            component: () => import("@/views/crafted/account/Accessories.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next);
            },
          },
          {
            path: "account-tools",
            name: "accountTools",
            component: () => import("@/views/crafted/account/Tools.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next);
            },
          },
          {
            path: "account-dashboard",
            name: "accountDashboard",
            component: () => import("@/views/crafted/account/Dashboard.vue"),
            beforeEnter(to, from, next) {
              validateNotUserIdAndRoles(to, from, next);
            },
          },
          {
            path: "chat",
            name: "chat",
            component: () => import("@/views/crafted/account/Chat.vue"),
            beforeEnter(to, from, next) {
              validateUserRoles(to, from, next, 2);
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up/:token?",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-change/:email/:token",
        name: "password-change",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordChange.vue"
          ),
      },
    ],
  },
  {
    path: "/unauthorized",
    name: "403",
    component: () => import("@/views/crafted/authentication/Error403.vue"),
  },
  {
    path: "/gdpr-consent",
    name: "gdpr-consent",
    component: () => import("@/views/crafted/authentication/GdprConsent.vue"),
  },
  {
    path: "/consents-change",
    name: "consents-change",
    component: () =>
      import("@/views/crafted/authentication/ConsentsChange.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.commit(
    Mutations.SET_SHOW_USER_SEARCH_IN_MENU,
    to.path.indexOf("users") == -1
  );
  store
    .dispatch(Actions.VERIFY_AUTH)
    .then(
      (unreadMessages) => {
        store.commit(Mutations.SET_MESSAGE_COUNTS, unreadMessages);
        const currentUser = store.getters["currentUser"] as User;
        if (currentUser.role_id <= 3) {
          store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG, true);
        }
        if (to.name === "sign-in") {
          const path =
            currentUser.role_id > 1
              ? "/dashboard"
              : `/account/${currentUser.id}/overview`;
          router.push({
            path,
          });
        }
        const gdpr =
          !(!currentUser.privacy?.gdpr && currentUser.role_id < 3) ||
          currentUser.role_id > 2;
        if (!gdpr) {
          router.push({ name: "gdpr-consent" });
        }
        const missingConsents: Array<string> = [];
        for (const name in currentUser.privacy) {
          if (currentUser.privacy[name] === null) {
            missingConsents.push(name);
          }
        }

        if (currentUser.role_id < 3 && missingConsents.length > 0) {
          router.push({ name: "consents-change" });
        }

        if (
          to.name === "sign-in" ||
          (to.name === "gdpr-consent" && gdpr) ||
          (to.name === "consents-change" && missingConsents.length == 0)
        ) {
          const path =
            currentUser.role_id > 1
              ? "/dashboard"
              : `/account/${currentUser.id}/overview`;
          router.push({
            path,
          });
        }
        return true;
      },
      () => {
        if (
          to.name !== "sign-in" &&
          to.name !== "sign-up" &&
          to.name !== "password-reset" &&
          to.name !== "password-change"
        ) {
          if (localStorage.userId) {
            useToast().warning(t("jwt.invalidToken"));
          }
          router.push({ name: "sign-in" });
        }
        return false;
      }
    )
    .catch(() => {
      useToast().warning(t("jwt.invalidToken"));
    });
});

export default router;

import { EmailTemplates } from "@/store/enums/EmailTemplateEnums";
export const mailSk = {
  email_templates: {
    [EmailTemplates.COMMON]: "Štandardný",
    [EmailTemplates.NEWSLETTER_BIRTHDAY]: "Newsletter - narodeniny",
  },
  mails: {
    passwordReset: {
      subject: "iSenses: Obnovenie hesla",
      body: {
        title: "Obnovenie hesla",
        text: "Ak ste zabudli svoje heslo alebo si ho chcete obnoviť, použite prosím odkaz nižšie.",
        button: {
          text: "Obnoviť heslo",
          link: "password-change/",
        },
        textAfter:
          "Ak ste si obnovenie nevyžiadali, tento e-mail ignorujte. Len osoba s prístupom k vašej e-mailovej schránke môže zmeniť heslo.",
        companyId: "i",
      },
    },
    inviteUser: {
      subject: "iSenses: Pozvánka",
      body: {
        title: "Vitajte v iSenses",
        text: "Práve sme vám vytvorili účet v systéme iSenses. Prosím, nastavte si svoje heslo a potom sa prihláste.",
        button: {
          text: "Nastaviť heslo",
          link: "password-change/",
        },
        textAfter:
          "Ak nie ste záujem o registráciu do systému iSenses, tento e-mail ignorujte. Len osoba s prístupom k vašej e-mailovej schránke môže nastaviť heslo.",
        companyId: "i",
      },
    },
  },
};

<style lang="scss"></style>
<template>
  <PressAllMultipleGame
    :settings="prepareSettings(tool)"
    :onGameEnd="gameFinished"
  ></PressAllMultipleGame>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { Tool } from "@/store/interfaces/Tool";
import { useToolFormater } from "@/components/tools/composables/toolFormater";
import { applyReactInVue } from "veaury";
import PressAllMultipleGame from "@/react_app/games/Games/PressAllMultipleGame/PressAllMultipleGame";

export default defineComponent({
  name: "press-all-multiple-game",
  props: {
    tool: {
      type: Object as () => Tool,
      required: true,
    },
  },
  emits: ["game-finished"],
  components: {
    PressAllMultipleGame: applyReactInVue(PressAllMultipleGame),
  },
  setup(props, { emit }) {
    const { prepareSettings, printName } = useToolFormater(props.tool);

    const gameComponentName = computed<string>(() => {
      return props.tool.settings["react_component"];
    });

    const gameFinished = (data: object) => {
      emit("game-finished", data);
    };

    return { prepareSettings, gameFinished, gameComponentName, printName };
  },
});
</script>

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Payment } from "@/store/interfaces/Payment";

@Module
export default class PaymentModule extends VuexModule {
  redirectTo = "";
  loadedPayment: Payment = {} as Payment;
  Payments: Payment[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedPayment(): Payment {
    return this.loadedPayment;
  }

  /**
   * Retreive current loaded payments
   */
  get getPayments(): Payment[] {
    return this.Payments;
  }
  get getPaymentTypes(): Array<string> {
    return ["game", "measurement", "other"];
  }

  /**
   * Get last pagination setup
   */
  get getPaymentsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getPaymentsSorting() {
    return this.sorting;
  }
  @Mutation
  [Mutations.SET_LOADED_PAYMENT](Payment: Payment) {
    this.loadedPayment = Payment;
  }

  @Mutation
  [Mutations.SET_PAYMENTS](data) {
    this.Payments = data.data;
  }

  @Mutation
  [Mutations.SET_PAYMENTS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_PAYMENTS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_PAYMENTS](payload) {
    ApiService.setHeader();
    return new Promise<Payment[]>((resolve, reject) => {
      ApiService.post("payments", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PAYMENTS, data["payments"]);
            this.context.commit(
              Mutations.SET_PAYMENTS_PAGINATION,
              data["payments"].pagination
            );
            resolve(data["payments"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.errors.cantGetPayments"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_PAYMENT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Payment>((resolve) => {
        const Payment = {} as Payment;
        this.context.commit(Mutations.SET_LOADED_PAYMENT, Payment);
        resolve(Payment);
      });
    }
    return new Promise<Payment>((resolve, reject) => {
      ApiService.get(`payment/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PAYMENT, data["payment"]);
            resolve(data["payment"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.errors.commonGET_PAYMENT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FREE_PAYMENTS_BY_INVOICE_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Payment[]>((resolve, reject) => {
      ApiService.get(`payments/free-by-invoice/${id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["payments"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.errors.commonGET_FREE_PAYMENTS_BY_INVOICE_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PAYMENT_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Payment>((resolve, reject) => {
      ApiService.delete(`payment/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PAYMENT, data["payment"]);
            resolve(data["payment"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.errors.commonDELETE_PAYMENT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PAYMENT](Payment: Payment) {
    const payload = JSON.parse(JSON.stringify(Payment));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`payment/${Payment.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PAYMENT, data["payment"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Payment.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.form.paymentChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_PAYMENT](Payment: Payment) {
    const payload = JSON.parse(JSON.stringify(Payment));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`payment`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PAYMENT, data["payment"]);
            resolve(data["payment"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Payment.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "payments.form.paymentAddFail"
          );
          reject();
        });
    });
  }
}

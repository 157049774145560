import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import Color from "../../Components/Color";

const getRandomCoordinates = (rows, cols, count) => {
  const coordinates = new Set();
  while (coordinates.size < count) {
    const row = Math.floor(Math.random() * rows);
    const col = Math.floor(Math.random() * cols);
    coordinates.add(`${row}-${col}`);
  }
  return Array.from(coordinates).map((coord) => coord.split("-").map(Number));
};

export const useRememberTheGridMultipleGame = (
  settings,
  onGameEnd,
  gridRef
) => {
  const { rows, cols, fieldCount, highlightDuration } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [showStartButton, setShowStartButton] = useState(true);
  const [highlightedFields, setHighlightedFields] = useState([]);
  const [clickTimes, setClickTimes] = useState([]);
  const [roundClickTimes, setRoundClickTimes] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const gameTimer = useRef(null);
  const highlightTimer = useRef(null);
  const roundClickTimesRef = useRef(roundClickTimes);

  useEffect(() => {
    roundClickTimesRef.current = roundClickTimes;
  }, [roundClickTimes]);

  const startGame = () => {
    setShowStartButton(false);
    setIsRunning(true);
    setClickTimes([]);
    setRoundClickTimes([]);
    generateNewGrid();

    gameTimer.current = setTimeout(() => {
      setIsRunning(false);
      if (clickTimes.length > 0) {
        const updatedRounds = [...roundClickTimesRef.current, clickTimes];
        onGameEnd({
          games: [
            {
              id: "RememberTheGridMultipleGame",
              rounds: updatedRounds,
            },
          ],
        });
      } else {
        onGameEnd({
          games: [
            {
              id: "RememberTheGridMultipleGame",
              rounds: roundClickTimesRef.current,
            },
          ],
        });
      }
    }, 60000);
  };

  const generateNewGrid = () => {
    const newGridContent = Array.from({ length: rows }, () =>
      Array.from({ length: cols }, () => ({
        isHighlighted: false,
        isClicked: false,
      }))
    );

    const coordinates = getRandomCoordinates(rows, cols, fieldCount);
    coordinates.forEach(([row, col]) => {
      newGridContent[row][col].isHighlighted = true;
    });

    setGridContent(
      newGridContent.map((row) =>
        row.map((cell) => (
          <div
            style={{
              backgroundColor: cell.isHighlighted
                ? Color.Blue
                : Color.LightGray,
              width: "100%",
              height: "100%",
            }}
          />
        ))
      )
    );
    setHighlightedFields(coordinates);
    setIsHighlighting(true);

    highlightTimer.current = setTimeout(() => {
      setIsHighlighting(false);
      setGridContent(
        newGridContent.map((row) =>
          row.map((cell) => (
            <div
              style={{
                backgroundColor: Color.LightGray,
                width: "100%",
                height: "100%",
              }}
            />
          ))
        )
      );
    }, highlightDuration * 1000);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    if (
      !isRunning ||
      isHighlighting ||
      gridRef.current.getCell(rowIndex, colIndex).props.style
        .backgroundColor !== Color.LightGray
    )
      return;

    const clickTime = Math.floor(performance.now());
    const isCorrect = highlightedFields.some(
      ([row, col]) => row === rowIndex && col === colIndex
    );
    setClickTimes((prev) => [...prev, { clickTime, isCorrect }]);

    gridRef.current.updateCell(
      rowIndex,
      colIndex,
      <div
        style={{
          backgroundColor: isCorrect ? Color.Teal : Color.Red,
          width: "100%",
          height: "100%",
        }}
      />
    );

    if (isCorrect) {
      setHighlightedFields((prevFields) => {
        const remainingFields = prevFields.filter(
          ([row, col]) => row !== rowIndex || col !== colIndex
        );
        if (remainingFields.length === 0) {
          setIsHighlighting(true);
          setRoundClickTimes((prevRounds) => {
            const newRounds = [
              ...prevRounds,
              [...clickTimes, { clickTime, isCorrect }],
            ];
            roundClickTimesRef.current = newRounds;
            return newRounds;
          });
          setClickTimes([]);
          setTimeout(() => {
            generateNewGrid();
          }, 500); // Wait 500ms before generating a new grid
        }
        return remainingFields;
      });
    }
  };

  const gridComponent = (
    <Grid
      ref={gridRef}
      gridContent={gridContent}
      onCellClick={handleCellClick}
    />
  );

  return {
    gridComponent,
    showStartButton,
    startGame,
    handleCellClick,
    isRunning,
  };
};

import React, { useState, useEffect } from "react";

// Go-NoGo Test
const generateUniqueCharacters = (amount) => {
  return Array.from({ length: amount }, (_, i) => String.fromCharCode(65 + i));
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

const GoNoGoGame = ({ onGameEnd }) => {
  const [gridColor, setGridColor] = useState("#EFEFEF");
  const [combinationStartTime, setCombinationStartTime] = useState(null);
  const [letterCombinations, setLetterCombinations] = useState([]);
  const [letterCombination, setLetterCombination] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [allowClick, setAllowClick] = useState(true);
  const [showStartButton, setShowStartButton] = useState(true);
  const [placementScenario] = useState(2); // Adjust accordingly
  const [letterPositions, setLetterPositions] = useState([]);

  const gridSize = 5;

  const gameLength = 30;
  const [remainingGameTime, setRemainingGameTime] = useState(gameLength);

  const interval = 1000;

  useEffect(() => {
    if (remainingGameTime === 0) {
      let correctCount = answers.filter((answer) => answer.correct).length;

      onGameEnd({
        games: [
          {
            id: "GoNoGoGame",
            correctCount,
            answers,
          },
        ],
      });
    } else {
      const timeoutId = setTimeout(() => {
        if (!showStartButton) {
          showNewCombination();
        }
      }, interval);
      return () => clearTimeout(timeoutId);
    }
  }, [showStartButton, remainingGameTime]);

  useEffect(() => {
    const gameTimer = setTimeout(() => {
      if (!showStartButton && remainingGameTime > 0) {
        setRemainingGameTime(remainingGameTime - 1);
      }
    }, 1000);
    return () => clearTimeout(gameTimer);
  }, [remainingGameTime, showStartButton]);

  const startGame = () => {
    setShowStartButton(false);

    generateCombinations();

    showNewCombination();
  };

  const generateCombinations = () => {
    const totalCombinations = 30;
    var combinations = [];
    let availableCharacters = generateUniqueCharacters(7);
    let sameCombinationsCount = 5;

    // Function to generate a unique combination
    const generateUniqueCombination = () => {
      const combination = new Set();
      while (combination.size < 3) {
        const letter =
          availableCharacters[
            Math.floor(Math.random() * availableCharacters.length)
          ];
        combination.add(letter);
      }
      return Array.from(combination);
    };

    for (let i = 0; i < totalCombinations; i++) {
      if (sameCombinationsCount > 0) {
        // Generate a combination with the same letters
        const letter =
          availableCharacters[
            Math.floor(Math.random() * availableCharacters.length)
          ];
        combinations.push([letter, letter, letter]);
        sameCombinationsCount--;
      } else {
        let uniqueCombination = generateUniqueCombination();

        while (
          combinations.some(
            (comb) => JSON.stringify(comb) === JSON.stringify(uniqueCombination)
          )
        ) {
          uniqueCombination = generateUniqueCombination();
        }
        combinations.push(uniqueCombination);
      }
    }

    shuffleArray(combinations);
    setLetterCombinations(combinations);
  };

  const showNewCombination = () => {
    setAllowClick(true);
    setGridColor("#EFEFEF");

    if (currentIndex < letterCombinations.length) {
      setCombinationStartTime(Date.now());

      setLetterCombination(letterCombinations[currentIndex]);

      setLetterPositions(calculateLetterPositions(placementScenario, gridSize));

      setCurrentIndex(currentIndex + 1);
    }
  };

  const makeGuess = () => {
    if (allowClick) {
      const clickTime = Date.now() - combinationStartTime;
      const correct = letterCombination.every(
        (letter) => letter === letterCombination[0]
      );

      setGridColor(correct ? "#4CAF50" : "red");

      setAllowClick(false);
      setAnswers((prev) => [...prev, { correct: correct, clickTime }]);
    }
  };

  const calculateLetterPositions = (scenario, gridSize = 5) => {
    const positions = [];
    switch (scenario) {
      case 0: // Random
        while (positions.length < 3) {
          const randomPosition = Math.floor(
            Math.random() * (gridSize * gridSize)
          );
          if (!positions.includes(randomPosition)) {
            positions.push(randomPosition);
          }
        }
        break;
      case 1: // Horizontal spaced in the middle row
        // eslint-disable-next-line no-case-declarations
        const middleRowStart = Math.floor(gridSize / 2) * gridSize;
        positions.push(middleRowStart, middleRowStart + 2, middleRowStart + 4);
        break;
      case 2: // Horizontal in the center of the grid
        // eslint-disable-next-line no-case-declarations
        const centerRowStart = Math.floor(gridSize / 2) * gridSize;
        positions.push(
          centerRowStart + 1,
          centerRowStart + 2,
          centerRowStart + 3
        );
        break;
      case 3: // Vertical spaced apart in the middle column
        // eslint-disable-next-line no-case-declarations
        const middleColumn = Math.floor(gridSize / 2);
        positions.push(
          middleColumn,
          middleColumn + gridSize * 2,
          middleColumn + gridSize * 4
        );
        break;
      case 4: // Vertical in the center of the grid
        // eslint-disable-next-line no-case-declarations
        const centerColumn = Math.floor(gridSize / 2);
        // eslint-disable-next-line no-case-declarations
        const centerStart = gridSize * 1 + centerColumn;
        positions.push(
          centerStart,
          centerStart + gridSize,
          centerStart + gridSize * 2
        );
        break;
      default:
        break;
    }
    return positions;
  };

  const GameHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginBottom: "24px",
      }}
    >
      <div>Game timer: {remainingGameTime}</div>
      <div>Score: {answers.filter((answer) => answer.correct).length}</div>
    </div>
  );

  const GameGrid = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "24px",
          marginBottom: "24px",
        }}
      >
        {!showStartButton && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
              gap: "10px",
            }}
          >
            {[...Array(gridSize * gridSize)].map((_, index) => (
              <div
                key={index}
                onClick={makeGuess}
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: gridColor,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "black",
                }}
              >
                {letterPositions.includes(index)
                  ? letterCombination[letterPositions.indexOf(index)]
                  : ""}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const GameFooter = () => (
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "24px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showStartButton && (
          <div style={{ padding: "10px" }}>
            V prostřední řadě budou cyklicky ve třech středových buňkách náhodně
            zobrazována písmena v rozsahu A až G. o každé rotaci má uživatel
            časové okno, tj. do další rotace, kliknout na libovolné pole gridu v
            případě, že jsou písmena stejná.{" "}
          </div>
        )}
        {showStartButton && (
          <button
            onClick={startGame}
            style={{
              padding: "10px",
              width: "120px",
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor: "#368f58",
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
              border: "none",
            }}
          >
            Start
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <GameHeader />
      <GameGrid />
      <GameFooter />
    </div>
  );
};

export default GoNoGoGame;

// Shapes.js

import React from "react";
import "./Shapes.css";

export const Ellipse = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    style={style}
  >
    <defs>
      <filter
        id="ellipseFilter"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <circle cx="34" cy="31" r="25" fill={fill} filter="url(#ellipseFilter)" />
  </svg>
);

export const Rectangle = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    style={style}
  >
    <defs>
      <filter
        id="rectangleFilter"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <rect
      width="50"
      height="50"
      x="9"
      y="6"
      fill={fill}
      filter="url(#rectangleFilter)"
    />
  </svg>
);

export const Triangle = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    style={style}
  >
    <defs>
      <filter
        id="triangleFilter"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <path
      d="M 34 6 L 59 56 L 9 56 Z"
      fill={fill}
      filter="url(#triangleFilter)"
    />
  </svg>
);

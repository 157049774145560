export const trainingCs = {
  trainings: {
    toolTips: {
      edit: "Upravit trénink {training}",
      delete: "Odstranit trénink {training}",
    },
    contentPreview: {
      title: "Náhled obsahu",
    },
    contentEditor: {
      title: "Editor obsahu",
    },
    editTitle: "Upravit trénink",
    newTitle: "Nový trénink",
    addTraining: "Přidat trénink",
    errors: {
      notFound: "Trénink id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit trénink id {id}",
      nameExists: "Trénink s nazvem `{name}` již existuje",
      commonGET_TRAINING_BY_ID: "Chyba při získávání dat o tréninku",
      commonDELETE_TRAINING_BY_ID: "Chyba při odstraňování tréninku",
      commonDELETE_TRAININGS: "Nepodařilo se odstranit tréninky",
      cantGetTrainings: "Chyba při získávání dat o trénincích",
    },
    form: {
      name: "Název",
      name_client: "Název pro klienta",
      nrOfWeeks: "Délka tréninku",
      weeks: "týdnů",
      week: "Týden",
      specialization: "Specializace",
      specializations: "Specializace",
      content: "Obsah",
      company: "Firma",
      language: "Jazyk",
      files: "Přílohy",
      trainingChanged: "Trénink byl upraven",
      trainingChangedFail: "Nepodařilo se upravit trénink",
      trainingAdded: "Nový trénink byl vytvořen",
      trainingAddFail: "Nepodařilo se vytvořit nový trénink",
      weekFilePlaceholder: "Zadejte název přílohy",
    },
    table: {
      header: {
        name: "Název",
        name_client: "Název pro klienta",
        nrOfWeeks: "Délka tréninku",
        company: "Firma",
        language: "Jazyk",
        specialization: "Specializace",
        specializations: "Specializace",
        actions: "Akce",
      },
      deleteTrainingQuestion:
        "Opravdu chcete odstranit trénink <strong>{training}</strong>?",
      searchTrainings: "Hledat tréninky",
      trainingDeleted: "Trénink {training} byl odstraněn",
      trainingsDeleted: "Tréninky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné tréninky",
    },
  },
};

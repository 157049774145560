import React, { useState, useRef } from "react";
import Color from "../../Components/Color";

const generateRandomExample = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = Math.floor(Math.random() * 10);
  return { num1, num2, result: num1 + num2 };
};

const generateGridContent = (rows, cols, fieldCount, correctAnswer) => {
  const gridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => null)
  );

  const correctRow = Math.floor(Math.random() * rows);
  const correctCol = Math.floor(Math.random() * cols);
  gridContent[correctRow][correctCol] = correctAnswer;

  let filledFields = 1;
  const usedValues = new Set([correctAnswer]);

  while (filledFields < fieldCount) {
    const row = Math.floor(Math.random() * rows);
    const col = Math.floor(Math.random() * cols);
    if (gridContent[row][col] === null) {
      let newValue;
      do {
        newValue = Math.floor(correctAnswer + (Math.random() * 20 - 10)); // +/- 10
      } while (usedValues.has(newValue));
      gridContent[row][col] = newValue;
      usedValues.add(newValue);
      filledFields++;
    }
  }

  return gridContent.map((row) =>
    row.map((cell) => (cell === null ? "" : cell))
  );
};

export const useCountingGame = (settings, onGameEnd, gridRef) => {
  const { rows, cols, fieldCount } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [example, setExample] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [clickTimes, setClickTimes] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [allowAnswer, setAllowAnswer] = useState(false);
  const gameTimer = useRef(null);

  const startGame = () => {
    setIsRunning(true);
    setClickTimes([]);
    generateNewExample();
    setStartTime(Math.floor(performance.now()));

    gameTimer.current = setTimeout(() => {
      setIsRunning(false);
      onGameEnd({
        games: [
          {
            id: "CountingGame",
            time: clickTimes,
          },
        ],
      });
    }, 60000); // Run the game for 1 minute
  };

  const generateNewExample = () => {
    const newExample = generateRandomExample();
    setExample(newExample);

    const newGridContent = generateGridContent(
      rows,
      cols,
      fieldCount,
      newExample.result
    );
    setGridContent(newGridContent);
    setStartTime(Math.floor(performance.now()));
    setAllowAnswer(true);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    if (!isRunning || !allowAnswer) return;

    const clickTime = Math.floor(performance.now()) - startTime;
    const isCorrect = gridContent[rowIndex][colIndex] === example.result;
    setClickTimes((prev) => [...prev, { clickTime, isCorrect }]);

    gridRef.current.updateCell(
      rowIndex,
      colIndex,
      <div
        style={{
          backgroundColor: isCorrect ? Color.Teal : Color.Red,
          width: "100%",
          height: "100%",
        }}
      />
    );

    setAllowAnswer(false);

    setTimeout(() => {
      setAllowAnswer(true);
      generateNewExample();
    }, 500); // 500ms before generating a new example
  };

  return {
    gridContent,
    example,
    startGame,
    handleCellClick,
    isRunning,
  };
};

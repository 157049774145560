import React, { useState, useEffect } from "react";

// Písmenkový grid test

const generateUniqueCharacters = (amount) => {
  return Array.from({ length: amount }, (_, i) => String.fromCharCode(65 + i));
};

const populateGridWithCharacters = (size, characters) => {
  const grid = Array(size * size).fill(" ");
  characters.forEach((char) => {
    let pos;
    do {
      pos = Math.floor(Math.random() * size * size);
    } while (grid[pos] !== " ");
    grid[pos] = char;
  });
  return grid;
};

const RememberTheGridGame = ({
  settings: { size, amountOfCharacters },
  onGameEnd,
}) => {
  const [grid, setGrid] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentChar, setCurrentChar] = useState("");
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showStartButton, setShowStartButton] = useState(true);
  const [autoNextRound, setAutoNextRound] = useState(false);
  const [memorizingPhase, setMemorizingPhase] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [guessTimer, setGuessTimer] = useState(5);
  const [guessStartTime, setGuessStartTime] = useState(null);

  let startTotalTime = 3;
  const [timer, setTimer] = useState(startTotalTime);
  const [currentRoundStartTime, setCurrentRoundStartTime] =
    useState(startTotalTime);
  const minimumTime = 1;
  const decrement = 0.5;

  const gameLength = 30;
  const [remainingGameTime, setRemainingGameTime] = useState(gameLength);

  useEffect(() => {
    if (remainingGameTime === 0 && guessTimer === 0) {
      onGameEnd({
        games: [
          {
            id: "RememberTheGridGame",
            score,
            answers,
          },
        ],
      });
    } else if (autoNextRound) {
      const timeoutId = setTimeout(() => {
        if (!showStartButton) {
          startGame();
        }
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [answers, score, autoNextRound, showStartButton]);

  useEffect(() => {
    const gameTimer = setTimeout(() => {
      if (!showStartButton && remainingGameTime > 0) {
        setRemainingGameTime(remainingGameTime - 1);
      }
    }, 1000);
    return () => clearTimeout(gameTimer);
  }, [remainingGameTime, showStartButton]);

  const startGame = () => {
    setVisible(true);
    setShowStartButton(false);
    setMemorizingPhase(true);

    const characters = generateUniqueCharacters(amountOfCharacters);
    const newGrid = populateGridWithCharacters(size, characters);

    setGrid(newGrid);
    setCurrentChar(characters[Math.floor(Math.random() * characters.length)]);
    setGuesses(Array(size * size).fill(null));

    const newStartTime = Math.max(
      startTotalTime - answers.length * decrement,
      minimumTime
    );
    setTimer(newStartTime);
    setCurrentRoundStartTime(newStartTime);
    countdownTimer();
  };

  const countdownTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        const nextTimerValue = prev - decrement;
        if (nextTimerValue <= minimumTime) {
          clearInterval(countdown);
          endMemorizationPhase();
          return minimumTime;
        }
        return nextTimerValue;
      });
    }, 1000);
  };

  const endMemorizationPhase = () => {
    setVisible(false);
    setMemorizingPhase(false);
    setAutoNextRound(false);
    setGuessTimer(5);
    setGuessStartTime(Date.now());
  };

  const makeGuess = (index) => {
    if (!visible && guessTimer > 0) {
      const isCorrect = grid[index] === currentChar;
      const updatedGuesses = guesses.map((guess, idx) =>
        idx === index ? isCorrect : guess
      );

      if (isCorrect) {
        setScore(score + 1);
      }

      setGuesses(updatedGuesses);
      setVisible(true);
      setAutoNextRound(true);

      const currentTime = Date.now();
      const clickTime = currentTime - guessStartTime;
      setAnswers([...answers, { correct: isCorrect, clickTime }]);
      setGuessTimer(0);
    }
  };

  const timeBarWidth = (timer / currentRoundStartTime) * 100;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <GameHeader score={score} gameTimer={remainingGameTime} />
      <GameGrid
        grid={grid}
        guesses={guesses}
        visible={visible}
        size={size}
        makeGuess={makeGuess}
      />
      <GameFooter
        startGame={startGame}
        showStartButton={showStartButton}
        currentChar={currentChar}
        visible={visible}
        memorizingPhase={memorizingPhase}
        timeBarWidth={timeBarWidth}
      />
    </div>
  );
};

const GameHeader = ({ score, gameTimer }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      marginBottom: "24px",
    }}
  >
    <div>Game timer: {gameTimer}</div>
    <div>Score: {score}</div>
  </div>
);

const GameGrid = ({ grid, guesses, visible, size, makeGuess }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      fontSize: "24px",
      marginBottom: "24px",
    }}
  >
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${size}, 1fr)`,
        gap: "10px",
      }}
    >
      {grid.map((char, index) => (
        <div
          key={index}
          onClick={() => makeGuess(index)}
          style={{
            width: "50px",
            height: "50px",
            backgroundColor:
              guesses[index] === true
                ? "#368f58"
                : guesses[index] === false
                ? "red"
                : "#EFEFEF",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: !visible ? "pointer" : "default",
            fontWeight: "bold",
            textTransform: "uppercase",
            color:
              guesses[index] !== null
                ? "white"
                : visible
                ? "black"
                : "transparent",
          }}
        >
          {char}
        </div>
      ))}
    </div>
  </div>
);

// Footer with start button and memorization timer
const GameFooter = ({ startGame, showStartButton, currentChar, visible }) => (
  <div style={{ display: "flex", justifyContent: "center", fontSize: "24px" }}>
    {currentChar && !visible && <div>Find: {currentChar}</div>}

    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {showStartButton && (
        <div style={{ padding: "10px" }}>
          Zobrazí se 7 náhodně rozmístěných písmen A až G na expoziční dobu,
          která se zkracuje mezi pokusy. Začíná se od 3s a s dekementem 500ms se
          zkracuje na 1s. Uživatel označuje kliknutím umístění požadovaného
          písmena (náhodně A až G).
        </div>
      )}
      {showStartButton && (
        <button
          onClick={startGame}
          style={{
            padding: "10px",
            width: "120px",
            borderRadius: "5px",
            cursor: "pointer",
            backgroundColor: "#368f58",
            color: "white",
            fontWeight: "bold",
            textTransform: "uppercase",
            border: "none",
          }}
        >
          Start
        </button>
      )}
    </div>
    {/*memorizingPhase && visible && (
      <div style={{
        width: '120px',
        height: '20px',
        borderRadius: '5px',
        backgroundColor: '#EFEFEF',
        overflow: 'hidden'
      }}>
        <div style={{
          height: '100%',
          width: `${timeBarWidth}%`,
          backgroundColor: '#4CAF50',
          transition: 'width 1s linear'
        }}></div>
      </div>
      )*/}
  </div>
);

export default RememberTheGridGame;

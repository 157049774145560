export const exerciseCategoryCs = {
  exerciseCategories: {
    toolTips: {
      edit: "Upravit kategorii cviků {exerciseCategory}",
      delete: "Odstranit kategorii cviků {exerciseCategory}",
    },
    editTitle: "Upravit kategorii cviků",
    newTitle: "Nová kategorie cviků",
    addExerciseCategory: "Přidat kategorii cviků",
    errors: {
      notFound: "Kategorie cviků id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit kategorii cviků id {id}",
      nameExists: "Kategorie cviků s nazvem `{name}` již existuje",
      commonGET_EXERCISE_CATEGORY_BY_ID:
        "Chyba při získávání dat o kategoriích cviků",
      commonDELETE_EXERCISE_CATEGORY_BY_ID:
        "Chyba při odstraňování kategorie cviků",
      commonDELETE_EXERCISE_CATEGORIES:
        "Nepodařilo se odstranit kategorii cviků",
      cantGetExerciseCategories: "Chyba při získávání dat o kategoriích cviků",
    },
    form: {
      name: "Název",
      client_name: "Název pro klienta",
      color: "Barva",
      icon: "Ikona",
      company: "Firmy",
      parentExerciseCategory: "Nadřazená kategorie",
      exerciseCategoryChanged: "Kategorie cviků byla upravena",
      exerciseCategoryChangedFail: "Nepodařilo se upravit kategorii cviků",
      exerciseCategoryAdded: "Nová kategorie cviků byla vytvořena",
      exerciseCategoryAddFail: "Nepodařilo se vytvořit novou kategorii cviků",
    },
    table: {
      header: {
        name: "Název",
        icon: "Ikona",
        parentExerciseCategory: "Nadřazená kategorie",
        company: "Firmy",
        actions: "Akce",
      },
      deleteExerciseCategoryQuestion:
        "Opravdu chcete odstranit kategorii cviků <strong>{exerciseCategory}</strong>?",
      searchExerciseCategories: "Hledat kategorii cviků",
      exerciseCategoryDeleted:
        "Kategorie cviků {exerciseCategory} byla odstraněna",
      exerciseCategoriesDeleted: "Kategorie cviků byly odstraněny",
      noRecords: "Nebyly nalezeny žádné kategorie cviků",
    },
  },
};

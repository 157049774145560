import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import Color from "../../Components/Color";

export const usePressAllGame = (settings, onGameEnd) => {
  const { rows, cols } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => ({
      color: Color.LightGray,
      isPressed: false,
    }))
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [showStartButton, setShowStartButton] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [fieldClearTimes, setFieldClearTimes] = useState([]);
  const [timer, setTimer] = useState(60); // 1 minute timer
  const timerRef = useRef(null);

  useEffect(() => {
    if (clickCount === rows * cols) {
      const endTime = Math.floor(performance.now());
      const totalTime = endTime - startTime;
      setFieldClearTimes((prevTimes) => [...prevTimes, totalTime]);
      resetGrid();
    }
  }, [clickCount]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerRef.current);
      onGameEnd({
        games: [
          {
            id: "PressAllGame",
            fieldClearTimes: fieldClearTimes,
          },
        ],
      });
    }
  }, [timer]);

  const getRandomPositions = (rows, cols) => {
    const positions = new Set();
    while (positions.size < rows * cols) {
      const randomPosition = [
        Math.floor(Math.random() * rows),
        Math.floor(Math.random() * cols),
      ];
      positions.add(randomPosition.toString());
    }
    return Array.from(positions).map((pos) => pos.split(",").map(Number));
  };

  const handleStartGame = () => {
    setShowStartButton(false);
    setStartTime(null);
    setClickCount(0);
    setFieldClearTimes([]);
    setTimer(60); // reset timer to 1 minute

    const randomPositions = getRandomPositions(rows, cols);

    setGridContent((prevGridContent) => {
      const newGridContent = prevGridContent.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const isActive = randomPositions.some(
            ([r, c]) => r === rowIndex && c === colIndex
          );
          return {
            color: isActive ? Color.Blue : Color.LightGray,
            isPressed: false,
          };
        })
      );
      return newGridContent;
    });

    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  const resetGrid = () => {
    const randomPositions = getRandomPositions(rows, cols);

    setGridContent((prevGridContent) => {
      const newGridContent = prevGridContent.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const isActive = randomPositions.some(
            ([r, c]) => r === rowIndex && c === colIndex
          );
          return {
            color: isActive ? Color.Blue : Color.LightGray,
            isPressed: false,
          };
        })
      );
      return newGridContent;
    });

    setClickCount(0);
    setStartTime(Math.floor(performance.now()));
  };

  const handleCellClick = (rowIndex, colIndex) => {
    setGridContent((prevGridContent) => {
      const newGridContent = prevGridContent.map((row, rIdx) =>
        row.map((cell, cIdx) => {
          if (
            rIdx === rowIndex &&
            cIdx === colIndex &&
            !cell.isPressed &&
            cell.color !== Color.LightGray
          ) {
            if (startTime === null) {
              setStartTime(Math.floor(performance.now()));
            }
            setClickCount((prevCount) => prevCount + 1);
            return { ...cell, isPressed: true, color: Color.LightGray };
          }
          return cell;
        })
      );
      return newGridContent;
    });
  };

  const gridComponent = (
    <Grid
      gridContent={gridContent.map((row, rowIndex) =>
        row.map((cell, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: cell.color,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          />
        ))
      )}
      onCellClick={handleCellClick}
    />
  );

  return {
    gridComponent,
    showStartButton,
    handleStartGame,
    handleCellClick,
    timer,
  };
};

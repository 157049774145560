import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Accessory } from "@/store/interfaces/Accessory";

@Module
export default class AccessoryModule extends VuexModule {
  redirectTo = "";
  loadedAccessory: Accessory = {} as Accessory;
  Accessories: Accessory[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedAccessory(): Accessory {
    return this.loadedAccessory.id
      ? this.loadedAccessory
      : ({
          ...this.loadedAccessory,
          ...{
            image: this.loadedAccessory.image ? this.loadedAccessory.image : {},
            icon: this.loadedAccessory.icon ? this.loadedAccessory.icon : {},
            color: this.loadedAccessory.color
              ? this.loadedAccessory.color
              : "#dedede",
            resources: this.loadedAccessory.resources
              ? this.loadedAccessory.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    client_name: "",
                    description: "",
                    accessory_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as Accessory);
  }

  /**
   * Retreive current loaded accessories
   */
  get getAccessories(): Accessory[] {
    return this.Accessories;
  }

  /**
   * Get last pagination setup
   */
  get getAccessoriesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getAccessoriesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_ACCESSORY](Accessory: Accessory) {
    this.loadedAccessory = Accessory;
  }

  @Mutation
  [Mutations.SET_ACCESSORIES](data) {
    this.Accessories = data.data;
  }

  @Mutation
  [Mutations.SET_ACCESSORIES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_ACCESSORIES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_ACCESSORIES](payload) {
    ApiService.setHeader();
    return new Promise<Accessory[]>((resolve, reject) => {
      ApiService.post("accessories", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_ACCESSORIES, data["accessories"]);
            this.context.commit(
              Mutations.SET_ACCESSORIES_PAGINATION,
              data["accessories"].pagination
            );
            resolve(data["accessories"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "accessories.errors.cantGetAccessories"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_ACCESSORY_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Accessory>((resolve) => {
        const Accessory = {} as Accessory;
        this.context.commit(Mutations.SET_LOADED_ACCESSORY, Accessory);
        resolve(Accessory);
      });
    }
    return new Promise<Accessory>((resolve, reject) => {
      ApiService.get(`accessory/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_ACCESSORY,
              data["accessory"]
            );
            resolve(data["accessory"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "accessories.errors.commonGET_ACCESSORY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_ACCESSORY_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Accessory>((resolve, reject) => {
      ApiService.delete(`accessory/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_ACCESSORY,
              data["accessory"]
            );
            resolve(data["accessory"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "accessories.errors.commonDELETE_ACCESSORY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_ACCESSORY](Accessory: Accessory) {
    const payload = JSON.parse(JSON.stringify(Accessory));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`accessory/${Accessory.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_ACCESSORY,
              data["accessory"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Accessory.id,
              code: Accessory.code,
              name: Accessory.code,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "accessories.form.accessoryChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_ACCESSORY](Accessory: Accessory) {
    const payload = JSON.parse(JSON.stringify(Accessory));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`accessory`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_ACCESSORY,
              data["accessory"]
            );
            resolve(data["accessory"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Accessory.id,
              code: Accessory.code,
              name: Accessory.code,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "accessories.form.accessoryAddFail"
          );
          reject();
        });
    });
  }
}

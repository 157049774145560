export const userGroupEn = {
  userGroups: {
    toolTips: {
      edit: "Edit group {group}",
      delete: "Delete group {group}",
      default: "Default user group",
    },
    unassigned: "Unassigned",
    editTitle: "Edit user group",
    newTitle: "New user group",
    addGroup: "Add group",
    errors: {
      notFound: "User group {id} not found",
      notYourRole: "Cant access data for user group {id}",
      nameExists: "User group named `{name}` already exists",
      commonGET_USER_GROUP_BY_ID: "Error retrieving user group data",
      commonDELETE_USER_GROUP_BY_ID: "Error deleting user group",
      commonDELETE_USER_GROUPS: "Error deleting user groups",
      cantGetUserGroups: "Failed to fetch user groups data",
      defaultExists: "Default group for this company was already created",
    },
    form: {
      name: "Name",
      company: "Company",
      oragnization: "Oragnization",
      diagnostics: "Diagnostics",
      default: "Default",
      parentUserGroup: "Parent user group",
      userGroupChanged: "User group was stored",
      userGroupChangedFail: "Failed to store group",
      userGroupAdded: "New group was created",
      userGroupAddFail: "Failed to add new group",
    },
    table: {
      header: {
        name: "Name",
        default: "Default",
        company: "Company",
        oragnization: "Oragnization",
        diagnostics: "Diagnostics",
        parentUserGroup: "Parent user group",
        actions: "Actions",
      },
      deleteUserGroupQuestion:
        "Do you really want to delete  group <strong>{userGroup}</strong>?",
      searchUserGroups: "Search user groups",
      userGroupDeleted: "User group {userGroup} was deleted",
      userGroupsDeleted: "User groups were deleted",
      noRecords: "No user groups found",
    },
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Country, Currency } from "@/store/interfaces/Country";
import i18n from "@/core/plugins/i18n";

@Module
export default class CountryModule extends VuexModule {
  loadedCountries: Country[] = [];

  get getLoadedCountries(): Country[] {
    return this.loadedCountries;
  }

  get getCurrencies(): Currency[] {
    return [
      { id: "CZK", name: "Kč" },
      { id: "EUR", name: "EUR" },
    ];
  }

  get getCurrencyCodes(): string[] {
    return ["CZK", "EUR"];
  }

  @Mutation
  [Mutations.SET_LOADED_COUNTRIES](countries: Country[]) {
    countries.map((country: Country) => {
      if (i18n.global.te(`countries.nameOverides.${country.code}`)) {
        country.name = i18n.global.t(`countries.nameOverides.${country.code}`);
      }
    });
    this.loadedCountries = countries;
  }

  @Action
  [Actions.GET_COUNTRIES]() {
    return new Promise<Country[]>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`countries`, {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COUNTRIES, data.countries);
            resolve(data.countries);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "countries.errors.commonGET_COUNTRIES"
          );
          reject();
        });
    });
  }
}

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { User } from "@/store/interfaces/User";
import { ClientRadarData } from "@/store/interfaces/DiagnosticRecord";

@Module
export default class CoachModule extends VuexModule {
  Players: User[] = [];

  CoachRadarData: ClientRadarData[] = [];
  CoachRadarDataLoading = false;

  get getPlayers(): User[] {
    return this.Players;
  }

  get getCoachRadarData(): ClientRadarData[] {
    return this.CoachRadarData;
  }

  get isCoachRadarDataLoading(): boolean {
    return this.CoachRadarDataLoading;
  }
  @Mutation
  [Mutations.SET_PLAYERS](data) {
    this.Players = data;
  }
  @Mutation
  [Mutations.SET_COACH_RADAR_DATA](data) {
    this.CoachRadarData = data;
  }
  @Mutation
  [Mutations.SET_COACH_RADAR_DATA_LOADING](value) {
    this.CoachRadarDataLoading = value;
  }

  @Action
  [Actions.LOAD_COACH_BOARD_DATA](payload) {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("coach/get-stats", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PLAYERS, data["result"]);
            resolve(data["result"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantSensesBoardStats"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.LOAD_COACH_BOARD_RADAR_DATA](payload) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_COACH_RADAR_DATA_LOADING, true);
    return new Promise<ClientRadarData[]>((resolve, reject) => {
      ApiService.post("coach/get-radar-stats", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_COACH_RADAR_DATA, data["result"]);
            resolve(data["result"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.coach.errors.cantGetRadarData"
          );
          reject();
        })
        .finally(() =>
          this.context.commit(Mutations.SET_COACH_RADAR_DATA_LOADING, false)
        );
    });
  }

  @Action
  [Actions.GENERATE_COACH_RESULTS_PDF](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("coach/generate-results-pdf", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.result);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.coach.errors.failedToGenerateExport"
          );
          reject();
        });
    });
  }
}

import { TrainingWeek } from "@/store/interfaces/TrainingWeek";

export interface TrainingEventOverride {
  id: number | null;
  training_event_id: number;
  training_week_id: number;
  trainingWeek: TrainingWeek;
  content: string;
  note: string;
  note_displayed: number;
  note_changed: boolean;
  diary_changed: boolean;
  updated_by: number;
  additional_data: {
    sleep_diary: SleepDiary;
  };
}

export interface SleepDiary {
  days: SleepDiaryDay[];
}

export interface SleepDiaryDay {
  day_nr: number;
  bed_time_at: string;
  lights_off_at: string;
  fall_asleep_in: string;
  wake_up_at: string;
  of_bed_at: string;
  awake_moments_count: number;
  time_in_bed: string;
}
export const defaultSleepDiary = {
  days: [
    {
      day_nr: 1,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 2,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 3,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 4,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 5,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 6,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
    {
      day_nr: 7,
      bed_time_at: "",
      lights_off_at: "",
      fall_asleep_in: "",
      wake_up_at: "",
      of_bed_at: "",
      awake_moments_count: 0,
      time_in_bed: "",
    } as SleepDiaryDay,
  ],
} as SleepDiary;

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { UserContactEvent } from "@/store/interfaces/User";

@Module
export default class UserContactEventModule extends VuexModule {
  redirectTo = "";
  loadedUserContactEvent: UserContactEvent = {} as UserContactEvent;
  userContactEvents: UserContactEvent[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedUserContactEvent(): UserContactEvent {
    return { ...this.loadedUserContactEvent } as UserContactEvent;
  }

  /**
   * Retreive current loaded userContactEvents
   */
  get getUserContactEvents(): UserContactEvent[] {
    return this.userContactEvents;
  }

  /**
   * Get last pagination setup
   */
  get getUserContactEventsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getUserContactEventsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_USER_CONTACT_EVENT](
    UserContactEvent: UserContactEvent
  ) {
    this.loadedUserContactEvent = UserContactEvent;
  }

  @Mutation
  [Mutations.SET_USER_CONTACT_EVENTS](data) {
    this.userContactEvents = data.data;
  }

  @Mutation
  [Mutations.SET_USER_CONTACT_EVENTS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_USER_CONTACT_EVENTS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_USER_CONTACT_EVENTS](payload) {
    ApiService.setHeader();
    return new Promise<UserContactEvent[]>((resolve, reject) => {
      ApiService.post("user-contact-event", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_USER_CONTACT_EVENTS,
              data["userContactEvents"]
            );
            this.context.commit(
              Mutations.SET_USER_CONTACT_EVENTS_PAGINATION,
              data["userContactEvents"].pagination
            );
            resolve(data["userContactEvents"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userContactEvents.errors.cantGetUserContactEvents"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_USER_CONTACT_EVENT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<UserContactEvent>((resolve) => {
        const UserContactEvent = {} as UserContactEvent;
        this.context.commit(
          Mutations.SET_LOADED_USER_CONTACT_EVENT,
          UserContactEvent
        );
        resolve(UserContactEvent);
      });
    }
    return new Promise<UserContactEvent>((resolve, reject) => {
      ApiService.get(`user-contact-event/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_USER_CONTACT_EVENT,
              data["userContactEvent"]
            );
            resolve(data["userContactEvent"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userContactEvents.errors.commonGET_USER_CONTACT_EVENT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER_CONTACT_EVENT_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<UserContactEvent>((resolve, reject) => {
      ApiService.delete(`user-contact-event/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_USER_CONTACT_EVENT,
              data["userContactEvent"]
            );
            resolve(data["userContactEvent"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userContactEvents.errors.commonDELETE_USER_CONTACT_EVENT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_USER_CONTACT_EVENT](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("user-contact-event/add-event", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.result);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userContactEvents.errors.cantAddUserContactEvent"
          );
          reject();
        });
    });
  }
}

import React from "react";
import Color from "../Color";

class Cell extends React.Component {
  render() {
    const { content, onClick } = this.props;
    const cellStyle = {
      minWidth: "50px",
      width: "5vw",
      minHeight: "50px",
      height: "5vw",
      backgroundColor: Color.LightGray,
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      textTransform: "uppercase",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    };

    return (
      <div onClick={onClick} style={cellStyle}>
        {content}
      </div>
    );
  }
}

export default Cell;

import Color from "../../Components/Color";

const Variations = {
  Colors: Color.getAllColors(),
  Letters: ["A", "B", "C", "D", "E"],
  Numbers: [1, 2, 3, 4, 5],
  Symbols: ["!", "@", "#", "$", "%"],
};

export default Variations;

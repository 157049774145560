export const userGameDataSk = {
  userGameData: {
    errors: {
      cantGetData: "Chyba pri získavaní herných údajov",
    },
    table: {
      header: {
        created_at: "Vytvorené",
        level: "Level",
        data: "Údaje",
      },
      searchUserGameData: "Hľadať údaje",
      noRecords: "Neboli nájdené žiadne údaje",
    },
  },
};

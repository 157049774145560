export const widgetEn = {
  widgets: {
    total: "Total",
    ClientsWithoutTraining: {
      title: "Clients not in training",
    },
    ClientsAfterTraining: {
      title: "Clients after training",
    },
    ClientsJustFinishedTraining: {
      title: "Clients just finished training",
    },
    ClientsAfterDiagnostic: {
      title: "Clients after diagnostic",
    },
    ClientsEndingProgram: {
      title: "Clients with ending training",
    },
    ClientsOrderDiagnostic: {
      title: "Clients to make an appointment for diagnostics",
    },
    ClientsFirstWeekOfTraining: {
      title: "Clients in first week of training",
    },
    ClientsTrainingRenewal: {
      title: "Training renewal",
    },
    ClientsLeads: {
      title: "Leads",
    },
    ChatMessagesHistory: {
      title: "Chat history",
      searchMessages: "Search in messages",
      tooltips: {
        openChat: "Open chat",
        markAsUnread: "Mark as unread",
      },
      table: {
        read: "Read",
        created_at: "Created at",
      },
    },
    ClientsScheduledToContact: {
      title: "Clients scheduled to contact",
      scheduledOn: "Plánováno na",
    },
    players: {
      title: "List of players",
    },
    inOfficeStats: {
      categories: {
        eye_muscles: "Eye muscles",
        flexibility: "Flexibility",
        flipper_right: "Right eye",
        flipper_left: "Left eye",
        total: "Total",
      },
    },
    eyeFlexibilityStats: {
      categories: {
        eye_muscles: "Eye muscles",
        flexibility: "Flexibility",
        flipper_right: "Right eye",
        flipper_left: "Left eye",
        total: "Total",
      },
    },
    eyeStabilityStats: {
      categories: {
        eye_muscles: "Eye muscles",
        eye_muscles_far: "Eye muscles far",
        eye_muscles_close: "Eye muscles close",
        total: "Stability",
      },
    },
    coach: {
      results: "Results",
      games: "Games",
      radarTitle: "Clients compare graph",
      invalidDataMessage:
        "It seems that the measured data are strongly deviating from the values that can be achieved by correct measurements.",
      clientSettings: "Graph settings",
      radarComputedValues: {
        __average: "Team average",
      },
      export: {
        title: "Isenses report",
        button: "Generate report",
        pdfGenerated: "Report was generated",
        graphTitles: {
          total: "Clients compare graph",
          average: "Team average",
        },
      },
      gamesForClient: "Games for client",
      backToListOfClients: "Back to list of clients",
      historyListTitle: "Measurements",
      clickForDetail: "Detail of the result is available after click.",
      comparisionWithPreviousTranining: "comparision with previous tranining",
      currentValue: "Current value",
      previousValue: "Previous value",
      initialValue: "Initial value",
      diagnosticTitles: {
        senses_board: "SensesBoard",
        in_office: "Eye muscles",
        eye_flexibility: "Eye flexibility",
        eye_stability: "Eye stability",
        total: "Total",
      },
      group: "Grouped",
      filter: "Filter",
      individuals: "Individuals",
      noPlayers: "No data available",
      noDiagnostics: "No diagnostic data avaliable",
      form: {
        userGroup: "Group",
        players: "Players",
      },
      errors: {
        cantGetRadarData: "Failed to download data for radar chart",
      },
    },
    sensesBoardStats: {
      title: "SensesBoard results",
      historyListTitle: "Measurements",
      invalidDataMessage:
        "It seems that the measured data are strongly deviating from the values that can be achieved by correct measurements.",
      SBPotential: {
        potential: "Potential",
        graph: {
          title: "Improvement potential",
          players: "By players",
          groups: "By groups",
          categories: {
            game_reading: "Understanding the situation",
            orientation_in_space: "Orientation in space",
            refocus_both_eyes: "Sight speed",
            coordination: "Sight precision",
            understanding_situation: "Neurovision",
            total: "Total",
          },
        },
        modal: {
          title: "Individual potential",
          titleClient: "SensesBoard - Your potential",
          performance: "Performance",
          potential: "Potential",
          value: "Value",
          recommendation: "Recommendation",
          graphs: {
            game_reading: {
              title: "Understanding the situation",
            },
            orientation_in_space: {
              title: "Orientation in space",
            },
            refocus_both_eyes: {
              title: "Sight speed",
            },
            coordination: {
              title: "Sight precision",
            },
            understanding_situation: {
              title: "Neurovision",
            },
            total: {
              title: "Neurovision",
            },
          },
        },
      },
      SBNormativeIndex: {
        title: "Normative index",
        total: "Total",
        group: "Group",
        rank: "Rank",
        name: "Name",
        index: "Index",
        legend: {
          aboveAverage: "Above average",
          average: "Average",
          bellowAverage: "Bellow average",
        },
      },
      errors: {
        cantSensesBoardStats: "Failed to fetch SensesBoard statistics data",
        cantExportSensesBoardStats:
          "Failed to export SensesBoard statistics data",
      },
      cantLoadNiLegend: "Failed to load extra data for SensesBoard display",
    },
  },
};

export const registrationTokenSk = {
  registrationTokens: {
    toolTips: {
      edit: "Upraviť registračný kód {token}",
      delete: "Odstrániť registračný kód {token}",
      link: "Zobraziť odkaz pre registračný kód {token}",
    },
    editTitle: "Upraviť registračný kód",
    newTitle: "Nový registračný kód",
    addRegistrationToken: "Pridať registračný kód",
    errors: {
      notFound: "Registračný kód s ID {id} nebol nájdený",
      notYourRole: "Nemáte právo zobraziť registračný kód s ID {id}",
      nameExists: "Registračný kód `{token}` už existuje",
      registrationTokenAddFail: "Nepodařilo se vytvořit registračný kód",
      commonGET_REGISTRATION_TOKEN_BY_ID:
        "Chyba pri získavaní údajov o registračnom kóde",
      commonDELETE_REGISTRATION_TOKEN_BY_ID:
        "Chyba pri odstraňovaní registračného kódu",
      commonDELETE_REGISTRATION_TOKENS:
        "Nepodarilo sa odstrániť registračný kód",
      cantGetRegistrationTokens:
        "Chyba pri získávaní údajov o registračných kódoch",
    },
    form: {
      token: "Kód",
      company: "Firma",
      program: "Produkt",
      events: "Udalosti",
      totalUses: "Počet použití",
      userGroup: "Skupina",
      registrationTokenChanged: "Registračný kód bol upravený",
      registrationTokenChangedFail: "Nepodarilo sa upraviť registračný kód",
      registrationTokenAdded: "Nový registračný kód bol vytvorený",
      registrationTokenAddFail: "Nepodarilo sa vytvoriť nový registračný kód",
    },
    modal: {
      titleAdd: "Pridať nový registračný kód",
      titleUpdate: "Upraviť registračný kód `{token}`",
      titleLink: "Odkaz pre registračný kód `{token}`",
    },
    table: {
      header: {
        token: "Kód",
        company: "Firma",
        program: "Produkt",
        userGroup: "Skupina",
        events: "Udalosti",
        actions: "Akcie",
        uses: "Počet použití",
      },
      deleteRegistrationTokenQuestion:
        "Naozaj chcete odstrániť registračný kód <strong>{token}</strong>?",
      searchRegistrationTokens: "Hľadať registračný kód",
      registrationTokenDeleted:
        "Registračný kód {registrationToken} bol odstránený",
      registrationTokensDeleted: "Registračné kódy boli odstránené",
      noRecords: "Neboli nájdené žiadne registračné kódy",
    },
    events: {
      S2boardEnable: {
        title: "Aktivácia SBoard",
      },
      InOfficeEnable: {
        title: "Aktivácia Diagnostiky v ordinácii",
      },
      EyeStabilityEnable: {
        title: "Aktivácia Diagnostiky stability zraku",
      },
      EyeFlexibilityEnable: {
        title: "Aktivácia Diagnostiky rýchlosti očných svalov",
      },
    },
  },
};

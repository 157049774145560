import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import Color from "../../Components/Color";

const getRandomCoordinates = (rows, cols, count) => {
  const coordinates = new Set();
  while (coordinates.size < count) {
    const row = Math.floor(Math.random() * rows);
    const col = Math.floor(Math.random() * cols);
    coordinates.add(`${row}-${col}`);
  }
  return Array.from(coordinates).map((coord) => coord.split("-").map(Number));
};

const getRandomLetters = (count) => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const uniqueLetters = new Set();
  while (uniqueLetters.size < count) {
    const randomIndex = Math.floor(Math.random() * letters.length);
    uniqueLetters.add(letters[randomIndex]);
  }
  return Array.from(uniqueLetters);
};

export const useRememberTheGridGame = (settings, onGameEnd, gridRef) => {
  const { rows, cols, letterCount, showDuration } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [showStartButton, setShowStartButton] = useState(true);
  const [highlightedFields, setHighlightedFields] = useState([]);
  const [clickTimes, setClickTimes] = useState([]);
  const [roundClickTimes, setRoundClickTimes] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [targetLetter, setTargetLetter] = useState("");
  const [hasGuessed, setHasGuessed] = useState(false);
  const gameTimer = useRef(null);
  const showTimer = useRef(null);
  const roundClickTimesRef = useRef(roundClickTimes);
  const [roundStartTime, setRoundStartTime] = useState(null);

  useEffect(() => {
    roundClickTimesRef.current = roundClickTimes;
  }, [roundClickTimes]);

  const startGame = () => {
    setShowStartButton(false);
    setIsRunning(true);
    setClickTimes([]);
    setRoundClickTimes([]);
    setHasGuessed(false);
    generateNewGrid();

    gameTimer.current = setTimeout(() => {
      setIsRunning(false);
      if (clickTimes.length > 0) {
        const updatedRounds = [...roundClickTimesRef.current, clickTimes];
        onGameEnd({
          games: [
            {
              id: "RememberTheGridGame",
              rounds: updatedRounds,
            },
          ],
        });
      } else {
        onGameEnd({
          games: [
            {
              id: "RememberTheGridGame",
              rounds: roundClickTimesRef.current,
            },
          ],
        });
      }
    }, 60000);
  };

  const generateNewGrid = () => {
    const newGridContent = Array.from({ length: rows }, () =>
      Array.from({ length: cols }, () => ({
        isHighlighted: false,
        letter: null,
      }))
    );

    const coordinates = getRandomCoordinates(rows, cols, letterCount);
    const letters = getRandomLetters(letterCount);
    coordinates.forEach(([row, col], index) => {
      newGridContent[row][col].isHighlighted = true;
      newGridContent[row][col].letter = letters[index];
    });

    setGridContent(
      newGridContent.map((row) =>
        row.map((cell) => (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "24px",
            }}
          >
            {cell.isHighlighted ? cell.letter : ""}
          </div>
        ))
      )
    );
    setHighlightedFields(coordinates);
    setIsShowing(true);

    showTimer.current = setTimeout(() => {
      setIsShowing(false);
      const targetIndex = Math.floor(Math.random() * letters.length);
      setTargetLetter(letters[targetIndex]);
      setGridContent(
        newGridContent.map((row) =>
          row.map((cell) => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0px",
              }}
            >
              {cell.letter}
            </div>
          ))
        )
      );

      setRoundStartTime(Math.floor(performance.now()));
    }, showDuration * 1000);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    if (!isRunning || isShowing || hasGuessed) return;

    setHasGuessed(true);
    const clickTime = Math.floor(performance.now()) - roundStartTime;
    const isCorrect = highlightedFields.some(
      ([row, col]) =>
        row === rowIndex &&
        col === colIndex &&
        gridRef.current.getCell(row, col).props.children === targetLetter
    );
    const updatedClickTimes = { clickTime, isCorrect };
    setClickTimes(updatedClickTimes);

    gridRef.current.updateCell(
      rowIndex,
      colIndex,
      <div
        style={{
          backgroundColor: isCorrect ? Color.Teal : Color.Red,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          color: "white",
        }}
      >
        {gridRef.current.getCell(rowIndex, colIndex).props.children}
      </div>
    );

    setTimeout(() => {
      setRoundClickTimes((prevRounds) => [...prevRounds, updatedClickTimes]);
      setClickTimes([]);
      setHasGuessed(false);
      setHighlightedFields([]);
      generateNewGrid();
      setTargetLetter("");
    }, 500); // Wait 500ms before generating a new grid
  };

  const gridComponent = (
    <Grid
      gridContent={gridContent}
      onCellClick={(rowIndex, colIndex) => handleCellClick(rowIndex, colIndex)}
      ref={gridRef}
    />
  );

  return {
    gridComponent,
    showStartButton,
    startGame,
    isRunning,
    targetLetter,
  };
};

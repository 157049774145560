export const trainingSk = {
  trainings: {
    toolTips: {
      edit: "Upraviť tréning {training}",
      delete: "Odstrániť tréning {training}",
    },
    contentPreview: {
      title: "Náhľad obsahu",
    },
    contentEditor: {
      title: "Editor obsahu",
    },
    editTitle: "Upraviť tréning",
    newTitle: "Nový tréning",
    addTraining: "Pridať tréning",
    errors: {
      notFound: "Tréning s ID {id} nebol nájdený",
      notYourRole: "Nemáte oprávnenie zobraziť tréning s ID {id}",
      nameExists: "Tréning s názvom `{name}` už existuje",
      commonGET_TRAINING_BY_ID: "Chyba pri získavaní údajov o tréningu",
      commonDELETE_TRAINING_BY_ID: "Chyba pri odstraňovaní tréningu",
      commonDELETE_TRAININGS: "Nepodarilo sa odstrániť tréningy",
      cantGetTrainings: "Chyba pri získavaní údajov o tréningoch",
    },
    form: {
      name: "Názov",
      name_client: "Názov pre klienta",
      nrOfWeeks: "Dĺžka tréningu",
      weeks: "týždňov",
      week: "Týždeň",
      specialization: "Specializácia",
      specializations: "Specializácie",
      content: "Obsah",
      company: "Firma",
      language: "Jazyk",
      files: "Prílohy",
      trainingChanged: "Tréning bol upravený",
      trainingChangedFail: "Nepodarilo sa upraviť tréning",
      trainingAdded: "Nový tréning bol vytvorený",
      trainingAddFail: "Nepodarilo sa vytvoriť nový tréning",
      weekFilePlaceholder: "Zadajte názov prílohy",
    },
    table: {
      header: {
        name: "Názov",
        name_client: "Názov pre klienta",
        nrOfWeeks: "Dĺžka tréningu",
        company: "Firma",
        language: "Jazyk",
        specialization: "Specializácia",
        specializations: "Specializácie",
        actions: "Akcie",
      },
      deleteTrainingQuestion:
        "Určite chcete odstrániť tréning <strong>{training}</strong>?",
      searchTrainings: "Hľadať tréningy",
      trainingDeleted: "Tréning {training} bol odstránený",
      trainingsDeleted: "Tréningy boli odstránené",
      noRecords: "Neboli nájdené žiadne tréningy",
    },
  },
};

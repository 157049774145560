export const bodyPartCs = {
  bodyParts: {
    title: "Části těla",
    toolTips: {
      edit: "Upravit část těla {bodyPart}",
      delete: "Odstranit část těla {bodyPart}",
    },
    editTitle: "Upravit část těla",
    newTitle: "Nová část těla",
    addBodyPart: "Přidat část těla",
    errors: {
      notFound: "Část těla id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit část těla id {id}",
      commonGET_BODY_PART_BY_ID: "Chyba při získávání dat o částech těla",
      commonDELETE_BODY_PART_BY_ID: "Chyba při odstraňování části těla",
      commonDELETE_BODY_PARTS: "Nepodařilo se odstranit část těla",
      cantGetBodyParts: "Chyba při získávání dat o částech těla",
    },
    form: {
      addBodyPart: "Přidat část těla",
      name: "Název",
      company: "Firmy",
      bodyPartChanged: "Část těla byla upravena",
      bodyPartChangedFail: "Nepodařilo se upravit část těla",
      bodyPartAdded: "Nová část těla byla vytvořena",
      bodyPartAddFail: "Nepodařilo se vytvořit novou část těla",
    },
    table: {
      header: {
        name: "Název",
        company: "Firmy",
        actions: "Akce",
      },
      deleteBodyPartQuestion:
        "Opravdu chcete odstranit část těla <strong>{bodyPart}</strong>?",
      searchBodyParts: "Hledat část těla",
      bodyPartDeleted: "Část těla {bodyPart} byla odstraněna",
      bodyPartsDeleted: "Části těla byly odstraněny",
      noRecords: "Nebyly nalezeny žádné části těla",
    },
  },
};

export const companyBankAccountEn = {
  companyBankAccounts: {
    toolTips: {
      edit: "Edit company bank account {account}",
      delete: "Delete company bank account {account}",
      lastSyncState: "Last sync on {date}",
    },
    editTitle: "Edit company bank account",
    newTitle: "New company bank account",
    addCompanyBankAccount: "Add company bank account",
    errors: {
      notFound: "Company bank account id {id} not found",
      notYourRole: "Cant access data for company bank account id {id}",
      currencyExists:
        "Company bank account for currency `{currency_id}` already exists",
      commonGET_COMPANY_BANK_ACCOUNT_BY_ID:
        "Error retrieving company bank account data",
      commonDELETE_COMPANY_BANK_ACCOUNT_BY_ID:
        "Error deletin company bank account",
      commonDELETE_COMPANY_BANK_ACCOUNTS:
        "Error deleting company bank accounts",
      cantGetCompanyBankAccounts: "Failed to fetch company bank accounts data",
    },
    form: {
      company: "Company",
      currency: "Currency",
      bank_name: "Bank name",
      account: "Account nr.",
      iban: "IBAN",
      swift_bic: "SWIFT/BIC",
      companyBankAccountChanged: "Company bank account was stored",
      companyBankAccountChangedFail: "Failed to store company bank account",
      companyBankAccountAdded: "New company bank account was created",
      companyBankAccountAddFail: "Failed to add new company bank account",
    },
    log: {
      table: {
        date: "Date",
        type: "State",
        text: "Text",
      },
    },
    modal: {
      titleAdd: "Add new company bank account",
      titleUpdate: "Edit company bank account `{account}`",
    },
    table: {
      header: {
        company: "Company",
        currency: "Currency",
        bank_name: "Bank name",
        account: "Account nr.",
        iban: "IBAN",
        syncState: "API connection",
        swift_bic: "SWIFT/BIC",
        actions: "Actions",
      },
      runSync: "Run synchronization",
      getSyncLog: "Show last sync log",
      deleteCompanyBankAccountQuestion:
        "Do you really want to delete company bank account <strong>{account}</strong>?",
      searchCompanyBankAccounts: "Search company bank account",
      companyBankAccountDeleted:
        "Company bank account {companyBankAccount} was deleted",
      companyBankAccountsDeleted: "Company bank accounts were deleted",
      noRecords: "No company bank accounts found",
    },
  },
};

export const trainingEventEn = {
  trainingEvents: {
    errors: {
      cantGetSchedule: "Failed to get training schedule",
      cantGetTrainingEventOverride: "Failed to override training week content",
    },
    saveNote: "Save note",
    saveSleepDiary: "Save sleep diary",
    noteChanged: "Note was updated",
    contentChanged: "Content was updated",
    sleepDiaryChanged: "Sleep diary was updated",
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Specialization } from "@/store/interfaces/Specialization";

@Module
export default class SpecializationModule extends VuexModule {
  redirectTo = "";
  loadedSpecialization: Specialization = {} as Specialization;
  Specializations: Specialization[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedSpecialization(): Specialization {
    return { ...this.loadedSpecialization } as Specialization;
  }

  /**
   * Retreive current loaded specializations
   */
  get getSpecializations(): Specialization[] {
    return this.Specializations;
  }

  /**
   * Get last pagination setup
   */
  get getSpecializationsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getSpecializationsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_SPECIALIZATION](Specialization: Specialization) {
    this.loadedSpecialization = Specialization;
  }

  @Mutation
  [Mutations.SET_SPECIALIZATIONS](data) {
    this.Specializations = data.data;
  }

  @Mutation
  [Mutations.SET_SPECIALIZATIONS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_SPECIALIZATIONS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_SPECIALIZATIONS](payload) {
    ApiService.setHeader();
    return new Promise<Specialization[]>((resolve, reject) => {
      ApiService.post("specializations", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_SPECIALIZATIONS,
              data["specializations"]
            );
            this.context.commit(
              Mutations.SET_SPECIALIZATIONS_PAGINATION,
              data["specializations"].pagination
            );
            resolve(data["specializations"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "specializations.errors.cantGetSpecializations"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_SPECIALIZATION_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Specialization>((resolve) => {
        const Specialization = {} as Specialization;
        this.context.commit(
          Mutations.SET_LOADED_SPECIALIZATION,
          Specialization
        );
        resolve(Specialization);
      });
    }
    return new Promise<Specialization>((resolve, reject) => {
      ApiService.get(`specialization/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_SPECIALIZATION,
              data["specialization"]
            );
            resolve(data["specialization"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "specializations.errors.commonGET_SPECIALIZATION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SPECIALIZATION_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Specialization>((resolve, reject) => {
      ApiService.delete(`specialization/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_SPECIALIZATION,
              data["specialization"]
            );
            resolve(data["specialization"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "specializations.errors.commonDELETE_SPECIALIZATION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SPECIALIZATION](Specialization: Specialization) {
    const payload = JSON.parse(JSON.stringify(Specialization));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`specialization/${Specialization.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_SPECIALIZATION,
              data["specialization"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Specialization.id,
              name: Specialization.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "specializations.form.specializationChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_SPECIALIZATION](Specialization: Specialization) {
    const payload = JSON.parse(JSON.stringify(Specialization));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`specialization`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_SPECIALIZATION,
              data["specialization"]
            );
            resolve(data["specialization"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Specialization.id,
              name: Specialization.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "specializations.form.specializationAddFail"
          );
          reject();
        });
    });
  }
}

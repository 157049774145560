export const fileSk = {
  files: {
    selector: {
      toolTips: {
        upload: "Nahrať",
        select: "Vybrať zo zoznamu",
        cancel: "Zrušiť",
        preview: "Zobraziť",
      },
    },
    toolTips: {
      edit: "Upraviť súbor {file}",
      delete: "Odstrániť súbor {file}",
      preview: "Náhľad súboru {file}",
    },
    editTitle: "Upraviť súbor",
    newTitle: "Nový súbor",
    addFile: "Pridať súbor",
    fileSelector: {
      title: "Vyberte súbor pre vloženie do textu",
    },
    errors: {
      notFound: "Súbor s ID {id} nebol nájdený",
      notYourRole: "Nemáte oprávnenie zobraziť súbor s ID {id}",
      nameExists: "Súbor s názvom `{name}` už existuje",
      commonGET_FILE_BY_ID: "Chyba pri získavaní údajov o súbore",
      commonDELETE_FILE_BY_ID: "Chyba pri odstraňovaní súboru",
      commonDELETE_FILES: "Nepodarilo sa odstrániť súbory",
      cantGetFiles: "Chyba pri získavaní údajov o súboroch",
      fileUploadFailed: "Nepodarilo sa nahrať súbor",
      diagnosticImportBatchFailed: "Chyba pri imporote diagnostických dát",
      resultsExportFailed: "Chyba pri exporote diagnostických dát",
      leadsImportBatchFailed: "Chyba pri imporote leadov",
      notAllowedFiletype: "Nepovolený typ súboru",
    },
    types: {
      pdf: "PDF",
      image: "Obrázok",
      video: "Video",
      audio: "Audio",
      icon: "Ikonka",
      document: "Dokument",
    },
    form: {
      name: "Názov",
      company: "Firma",
      file: "Súbor",
      specializations: "Špecializácie",
      description: "Popis",
      language: "Jazyk",
      fileChanged: "Súbor bol upravený",
      fileChangedFail: "Nepodarilo sa upraviť súbor",
      fileAdded: "Nový súbor bol vytvorený",
      fileAddFail: "Nepodarilo sa vytvoriť nový súbor",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firma",
        language: "Jazyk",
        type: "Typ",
        specializations: "Špecializácie",
        actions: "Akcie",
      },
      deleteFileQuestion:
        "Naozaj chcete odstrániť súbor <strong>{file}</strong>?",
      searchFiles: "Hľadať súbory",
      fileDeleted: "Súbor {file} bol odstránený",
      filesDeleted: "Súbory boli odstránené",
      noRecords: "Neboli nájdené žiadne súbory",
    },
  },
};

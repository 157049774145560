export const unitCs = {
  units: {
    title: "Jednotky",
    toolTips: {
      edit: "Upravit jednotku {unit}",
      delete: "Odstranit jednotku {unit}",
    },
    editTitle: "Upravit jednotku",
    newTitle: "Nová jednotka",
    addUnit: "Přidat jednotku",
    errors: {
      notFound: "Jednotka id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit jednotku id {id}",
      commonGET_UNIT_BY_ID: "Chyba při získávání dat o jednotkách",
      commonDELETE_UNIT_BY_ID: "Chyba při odstraňování jednotky",
      commonDELETE_UNITS: "Nepodařilo se odstranit jednotku",
      cantGetUnits: "Chyba při získávání dat o jednotkách",
    },
    form: {
      addUnit: "Přidat jednotku",
      name: "Název",
      type: "Typ",
      company: "Firmy",
      unitChanged: "Jednotka byla upravena",
      unitChangedFail: "Nepodařilo se upravit jednotku",
      unitAdded: "Nová jednotka byla vytvořena",
      unitAddFail: "Nepodařilo se vytvořit novou jednotku",
    },
    table: {
      header: {
        name: "Název",
        company: "Firmy",
        type: "Typ",
        actions: "Akce",
      },
      deleteUnitQuestion:
        "Opravdu chcete odstranit jednotku <strong>{unit}</strong>?",
      searchUnits: "Hledat jednotku",
      unitDeleted: "Jednotka {unit} byla odstraněna",
      unitsDeleted: "Jednotky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné jednotky",
    },
    types: {
      time: "čas",
      count: "počet",
    },
  },
};

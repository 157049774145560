import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Exercise } from "@/store/interfaces/Exercise";
import type { ExerciseCategory } from "@/store/interfaces/ExerciseCategory";

@Module
export default class ExerciseCategoryModule extends VuexModule {
  redirectTo = "";
  loadedExerciseCategory: ExerciseCategory = {} as ExerciseCategory;
  ExerciseCategories: ExerciseCategory[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedExerciseCategory(): ExerciseCategory {
    return this.loadedExerciseCategory.id
      ? this.loadedExerciseCategory
      : ({
          ...this.loadedExerciseCategory,
          ...{
            icon: this.loadedExerciseCategory.icon
              ? this.loadedExerciseCategory.icon
              : {},
            color: this.loadedExerciseCategory.color
              ? this.loadedExerciseCategory.color
              : "#dedede",
            resources: this.loadedExerciseCategory.resources
              ? this.loadedExerciseCategory.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    client_name: "",
                    exercise_category_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as ExerciseCategory);
  }

  /**
   * Retreive current loaded exercises
   */
  get getExerciseCategories(): ExerciseCategory[] {
    return this.ExerciseCategories;
  }

  /**
   * Get last pagination setup
   */
  get getExerciseCategoriesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getExerciseCategoriesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_EXERCISE_CATEGORY](ExerciseCategory: ExerciseCategory) {
    this.loadedExerciseCategory = ExerciseCategory;
  }

  @Mutation
  [Mutations.SET_EXERCISE_CATEGORIES](data) {
    this.ExerciseCategories = data.data;
  }

  @Mutation
  [Mutations.SET_EXERCISE_CATEGORIES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_EXERCISE_CATEGORIES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_EXERCISE_CATEGORIES](payload) {
    ApiService.setHeader();
    return new Promise<ExerciseCategory[]>((resolve, reject) => {
      ApiService.post("exercise-categories", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_EXERCISE_CATEGORIES,
              data["exercise_categories"]
            );
            this.context.commit(
              Mutations.SET_EXERCISE_CATEGORIES_PAGINATION,
              data["exercise_categories"].pagination
            );
            resolve(data["exercise_categories"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseCategories.errors.cantGetExerciseCategiries"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_EXERCISE_CATEGORY_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<ExerciseCategory>((resolve) => {
        const exerciseCategory = {} as ExerciseCategory;
        this.context.commit(
          Mutations.SET_LOADED_EXERCISE_CATEGORY,
          exerciseCategory
        );
        resolve(exerciseCategory);
      });
    }
    return new Promise<ExerciseCategory>((resolve, reject) => {
      ApiService.get(`exercise-category/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_CATEGORY,
              data["exercise_category"]
            );
            resolve(data["exercise_category"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseCategories.errors.commonGET_EXERCISE_CATEGORY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EXERCISE_CATEGORY_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Exercise>((resolve, reject) => {
      ApiService.delete(`exercise-category/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_CATEGORY,
              data["exercise_category"]
            );
            resolve(data["exercise_category"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseCategories.errors.commonDELETE_EXERCISE_CATEGORY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EXERCISE_CATEGORY](exerciseCategory: ExerciseCategory) {
    const payload = JSON.parse(JSON.stringify(exerciseCategory));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`exercise-category/${exerciseCategory.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_CATEGORY,
              data["exercise_category"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: exerciseCategory.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseCategories.form.exerciseCategoryChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EXERCISE_CATEGORY](exerciseCategory: ExerciseCategory) {
    const payload = JSON.parse(JSON.stringify(exerciseCategory));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`exercise-category`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE,
              data["exercise_category"]
            );
            resolve(data["exercise_category"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: exerciseCategory.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseCategories.form.exerciseCategoryAddFail"
          );
          reject();
        });
    });
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetronomeComponent = _resolveComponent("MetronomeComponent")!

  return (_openBlock(), _createBlock(_component_MetronomeComponent, {
    "bpm-min": 35,
    "bpm-max": 200,
    "beats-per-minute": _ctx.prepareSettings(_ctx.tool)['bpm'],
    volume: _ctx.getVolume()
  }, null, 8, ["beats-per-minute", "volume"]))
}
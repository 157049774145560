export const declensionEn = {
  declensions: {
    day: {
      1: "day",
      2: "days",
      3: "days",
      4: "days",
      5: "days",
    },
    week: {
      1: "week",
      2: "weeks",
      3: "weeks",
      4: "weeks",
      5: "weeks",
    },
    month: {
      1: "month",
      2: "months",
      3: "months",
      4: "months",
      5: "months",
    },
    year: {
      1: "year",
      2: "years",
      3: "years",
      4: "years",
      5: "years",
    },
    minute: {
      1: "minute",
      2: "minutes",
      3: "minutes",
      4: "minutes",
      5: "minutes",
    },
    second: {
      1: "second",
      2: "seconds",
      3: "seconds",
      4: "seconds",
      5: "seconds",
    },
    word: {
      1: "word",
      2: "words",
      3: "words",
      4: "words",
      5: "words",
    },
    letter: {
      1: "letter",
      2: "letters",
      3: "letters",
      4: "letters",
      5: "letters",
    },
  },
};

export const exerciseCategoryEn = {
  exerciseCategories: {
    toolTips: {
      edit: "Edit exercise category {exerciseCategory}",
      delete: "Delete exercise category {exerciseCategory}",
    },
    editTitle: "Edit exercise category",
    newTitle: "New exercise category",
    addExerciseCategory: "Add exercise category",
    errors: {
      notFound: "Exercise category {id} not found",
      notYourRole: "Cant access data for exercise category {id}",
      nameExists: "Exercise category named `{name}` already exists",
      commonGET_EXERCISE_CATEGORY_BY_ID:
        "Error retrieving exercise category data",
      commonDELETE_EXERCISE_CATEGORY_BY_ID: "Error deleting exercise category",
      commonDELETE_EXERCISE_CATEGORIES: "Error deleting exercise categorys",
      cantGetExerciseCategoriess: "Failed to fetch exercise categoriess data",
    },
    form: {
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      image: "Image",
      icon: "Icon",
      parentExerciseCategory: "Parent category",
      company: "Companies",
      exerciseCategoryChanged: "Exercise category was stored",
      exerciseCategoryChangedFail: "Failed to store exercise category",
      exerciseCategoryAdded: "New exercise category was created",
      exerciseCategoryAddFail: "Failed to add new exercise category",
    },
    table: {
      header: {
        name: "Name",
        icon: "Icon",
        parentExerciseCategory: "Parent category",
        company: "Companies",
        actions: "Actions",
      },
      deleteExerciseCategoryQuestion:
        "Do you really want to delete  exercise category <strong>{exerciseCategory}</strong>?",
      searchExerciseCategories: "Search exercise categories",
      exerciseCategoryDeleted:
        "Exercise category {exerciseCategory} was deleted",
      exerciseCategoriesDeleted: "Exercise categoriess were deleted",
      noRecords: "No exercise categoriess found",
    },
  },
};

export const exerciseCategorySk = {
  exerciseCategories: {
    toolTips: {
      edit: "Upraviť kategóriu cvičení {exerciseCategory}",
      delete: "Odstrániť kategóriu cvičení {exerciseCategory}",
    },
    editTitle: "Upraviť kategóriu cvičení",
    newTitle: "Nová kategória cvičení",
    addExerciseCategory: "Pridať kategóriu cvičení",
    errors: {
      notFound: "Kategória cvičení s ID {id} nebola nájdená",
      notYourRole: "Nemáte oprávnenie zobraziť kategóriu cvičení s ID {id}",
      nameExists: "Kategória cvičení s názvom `{name}` už existuje",
      commonGET_EXERCISE_CATEGORY_BY_ID:
        "Chyba pri získavaní údajov o kategóriách cvičení",
      commonDELETE_EXERCISE_CATEGORY_BY_ID:
        "Chyba pri odstraňovaní kategórie cvičení",
      commonDELETE_EXERCISE_CATEGORIES:
        "Nepodarilo sa odstrániť kategóriu cvičení",
      cantGetExerciseCategories:
        "Chyba pri získavaní údajov o kategóriách cvičení",
    },
    form: {
      name: "Názov",
      client_name: "Názov pre klienta",
      color: "Farba",
      icon: "Ikona",
      company: "Firmy",
      parentExerciseCategory: "Nadradená kategória",
      exerciseCategoryChanged: "Kategória cvičení bola upravená",
      exerciseCategoryChangedFail: "Nepodarilo sa upraviť kategóriu cvičení",
      exerciseCategoryAdded: "Nová kategória cvičení bola vytvorená",
      exerciseCategoryAddFail: "Nepodarilo sa vytvoriť novú kategóriu cvičení",
    },
    table: {
      header: {
        name: "Názov",
        icon: "Ikona",
        parentExerciseCategory: "Nadradená kategória",
        company: "Firmy",
        actions: "Akcie",
      },
      deleteExerciseCategoryQuestion:
        "Naozaj chcete odstrániť kategóriu cvičení <strong>{exerciseCategory}</strong>?",
      searchExerciseCategories: "Hľadať kategóriu cvičení",
      exerciseCategoryDeleted:
        "Kategória cvičení {exerciseCategory} bola odstránená",
      exerciseCategoriesDeleted: "Kategórie cvičení boli odstránené",
      noRecords: "Neboli nájdené žiadne kategórie cvičení",
    },
  },
};

import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class TrainingWeekModule extends VuexModule {
  @Action
  [Actions.UPLOAD_TRAINING_WEEK_FILE](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        `training-week/upload-file/${data.training_week_id}`,
        data.formData
      )
        .then(({ data }) => {
          if (data.success) {
            resolve(data.file);
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingWeeks.errors.fileUploadFailed"
          );
          reject();
        });
    });
  }
}

export const languageSk = {
  languages: {
    languageSelector: {
      title: "Vyberte jazyk",
    },
    toolTips: {
      edit: "Upraviť jazyk {language}",
      delete: "Odstrániť jazyk {language}",
    },
    editTitle: "Upraviť jazyk",
    newTitle: "Nový jazyk",
    addLanguage: "Pridať jazyk",
    errors: {
      notFound: "Jazyk s ID {id} nebol nájdený",
      notYourRole: "Nemáte právo zobraziť jazyk s ID {id}",
      nameExists: "Jazyk s názvom `{name}` už existuje",
      commonGET_LANGUAGE_BY_ID: "Chyba pri získávaní údajov o jazykoch",
      commonDELETE_LANGUAGE_BY_ID: "Chyba pri odstraňovaní jazykov",
      commonDELETE_LANGUAGES: "Nepodarilo sa odstrániť jazyky",
      cantGetLanguages: "Chyba pri získávaní údajov o jazykoch",
    },
    form: {
      name: "Názov",
      code: "Kód",
      flag: "Vlajka",
      languageChanged: "Jazyk bol upravený",
      languageChangedFail: "Nepodarilo sa upraviť jazyk",
      languageAdded: "Nový jazyk bol vytvorený",
      languageAddFail: "Nepodarilo sa vytvoriť nový jazyk",
    },
    table: {
      header: {
        name: "Názov",
        code: "Kód",
        flag: "Vlajka",
        actions: "Akcie",
      },
      deleteLanguageQuestion:
        "Naozaj chcete odstrániť jazyk <strong>{language}</strong>?",
      searchLanguages: "Hľadať jazyk",
      languageDeleted: "Jazyk {language} bol odstránený",
      languagesDeleted: "Jazyky boli odstránené",
      noRecords: "Neboli nájdené žiadne jazyky",
    },
  },
};

export const sportCs = {
  sports: {
    toolTips: {
      edit: "Upravit sport {sport}",
      delete: "Odstranit sport {sport}",
    },
    editTitle: "Upravit sport",
    newTitle: "Nový sport",
    addSport: "Přidat sport",
    errors: {
      notFound: "Sport id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit sport id {id}",
      nameExists: "Sport s nazvem `{name}` již existuje",
      commonGET_SPORT_BY_ID: "Chyba při získávání dat o sportu",
      commonDELETE_SPORT_BY_ID: "Chyba při odstraňování sportu",
      commonDELETE_SPORTS: "Nepodařilo se odstranit sport",
      cantGetSports: "Chyba při získávání dat o sportech",
    },
    form: {
      name: "Název",
      company: "Firma",
      sportChanged: "Sport byl upraven",
      sportChangedFail: "Nepodařilo se upravit sport",
      sportAdded: "Nový sport byl vytvořen",
      sportAddFail: "Nepodařilo se vytvořit nový sport",
    },
    table: {
      header: {
        name: "Název",
        company: "Firma",
        actions: "Akce",
      },
      deleteSportQuestion:
        "Opravdu chcete odstranit sport <strong>{sport}</strong>?",
      searchSports: "Hledat sport",
      sportDeleted: "Sport {sport} byl odstraněn",
      sportsDeleted: "Sporty byly odstraněny",
      noRecords: "Nebyly nalezeny žádné sporty",
    },
  },
};

import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ModalModule extends VuexModule {
  get getModalTitle(): string {
    return this._title;
  }

  get getModalBody(): string {
    return this._body;
  }
  private _title = "";
  private _body = "";

  @Mutation
  [Mutations.SET_MODAL_TITLE](title: string) {
    this._title = title;
  }

  @Mutation
  [Mutations.SET_MODAL_BODY](body: string) {
    this._body = body;
  }
}

import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import Variations from "./variations";
import Color from "../../Components/Color";

export const usePressAllMultipleGame = (settings, onGameEnd) => {
  const { rows, cols, variation } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [showStartButton, setShowStartButton] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [currentVariation, setCurrentVariation] = useState(null);
  const [currentVariationIndex, setCurrentVariationIndex] = useState(0);
  const [timer, setTimer] = useState(60); // 1 minute timer
  const [fieldClearTimes, setFieldClearTimes] = useState([]);
  const variations = useRef(variation);
  const variationCount = useRef({});
  const gridRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    if (clickCount === rows * cols && currentVariation != null) {
      const endTime = Math.floor(performance.now());
      const totalTime = endTime - startTime;
      setFieldClearTimes((prevTimes) => [...prevTimes, totalTime]);
      resetGrid();
    }
  }, [clickCount, currentVariation, rows, cols]);

  useEffect(() => {
    if (
      currentVariation &&
      variationCount.current[currentVariation] === 0 &&
      currentVariationIndex < variations.current.length - 1
    ) {
      setCurrentVariationIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        setCurrentVariation(variations.current[newIndex]);
        return newIndex;
      });
    }
  }, [
    variationCount.current[currentVariation],
    currentVariationIndex,
    variations.current.length,
  ]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerRef.current);
      onGameEnd({
        games: [
          {
            id: "PressAllMultipleGame",
            fieldClearTimes: fieldClearTimes,
          },
        ],
      });
    }
  }, [timer]);

  const handleStartGame = () => {
    setShowStartButton(false);
    setStartTime(null);
    setClickCount(0);
    setCurrentVariationIndex(0);
    setCurrentVariation(variations.current[0]);
    setTimer(60);
    setFieldClearTimes([]);

    populateGrid();

    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  const populateGrid = () => {
    const newVariationCount = {};
    const newGridContent = Array.from({ length: rows }, () =>
      Array.from({ length: cols }, () => {
        const variationItem =
          variations.current[
            Math.floor(Math.random() * variations.current.length)
          ];
        newVariationCount[variationItem] =
          (newVariationCount[variationItem] || 0) + 1;
        return {
          variation: variationItem,
          isPressed: false,
        };
      })
    );

    variationCount.current = newVariationCount;

    setGridContent(
      newGridContent.map((row) =>
        row.map((cell) => (
          <div
            style={{
              backgroundColor:
                variation === Variations.Colors
                  ? cell.variation
                  : "transparent",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "24px",
            }}
          >
            {variation !== Variations.Colors ? cell.variation : ""}
          </div>
        ))
      )
    );

    setClickCount(0);
    setStartTime(Math.floor(performance.now()));
  };

  const resetGrid = () => {
    setCurrentVariationIndex(0);
    setCurrentVariation(variations.current[0]);
    populateGrid();
  };

  const handleCellClick = (rowIndex, colIndex) => {
    const cell = gridContent[rowIndex][colIndex].props.style;
    const cellContent =
      variation === Variations.Colors
        ? cell.backgroundColor
        : gridContent[rowIndex][colIndex].props.children;
    if (cellContent === currentVariation && !cell.isPressed) {
      if (startTime === null) {
        setStartTime(Math.floor(performance.now()));
      }
      setClickCount((prevCount) => prevCount + 1);
      variationCount.current[currentVariation] -= 1;
      gridRef.current.updateCell(
        rowIndex,
        colIndex,
        <div
          style={{
            backgroundColor: Color.LightGray,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
          }}
        >
          {variation !== Variations.Colors ? "" : ""}
        </div>
      );
      setGridContent((prevGridContent) => {
        const newGridContent = prevGridContent.slice();
        newGridContent[rowIndex][colIndex] = (
          <div
            style={{
              backgroundColor: Color.LightGray,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "24px",
            }}
          >
            {variation !== Variations.Colors ? "" : ""}
          </div>
        );
        return newGridContent;
      });
    }
  };

  const gridComponent = (
    <Grid
      ref={gridRef}
      gridContent={gridContent}
      onCellClick={handleCellClick}
    />
  );

  return {
    gridComponent,
    showStartButton,
    handleStartGame,
    handleCellClick,
    currentVariation,
    timer,
  };
};

class Color {
  static Blue = "#1A4E8A";
  static Orange = "#F2A900";
  static Red = "#D50032";
  static Teal = "#00A499";
  static LightBlue = "#6CACE4";
  static Black = "#1D1D1D";
  static LightGray = "#EFEFEF";

  static getRandomColor() {
    const excludedColors = ["Black", "LightGray"];
    const colorKeys = Object.keys(Color)
      .filter((key) => !excludedColors.includes(key))
      .map((key) => Color[key]);
    const randomColor = colorKeys[Math.floor(Math.random() * colorKeys.length)];
    return randomColor;
  }

  static getAllColors() {
    const excludedColors = ["Black", "LightGray"];
    return Object.keys(Color)
      .filter((key) => !excludedColors.includes(key))
      .map((key) => Color[key]);
  }
}

export default Color;

const presets = [
  { level: 1, fieldCount: 2, highlightDuration: 1.5 },
  { level: 2, fieldCount: 3, highlightDuration: 1.4 },
  { level: 3, fieldCount: 3, highlightDuration: 1.3 },
  { level: 4, fieldCount: 4, highlightDuration: 1.2 },
  { level: 5, fieldCount: 4, highlightDuration: 1.1 },
  { level: 6, fieldCount: 5, highlightDuration: 1.0 },
  { level: 7, fieldCount: 5, highlightDuration: 0.9 },
  { level: 8, fieldCount: 6, highlightDuration: 0.8 },
  { level: 9, fieldCount: 6, highlightDuration: 0.7 },
  { level: 10, fieldCount: 7, highlightDuration: 0.6 },
  { level: 11, fieldCount: 7, highlightDuration: 0.5 },
  { level: 12, fieldCount: 8, highlightDuration: 0.4 },
  { level: 13, fieldCount: 8, highlightDuration: 0.3 },
  { level: 14, fieldCount: 9, highlightDuration: 0.2 },
  { level: 15, fieldCount: 10, highlightDuration: 0.2 },
];

export default presets;

import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import i18n from "@/core/plugins/i18n";
import { defaultPrivacy, User } from "@/store/interfaces/User";
import store from "@/store";

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors: Array<string> = [];
  redirectTo = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current redirect path
   * @returns User
   */
  get lastRedirectTo(): string {
    return this.redirectTo;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return {
      ...this.user,
      ...{
        privacy: { ...defaultPrivacy, ...this.user.privacy },
        role: { ...this.user.role },
      },
    };
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get last authentification error
   * @returns array
   */
  get getLastError(): string {
    return this.errors.length > 0 ? this.errors[this.errors.length - 1] : "";
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (typeof error === "object" && error !== null) {
      const { i18string, i18params } = error;
      error = i18n.global.t(i18string, i18params);
    }
    if (i18n.global.te(error)) {
      error = i18n.global.t(error);
    }
    this.errors.push(error);
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];
    JwtService.saveToken(data.token);
    localStorage.setItem("userId", data.user.id);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_REDIRECT_TO](link) {
    this.redirectTo = link;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_LOADED_USER, data.user);
          resolve(data.user);
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(Mutations.SET_ERROR, "auth.invalidCredentials");
          }
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT](onlyJwt) {
    const jwtOnly = onlyJwt !== undefined ? onlyJwt : false;
    return new Promise<void>((resolve, reject) => {
      if (jwtOnly) {
        this.context.commit(Mutations.PURGE_AUTH);
        resolve();
      } else {
        ApiService.get("logout")
          .then(({ data }) => {
            if (data.success) {
              this.context.commit(Mutations.PURGE_AUTH);
              store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-enabled");
              store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-fixed");
              resolve();
            } else {
              this.context.commit(Mutations.SET_ERROR, data.message);
              reject();
            }
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.message);
            reject();
          });
      }
    });
  }

  @Action
  [Actions.REGISTER](values) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        values.auth ? "registration" : "user/create",
        values.credentials
      )
        .then(({ data }) => {
          if (data["success"]) {
            if (values.auth) {
              this.context.commit(Mutations.SET_AUTH, data);
            }
            resolve(data.user);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              data.errors?.email["0"].i18
            );
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
          // this.context.commit(
          //   Mutations.SET_ERROR,
          //   response.data.errors?.email["0"].i18
          // );
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot-password", payload)
        .then(({ data }) => {
          resolve(data.i18);
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "auth.passwordReset.serverError"
            );
          }
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("verify")
          .then(({ data }) => {
            if (data.success) {
              this.context.commit(Mutations.SET_AUTH, data);
              if (window.top && window.self !== window.top) {
                window.top.location.href = window.location.href;
              }
              resolve(data.messageCounts);
            } else {
              this.context.commit(Mutations.SET_ERROR, data.message);
              this.context.commit(Mutations.PURGE_AUTH);
              reject(new Error(data.message));
            }
          })
          .catch((error) => {
            this.context.commit(Mutations.PURGE_AUTH);
            reject(error);
          });
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
        reject();
      }
    });
  }
  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("change-password", payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(
              Mutations.SET_REDIRECT_TO,
              response.data.redirect ?? ""
            );
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "auth.passwordChange.serverError"
            );
          }
          reject(response?.data);
        });
    });
  }
}

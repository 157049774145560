import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { ExerciseExecution } from "@/store/interfaces/ExerciseExecution";

@Module
export default class ExerciseExecutionModule extends VuexModule {
  redirectTo = "";
  loadedExerciseExecution: ExerciseExecution = {} as ExerciseExecution;
  ExerciseExecutions: ExerciseExecution[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedExerciseExecution(): ExerciseExecution {
    return this.loadedExerciseExecution.id
      ? this.loadedExerciseExecution
      : ({
          ...this.loadedExerciseExecution,
          ...{
            resources: this.loadedExerciseExecution.resources
              ? this.loadedExerciseExecution.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    exerciseExecution_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as ExerciseExecution);
  }

  /**
   * Retreive current loaded exerciseExecutions
   */
  get getExerciseExecutions(): ExerciseExecution[] {
    return this.ExerciseExecutions;
  }

  /**
   * Get last pagination setup
   */
  get getExerciseExecutionsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getExerciseExecutionsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_EXERCISE_EXECUTION](
    ExerciseExecution: ExerciseExecution
  ) {
    this.loadedExerciseExecution = ExerciseExecution;
  }

  @Mutation
  [Mutations.SET_EXERCISE_EXECUTIONS](data) {
    this.ExerciseExecutions = data.data;
  }

  @Mutation
  [Mutations.SET_EXERCISE_EXECUTIONS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_EXERCISE_EXECUTIONS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_EXERCISE_EXECUTIONS](payload) {
    ApiService.setHeader();
    return new Promise<ExerciseExecution[]>((resolve, reject) => {
      ApiService.post("exercise-executions", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_EXERCISE_EXECUTIONS,
              data["exercise_executions"]
            );
            this.context.commit(
              Mutations.SET_EXERCISE_EXECUTIONS_PAGINATION,
              data["exercise_executions"].pagination
            );
            resolve(data["exercise_executions"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseExecutions.errors.cantGetExerciseExecutions"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_EXERCISE_EXECUTION_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<ExerciseExecution>((resolve) => {
        const ExerciseExecution = {} as ExerciseExecution;
        this.context.commit(
          Mutations.SET_LOADED_EXERCISE_EXECUTION,
          ExerciseExecution
        );
        resolve(ExerciseExecution);
      });
    }
    return new Promise<ExerciseExecution>((resolve, reject) => {
      ApiService.get(`exercise-execution/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_EXECUTION,
              data["exercise_execution"]
            );
            resolve(data["exercise_execution"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseExecutions.errors.commonGET_EXERCISE_EXECUTION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EXERCISE_EXECUTION_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<ExerciseExecution>((resolve, reject) => {
      ApiService.delete(`exercise-execution/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_EXECUTION,
              data["exercise_execution"]
            );
            resolve(data["exercise_execution"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseExecutions.errors.commonDELETE_EXERCISE_EXECUTION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EXERCISE_EXECUTION](ExerciseExecution: ExerciseExecution) {
    const payload = JSON.parse(JSON.stringify(ExerciseExecution));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`exercise-execution/${ExerciseExecution.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_EXECUTION,
              data["exercise_execution"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: ExerciseExecution.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseExecutions.form.exerciseExecutionChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EXERCISE_EXECUTION](ExerciseExecution: ExerciseExecution) {
    const payload = JSON.parse(JSON.stringify(ExerciseExecution));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`exercise-execution`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_EXERCISE_EXECUTION,
              data["exercise_execution"]
            );
            resolve(data["exercise_execution"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: ExerciseExecution.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "exerciseExecutions.form.exerciseExecutionAddFail"
          );
          reject();
        });
    });
  }
}

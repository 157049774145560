import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type { WcProduct } from "@/store/interfaces/Woocommerce";

@Module
export default class WoocommerceModule extends VuexModule {
  loadedProduct: WcProduct = {} as WcProduct;
  products: WcProduct[] = [];

  get getLoadedWcProduct(): WcProduct {
    return { ...this.loadedProduct } as WcProduct;
  }

  get getWcProducts(): WcProduct[] {
    return this.products;
  }

  @Mutation
  [Mutations.SET_LOADED_WC_PRODUCT](product: WcProduct) {
    this.loadedProduct = product;
  }

  @Mutation
  [Mutations.SET_LOADED_WC_PRODUCTS](data) {
    this.products = data;
  }

  @Action
  [Actions.LOAD_WC_PRODUCTS]() {
    ApiService.setHeader();
    return new Promise<WcProduct[]>((resolve, reject) => {
      ApiService.get("woocommerce/products")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_WC_PRODUCTS,
              data["products"]
            );
            resolve(data["products"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "woocommerce.errors.cantGetProducts"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_WC_PRODUCT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<WcProduct>((resolve) => {
        const WcProduct = {} as WcProduct;
        this.context.commit(Mutations.SET_LOADED_WC_PRODUCT, WcProduct);
        resolve(WcProduct);
      });
    }
    return new Promise<WcProduct>((resolve, reject) => {
      ApiService.get(`woocommerce/product/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_WC_PRODUCT,
              data["product"]
            );
            resolve(data["product"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "woocommerce.errors.commonGET_PRODUCT_BY_ID"
          );
          reject();
        });
    });
  }
}

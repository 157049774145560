import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type {
  CompanyBankAccount,
  CompanyBankAccountSyncState,
} from "@/store/interfaces/CompanyBankAccount";
import { PaginationResponse, SortingPair } from "@/store/interfaces/Datatables";
import ApiService from "@/core/services/ApiService";

@Module
export default class CompanyBankAccountModule extends VuexModule {
  redirectTo = "";
  loadedCompanyBankAccount: CompanyBankAccount = {} as CompanyBankAccount;
  CompanyBankAccounts: CompanyBankAccount[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedCompanyBankAccount(): CompanyBankAccount {
    return { ...this.loadedCompanyBankAccount } as CompanyBankAccount;
  }

  /**
   * Retreive current loaded companyBankAccounts
   */
  get getCompanyBankAccounts(): CompanyBankAccount[] {
    return this.CompanyBankAccounts;
  }

  /**
   * Get last pagination setup
   */
  get getCompanyBankAccountsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getCompanyBankAccountsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT](
    CompanyBankAccount: CompanyBankAccount
  ) {
    this.loadedCompanyBankAccount = CompanyBankAccount;
  }

  @Mutation
  [Mutations.SET_COMPANY_BANK_ACCOUNTS](data) {
    this.CompanyBankAccounts = data.data;
  }

  @Mutation
  [Mutations.SET_COMPANY_BANK_ACCOUNTS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_COMPANY_BANK_ACCOUNTS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_COMPANY_BANK_ACCOUNTS](payload) {
    ApiService.setHeader();
    return new Promise<CompanyBankAccount[]>((resolve, reject) => {
      ApiService.post("companyBankAccounts", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_COMPANY_BANK_ACCOUNTS,
              data["companyBankAccounts"]
            );
            this.context.commit(
              Mutations.SET_COMPANY_BANK_ACCOUNTS_PAGINATION,
              data["companyBankAccounts"].pagination
            );
            resolve(data["companyBankAccounts"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.errors.cantGetCompanyBankAccounts"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_COMPANY_BANK_ACCOUNT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<CompanyBankAccount>((resolve) => {
        const CompanyBankAccount = {} as CompanyBankAccount;
        this.context.commit(
          Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT,
          CompanyBankAccount
        );
        resolve(CompanyBankAccount);
      });
    }
    return new Promise<CompanyBankAccount>((resolve, reject) => {
      ApiService.get(`companyBankAccount/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT,
              data["companyBankAccount"]
            );
            resolve(data["companyBankAccount"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.errors.commonGET_COMPANY_BANK_ACCOUNT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COMPANY_BANK_ACCOUNT_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<CompanyBankAccount>((resolve, reject) => {
      ApiService.delete(`companyBankAccount/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT,
              data["companyBankAccount"]
            );
            resolve(data["companyBankAccount"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.errors.commonDELETE_COMPANY_BANK_ACCOUNT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPANY_BANK_ACCOUNT](
    CompanyBankAccount: CompanyBankAccount
  ) {
    const payload = JSON.parse(JSON.stringify(CompanyBankAccount));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`companyBankAccount/${CompanyBankAccount.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT,
              data["companyBankAccount"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: CompanyBankAccount.id,
              account: CompanyBankAccount.account,
              currency_id: CompanyBankAccount.currency_id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.form.companyBankAccountChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COMPANY_BANK_ACCOUNT](
    CompanyBankAccount: CompanyBankAccount
  ) {
    const payload = JSON.parse(JSON.stringify(CompanyBankAccount));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`companyBankAccount`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_COMPANY_BANK_ACCOUNT,
              data["companyBankAccount"]
            );
            resolve(data["companyBankAccount"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: CompanyBankAccount.id,
              account: CompanyBankAccount.account,
              currency_id: CompanyBankAccount.currency_id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.form.companyBankAccountAddFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COMPANY_BANK_ACCOUNT_SYNC_STATE](id: number) {
    ApiService.setHeader();
    return new Promise<CompanyBankAccountSyncState>((resolve, reject) => {
      ApiService.get(`companyBankAccount/get-sync-state/${id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["syncState"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.errors.commonGET_COMPANY_BANK_ACCOUNT_SYNC_STATE"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.RUN_COMPANY_BANK_ACCOUNT_SYNC](id: number) {
    ApiService.setHeader();
    return new Promise<CompanyBankAccountSyncState>((resolve, reject) => {
      ApiService.get(`companyBankAccount/run-sync/${id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["syncState"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companyBankAccounts.errors.commonRUN_COMPANY_BANK_ACCOUNT_SYNC"
          );
          reject();
        });
    });
  }
}

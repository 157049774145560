import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { TemplatePeriodExercise } from "@/store/interfaces/TrainingTemplate";

@Module
export default class TemplatePeriodExerciseModule extends VuexModule {
  redirectTo = "";
  loadedTemplatePeriodExercise: TemplatePeriodExercise =
    {} as TemplatePeriodExercise;
  TemplatePeriodExercises: TemplatePeriodExercise[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedTemplatePeriodExercise(): TemplatePeriodExercise {
    return this.loadedTemplatePeriodExercise?.id
      ? this.loadedTemplatePeriodExercise
      : ({
          ...this.loadedTemplatePeriodExercise,
          ...{
            is_template: false,
          },
        } as TemplatePeriodExercise);
  }

  /**
   * Retreive current loaded trainingTemplates
   */
  get getTemplatePeriodExercises(): TemplatePeriodExercise[] {
    return this.TemplatePeriodExercises;
  }

  /**
   * Get last pagination setup
   */
  get getTemplatePeriodExercisesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getTemplatePeriodExercisesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE](
    templatePeriodExercise: TemplatePeriodExercise
  ) {
    this.loadedTemplatePeriodExercise = templatePeriodExercise;
  }

  @Mutation
  [Mutations.SET_TEMPLATE_PERIOD_EXERCISES](data) {
    this.TemplatePeriodExercises = data.data;
  }

  @Mutation
  [Mutations.SET_TEMPLATE_PERIOD_EXERCISES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_TEMPLATE_PERIOD_EXERCISES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_TEMPLATE_PERIOD_EXERCISES](payload) {
    ApiService.setHeader();
    return new Promise<TemplatePeriodExercise[]>((resolve, reject) => {
      ApiService.post("template-period-exercises", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_TEMPLATE_PERIOD_EXERCISES,
              data["template-period-exercises"]
            );
            this.context.commit(
              Mutations.SET_TEMPLATE_PERIOD_EXERCISES_PAGINATION,
              data["template-period-exercises"].pagination
            );
            resolve(data["template-period-exercises"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.exercises.errors.cantGetTemplatePeriodExercises"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TEMPLATE_PERIOD_EXERCISE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<TemplatePeriodExercise>((resolve) => {
        const templatePeriodExercise = {} as TemplatePeriodExercise;
        this.context.commit(
          Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE,
          templatePeriodExercise
        );
        resolve(templatePeriodExercise);
      });
    }
    return new Promise<TemplatePeriodExercise>((resolve, reject) => {
      ApiService.get(`template-period-exercise/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE,
              data["template-period-exercise"]
            );
            resolve(data["template-period-exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.exercises.errors.commonGET_TEMPLATE_PERIOD_EXERCISE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TEMPLATE_PERIOD_EXERCISE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TemplatePeriodExercise>((resolve, reject) => {
      ApiService.delete(`template-period-exercise/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE,
              data["template-period-exercise"]
            );
            resolve(data["template-period-exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.exercises.errors.commonDELETE_TEMPLATE_PERIOD_EXERCISE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TEMPLATE_PERIOD_EXERCISE](
    TemplatePeriodExercise: TemplatePeriodExercise
  ) {
    const payload = JSON.parse(JSON.stringify(TemplatePeriodExercise));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `template-period-exercise/${TemplatePeriodExercise.id}`,
        payload
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE,
              data["template-period-exercise"]
            );
            resolve();
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TemplatePeriodExercise.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.exercises.form.templatePeriodExerciseChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TEMPLATE_PERIOD_EXERCISE](
    TemplatePeriodExercise: TemplatePeriodExercise
  ) {
    const payload = JSON.parse(JSON.stringify(TemplatePeriodExercise));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`template-period-exercise`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TEMPLATE_PERIOD_EXERCISE,
              data["template-period-exercise"]
            );
            resolve(data["template-period-exercise"]);
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TemplatePeriodExercise.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.exercises.form.templatePeriodExerciseAddFail"
          );
          reject();
        });
    });
  }
}

import React, { useState, useEffect } from "react";

// Klasický grid test

const generateUniqueCharacters = (amount) => {
  return Array.from({ length: amount }, (_, i) => String.fromCharCode(65 + i));
};

const populateGridWithCharacters = (size, characters) => {
  const grid = Array(size * size).fill(" ");
  characters.forEach((char) => {
    let pos;
    do {
      pos = Math.floor(Math.random() * size * size);
    } while (grid[pos] !== " ");
    grid[pos] = char;
  });
  return grid;
};

const RememberTheGridMultipleGame = ({
  settings: { size, amountOfCharacters },
  onGameEnd,
}) => {
  const [grid, setGrid] = useState([]);
  const [visible, setVisible] = useState(false);
  const [charactersToFind, setCharactersToFind] = useState([]);
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [currentRoundAnswers, setCurrentRoundAnswers] = useState([]);
  const [showStartButton, setShowStartButton] = useState(true);
  const [autoNextRound, setAutoNextRound] = useState(false);
  const [memorizingPhase, setMemorizingPhase] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [guessTimer, setGuessTimer] = useState(5);
  const [guessStartTime, setGuessStartTime] = useState(null);

  let startTotalTime = 3;
  const [timer, setTimer] = useState(startTotalTime);
  const [currentRoundStartTime, setCurrentRoundStartTime] =
    useState(startTotalTime);
  const minimumTime = 1;
  const decrement = 0.5;

  const gameLength = 30;
  const [remainingGameTime, setRemainingGameTime] = useState(gameLength);

  useEffect(() => {
    if (remainingGameTime === 0 && guessTimer === 0) {
      onGameEnd({
        games: [
          {
            id: "RememberTheGridMultipleGame",
            score,
            answers,
          },
        ],
      });
    } else if (autoNextRound) {
      const timeoutId = setTimeout(() => {
        if (!showStartButton) {
          startGame();
        }
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [answers, onGameEnd, score, autoNextRound, showStartButton]);

  useEffect(() => {
    const gameTimer = setTimeout(() => {
      if (!showStartButton && remainingGameTime > 0) {
        setRemainingGameTime(remainingGameTime - 1);
      }
    }, 1000);
    return () => clearTimeout(gameTimer);
  }, [remainingGameTime, showStartButton]);

  useEffect(() => {
    let interval;
    if (guessTimer > 0 && !visible && !memorizingPhase) {
      interval = setInterval(() => {
        setGuessTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (guessTimer === 0 && !visible && !memorizingPhase) {
      setVisible(true);
      setAnswers((prev) => [...prev, currentRoundAnswers]);
      setCurrentRoundAnswers([]);
      setAutoNextRound(true);
    }
    return () => clearInterval(interval);
  }, [guessTimer, visible, memorizingPhase]);

  const startGame = () => {
    setVisible(true);
    setMemorizingPhase(true);
    setShowStartButton(false);

    const characters = generateUniqueCharacters(amountOfCharacters);
    setCharactersToFind(characters);
    const newGrid = populateGridWithCharacters(size, characters);

    setGrid(newGrid);
    setGuesses(Array(size * size).fill(null));

    const newStartTime = Math.max(
      startTotalTime - answers.length * decrement,
      minimumTime
    );
    setTimer(newStartTime);
    setCurrentRoundStartTime(newStartTime);
    countdownTimer();
  };

  const countdownTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        const nextTimerValue = prev - decrement;
        if (nextTimerValue <= minimumTime) {
          clearInterval(countdown);
          endMemorizationPhase();
          return minimumTime;
        }
        return nextTimerValue;
      });
    }, 1000);
  };

  const endMemorizationPhase = () => {
    setVisible(false);
    setMemorizingPhase(false);
    setAutoNextRound(false);
    setGuessTimer(5);
    setGuessStartTime(Date.now());
  };

  const makeGuess = (index) => {
    if (!visible && guessTimer > 0) {
      const currentTime = Date.now();
      const clickTime = currentTime - guessStartTime;
      const charGuessed = grid[index];
      const isCorrect = charactersToFind.includes(charGuessed);

      setCurrentRoundAnswers((prev) => [
        ...prev,
        { correct: isCorrect, clickTime },
      ]);

      if (isCorrect) {
        setScore(score + 1);
        setCharactersToFind(
          charactersToFind.filter((char) => char !== charGuessed)
        );
      }

      const updatedGuesses = guesses.map((guess, idx) =>
        idx === index ? isCorrect : guess
      );
      setGuesses(updatedGuesses);

      if (charactersToFind.length === 1 && isCorrect) {
        setGuessTimer(0);
      }
    }
  };

  const timeBarWidth = (timer / currentRoundStartTime) * 100;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <GameHeader score={score} gameTimer={remainingGameTime} />
      <GameGrid
        grid={grid}
        guesses={guesses}
        visible={visible}
        size={size}
        makeGuess={makeGuess}
      />
      <GameFooter
        startGame={startGame}
        showStartButton={showStartButton}
        charactersToFind={charactersToFind}
        visible={visible}
        memorizingPhase={memorizingPhase}
        timeBarWidth={timeBarWidth}
      />
    </div>
  );
};

const GameHeader = ({ score, gameTimer }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      marginBottom: "24px",
    }}
  >
    <div>Game timer: {gameTimer}</div>
    <div>Score: {score}</div>
  </div>
);

const GameGrid = ({ grid, guesses, visible, size, makeGuess }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      fontSize: "24px",
      marginBottom: "24px",
    }}
  >
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${size}, 1fr)`,
        gap: "10px",
      }}
    >
      {grid.map((char, index) => (
        <div
          key={index}
          onClick={() => makeGuess(index)}
          style={{
            width: "50px",
            height: "50px",
            backgroundColor:
              visible && char !== " "
                ? "#368f58"
                : guesses[index] === true
                ? "#6aa984"
                : guesses[index] === false
                ? "red"
                : "#EFEFEF",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "black",
          }}
        />
      ))}
    </div>
  </div>
);

// Footer with start button
const GameFooter = ({ startGame, showStartButton }) => (
  <div style={{ display: "flex", justifyContent: "center", fontSize: "24px" }}>
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {showStartButton && (
        <div style={{ padding: "10px" }}>
          Zobrazují se na stanovenou dobu obrazce (typicky 7 náhodných polí)
          značené jinou barvou pozadí buňky. Po době expozice se uživatel snaží
          odklikat předem označené buňky.
        </div>
      )}
      {showStartButton && (
        <button
          onClick={startGame}
          style={{
            padding: "10px",
            width: "120px",
            borderRadius: "5px",
            cursor: "pointer",
            backgroundColor: "#368f58",
            color: "white",
            fontWeight: "bold",
            textTransform: "uppercase",
            border: "none",
          }}
        >
          Start
        </button>
      )}
    </div>
    {/*memorizingPhase && visible && (
      <div style={{
        width: '120px',
        height: '20px',
        borderRadius: '5px',
        backgroundColor: '#EFEFEF',
        overflow: 'hidden'
      }}>
        <div style={{
          height: '100%',
          width: `${timeBarWidth}%`,
          backgroundColor: '#4CAF50',
          transition: 'width 1s linear'
        }}></div>
      </div>
      )*/}
  </div>
);

export default RememberTheGridMultipleGame;

export const trainingEventSk = {
  trainingEvents: {
    errors: {
      cantGetSchedule: "Nepodarilo sa získať rozvrh tréningu",
      cantGetTrainingEventOverride:
        "Nepodarilo sa zmeniť údaje pre tréninkový týždeň",
    },
    saveNote: "Uložiť poznámku",
    saveSleepDiary: "Uložiť spánkový denník",
    noteChanged: "Poznámka bola upravená",
    contentChanged: "Obsah bol upravený",
    sleepDiaryChanged: "Spánkový denník bol upravený",
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { BodyPart } from "@/store/interfaces/BodyPart";

@Module
export default class BodyPartModule extends VuexModule {
  redirectTo = "";
  loadedBodyPart: BodyPart = {} as BodyPart;
  BodyParts: BodyPart[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedBodyPart(): BodyPart {
    return this.loadedBodyPart.id
      ? this.loadedBodyPart
      : ({
          ...this.loadedBodyPart,
          ...{
            resources: this.loadedBodyPart.resources
              ? this.loadedBodyPart.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    bodyPart_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as BodyPart);
  }

  /**
   * Retreive current loaded bodyParts
   */
  get getBodyParts(): BodyPart[] {
    return this.BodyParts;
  }

  /**
   * Get last pagination setup
   */
  get getBodyPartsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getBodyPartsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_BODY_PART](BodyPart: BodyPart) {
    this.loadedBodyPart = BodyPart;
  }

  @Mutation
  [Mutations.SET_BODY_PARTS](data) {
    this.BodyParts = data.data;
  }

  @Mutation
  [Mutations.SET_BODY_PARTS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_BODY_PARTS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_BODY_PARTS](payload) {
    ApiService.setHeader();
    return new Promise<BodyPart[]>((resolve, reject) => {
      ApiService.post("body-parts", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_BODY_PARTS, data["body_parts"]);
            this.context.commit(
              Mutations.SET_BODY_PARTS_PAGINATION,
              data["body_parts"].pagination
            );
            resolve(data["body_parts"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "bodyParts.errors.cantGetBodyParts"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_BODY_PART_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<BodyPart>((resolve) => {
        const BodyPart = {} as BodyPart;
        this.context.commit(Mutations.SET_LOADED_BODY_PART, BodyPart);
        resolve(BodyPart);
      });
    }
    return new Promise<BodyPart>((resolve, reject) => {
      ApiService.get(`body-part/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_BODY_PART,
              data["body_part"]
            );
            resolve(data["body_part"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "bodyParts.errors.commonGET_BODY_PART_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_BODY_PART_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<BodyPart>((resolve, reject) => {
      ApiService.delete(`body-part/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_BODY_PART,
              data["body_part"]
            );
            resolve(data["body_part"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "bodyParts.errors.commonDELETE_BODY_PART_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BODY_PART](BodyPart: BodyPart) {
    const payload = JSON.parse(JSON.stringify(BodyPart));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`body-part/${BodyPart.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_BODY_PART,
              data["body_part"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: BodyPart.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "bodyParts.form.bodyPartChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BODY_PART](BodyPart: BodyPart) {
    const payload = JSON.parse(JSON.stringify(BodyPart));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`body-part`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_BODY_PART,
              data["body_part"]
            );
            resolve(data["body_part"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: BodyPart.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "bodyParts.form.bodyPartAddFail"
          );
          reject();
        });
    });
  }
}

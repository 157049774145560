export const diagnosticEn = {
  diagnostics: {
    toolTips: {
      edit: "Edit diagnostic {diagnostic}",
      delete: "Delete diagnostic {diagnostic}",
    },
    editTitle: "Edit diagnostic",
    newTitle: "New diagnostic",
    addDiagnostic: "Add diagnostic",
    errors: {
      notFound: "Diagnostic {id} not found",
      notYourRole: "Cant access data for diagnostic {id}",
      nameExists: "Diagnostic named `{name}` already exists",
      commonGET_DIAGNOSTIC_BY_ID: "Error retrieving diagnostic data",
      commonDELETE_DIAGNOSTIC_BY_ID: "Error deleting diagnostic",
      commonDELETE_DIAGNOSTICS: "Error deleting diagnostics",
      cantGetDiagnostics: "Failed to fetch diagnostics data",
    },
    form: {
      name: "Name",
      active: "Active",
      diagnosticChanged: "Diagnostic was stored",
      diagnosticChangedFail: "Failed to store diagnostic",
      diagnosticAdded: "New diagnostic was created",
      diagnosticAddFail: "Failed to add new diagnostic",
    },
    table: {
      header: {
        name: "Name",
        active: "Active",
        actions: "Actions",
      },
      deleteDiagnosticQuestion:
        "Do you really want to delete  diagnostic <strong>{diagnostic}</strong>?",
      searchDiagnostics: "Search diagnostics",
      diagnosticDeleted: "Diagnostic {diagnostic} was deleted",
      diagnosticsDeleted: "Diagnostics were deleted",
      noRecords: "No diagnostics found",
    },
  },
  diagnostic: {
    common: {
      yes: "Yes",
      no: "No",
      value: "Value",
      pickADay: "Record day",
      fileName: "Name",
    },
    history: {
      table: {
        delete: "Delete value {value}",
        deletePrompt: "Do you really want to delete value {value} ?",
        header: {
          date: "Date",
          value: "Value",
          actions: "Actions",
        },
      },
    },
    import: {
      diagnosticImportBatchSuccess: "Data imported successfully",
    },
    export: {
      diagnosticExportSuccess: "Data exported successfully",
    },
    toolTips: {
      s2board: { detail: "Display partial results" },
      "vision-energy": { detail: "Display partial results" },
      "in-office": { detail: "Display partial results" },
    },
    valueNotSet: "This value was not set yet",
    addHistoricValueFor:
      "<span class='small'>New record for value</span> {name}",
    listHistoricValueFor: "<span class='small'>Records for value</span> {name}",
    title: "Diagnostic",
    note: {
      title: "Note",
    },
    anamnesis: {
      title: "Anamnesis",
    },
    documents: {
      title: "Documents",
      values: {
        anamnesis_docs: {
          title: "Documents",
        },
      },
    },
    dominance: {
      title: "Dominance",
      noData: "No data available",
      feelTheLayout: "Choose the layout by feel",
      common: {
        left: "L",
        right: "R",
        yes: "Yes",
        no: "No",
        leftFull: "Left",
        rightFull: "Right",
      },
      help:
        ' <h5 class="mt-10">Hint:</h5>\n' +
        '            <h6 class="mt-5">Eye</h6>\n' +
        "            <div>\n" +
        "              Point your index finger at the object in front of you. Then close the one\n" +
        "              and the other eye. Note which eye you have left open while \n" +
        "              finger pointing at the chosen object.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Hand</h6>\n' +
        "            <div>\n" +
        "              Make a note of the hand you are more skilled at delicate work.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Leg</h6>\n' +
        "            <div>\n" +
        "              Record the foot you would kick the ball with first.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Rhythm</h6>\n' +
        "            <div>Can you hear the rhythm in the music?</div>",
      values: {
        dominance_eye: {
          title: "Eye",
        },
        dominance_hand: {
          title: "Hand",
        },
        dominance_leg: {
          title: "Leg",
        },
        dominance_ear: {
          title: "Rhythm",
        },
      },
    },
    s2board: {
      newMeasurement: "New measurement",
      title: "SensesBoard",
      valueDetail: {
        title: "Results of particular tasks",
      },
      resultDetail: {
        title: "Measurement detail",
      },
      resultDescription:
        "<strong>Sight speed</strong><br />\n" +
        "                Eye movement speed + cognitive skill<br />\n" +
        '                <i class="text-info"\n' +
        "                  >How FAST the eye can acquire information.</i\n" +
        "                ><br /><br />\n" +
        "                <strong>Sight precision</strong><br />\n" +
        "                Precision of eye movement muscles + cognitive skill.<br />\n" +
        '                <i class="text-info"\n' +
        "                  >How PRECISE can the eye acquire information.</i\n" +
        "                ><br /><br />\n" +
        "                <strong>Neurovision</strong><br />Total vision ability and\n" +
        "                cognitive skills to obtain information from the environment",
      noData: "No data available",
      measurementStored: "New measurement stored",
      measurementStoredFailed: "Failed to store new measurement",
      yourResult: "Your result",
      task: "Task",
      tasks: {
        "focus-far-close": "Focussing far-close",
        "focus-side-top": "Focussing side-top",
        "focus-side-bottom": "Focussing side-bottom",
        "lookup-from-one": "Searching numbers from one",
        "lookup-random": "Searching random numbers",
      },
      common: {
        left: "L",
        right: "R",
      },
      values: {
        s2board_enabled: {
          title: "Enabled",
        },
        s2board_measurements: {
          title: "Measurements",
        },
      },
    },
    "vision-energy": {
      newMeasurement: "New measurement",
      title: "Distance diagnostic",
      valueDetail: {
        title: "Results of particular tasks",
      },
      noData: "No data available",
      measurementStored: "New measurement stored",
      measurementStoredFailed: "Failed to store new measurement",
      yourResult: "Your result",
      task: "Task",
      words: "words",
      seconds: "sec",
      enterReadWords: "Enter nr. of read words",
      tasks: {
        task1: "Refocus both eyes distance - close",
        task2: "Refocus right eye distance - near",
        task3: "Refocus left eye distance - near",
        task4: "Flipper right eye",
        task5: "Flipper left eye",
      },
      common: {
        left: "L",
        right: "R",
        leftFull: "Left",
        rightFull: "Right",
      },
      values: {
        vision_energy_enabled: {
          title: "Enabled",
        },
        vision_energy_measurements: {
          title: "Measurements",
        },
      },
    },
    "potentials-legend": {
      sportLevel: "sport level",
      commonLife: "for common life",
      workOn: "something to work on",
      healthIssues: "functional issues",
      worldClass: "World Class",
      europeanClass: "European Class",
      noteOne: "The boundaries of trained and untrained individuals.",
      noteTwo: "It can cause problems in everyday life.",
    },
    "in-office": {
      newMeasurement: "New measurement",
      title: "Examination of oculomotor muscle functionality",
      valueDetail: {
        title: "Results of particular tasks",
      },
      resultDetail: {
        title: "Measurement detail",
      },
      resultDescription:
        "<strong>Eye muscles</strong><br />\n" +
        "                The ability of the eye to hold the gaze in one place for an extended period of time and retrieve information.\n" +
        "                <br /><br />\n" +
        "<strong>Flexibility</strong><br />\n" +
        "                The ability of the eye muscles to adapt to changes in viewing distance.\n" +
        "                <br /><br />\n" +
        "                <strong>Right eye</strong><br />\n" +
        "                Muscular velocity of the right eye.<br />\n" +
        "                 <br />\n" +
        "                <strong>Left eye</strong><br />Muscular velocity of the left eye.",
      noData: "No data available",
      measurementStored: "New measurement stored",
      measurementStoredFailed: "Failed to store new measurement",
      yourResult: "Your result",
      task: "Task",
      words: "words",
      seconds: "sec",
      turns: "turns",
      enterValue: "Enter measured value",
      enterNrOfTurns: "Enter the nr of turns",
      tasks: {
        task1: "Eye muscles far",
        task2: "Eye muscles near",
        task3: "Flexibility",
        task4: "Right eye",
        task5: "Left eye",
      },
      common: {
        left: "L",
        right: "R",
      },
      values: {
        in_office_enabled: {
          title: "Enabled",
        },
        in_office_measurements: {
          title: "Measurements",
        },
      },
    },
    eye_flexibility: {
      newMeasurement: "New measurement",
      title: "Examination eye flexibility",
      valueDetail: {
        title: "Results of particular tasks",
      },
      resultDetail: {
        title: "Measurement detail",
      },
      resultDescription:
        "<strong>Flexibility</strong><br />\n" +
        "                The ability of the eye muscles to adapt to changes in viewing distance.\n" +
        "                <br /><br />\n" +
        "                <strong>Right eye</strong><br />\n" +
        "                Muscular velocity of the right eye.<br />\n" +
        "                 <br />\n" +
        "                <strong>Left eye</strong><br />Muscular velocity of the left eye.",
      noData: "No data available",
      measurementStored: "New measurement stored",
      measurementStoredFailed: "Failed to store new measurement",
      yourResult: "Your result",
      task: "Task",
      words: "words",
      seconds: "sec",
      turns: "turns",
      enterValue: "Enter measured value",
      enterNrOfTurns: "Enter the nr of turns",
      tasks: {
        task1: "Flexibility",
        task2: "Right eye",
        task3: "Left eye",
      },
      common: {
        left: "L",
        right: "R",
      },
      values: {
        eye_flexibility_enabled: {
          title: "Enabled",
        },
        eye_flexibility_measurements: {
          title: "Measurements",
        },
      },
    },
    eye_stability: {
      newMeasurement: "New measurement",
      title: "Examination eye stability",
      valueDetail: {
        title: "Results of particular tasks",
      },
      resultDetail: {
        title: "Measurement detail",
      },
      resultDescription:
        "<span>The ability of the eye to hold the gaze in one place for an extended period of time and retrieve information.</span>",
      noData: "No data available",
      measurementStored: "New measurement stored",
      measurementStoredFailed: "Failed to store new measurement",
      yourResult: "Your result",
      task: "Task",
      words: "words",
      seconds: "sec",
      turns: "turns",
      enterValue: "Enter measured value",
      enterNrOfTurns: "Enter the nr of turns",
      tasks: {
        task1: "Flexibility",
        task2: "Right eye",
        task3: "Left eye",
      },
      common: {
        left: "L",
        right: "R",
      },
      values: {
        eye_stability_enabled: {
          title: "Enabled",
        },
        eye_stability_measurements: {
          title: "Measurements",
        },
      },
    },
    neurovision: {
      noData: "No data available",
      title: "Neurovisual diagnostic",
      common: {},
      resultDescription:
        "<strong>Short term memory</strong><br />\n" +
        "                Retaining information for a short period of time for current processing.\n" +
        "                <br /><br />\n" +
        "                <strong>Field of view efficiency</strong><br />\n" +
        "                The ability to perceive and distinguish objects in a wide visual space.<br />\n",
      values: {
        neurovision_ShorTerm_memory: {
          title: "Short term memory",
        },
        neurovision_field_of_view_efficiency: {
          title: "Field of view efficiency",
        },
      },
    },
    coordination: {
      noData: "No data available",
      title: "Coordination",
      common: {},
      resultDescription:
        "                <strong>Hand eye</strong><br />\n" +
        "                The ability to synchronize visual perception with motor movements of the hands.<br />\n" +
        "                <strong>Go/no go</strong><br />\n" +
        "                Ability to make decisions under time pressure.<br />\n",
      values: {
        coordination_reaction_time: {
          title: "Reaction time",
        },
        coordination_hand_eye: {
          title: "Hand eye",
        },
        coordination_gono_go: {
          title: "Go/no go",
        },
      },
    },
    concentration: {
      noData: "No data available",
      title: "Concentration",
      common: {},
      resultDescription:
        "                <strong>Concentration</strong><br />\n" +
        "                Ability to maintain focus on a specific activity.<br />\n",
      values: {
        concentration_concentration: {
          title: "Concentration",
        },
      },
    },
    hearing: {
      noData: "No data available",
      title: "Hearing - preventive hearing test",
      common: {},
      values: {
        hearing_left_ear: {
          title: "Left ear",
        },
        hearing_right_ear: {
          title: "Right ear",
        },
      },
    },
    balance: {
      noData: "No data available",
      title: "Balance",
      common: {},
      groups: {
        middleEar: "",
        rightLeg: "Right leg",
        leftLeg: "Left leg",
        note: "",
      },
      values: {
        balance_middleEar_value: {
          title: "Middle ear",
        },
        balance_rightLeg_opened_eyes: {
          title: "Opened eyes",
        },
        balance_rightLeg_closed_eyes: {
          title: "Closed eyes",
        },
        balance_leftLeg_opened_eyes: {
          title: "Opened eyes",
        },
        balance_leftLeg_closed_eyes: {
          title: "Closed eyes",
        },
        balance_note_value: {
          title: "Note",
        },
      },
    },
    eyesight: {
      noData: "No data available",
      title: "Eyesight diagnostic",
      common: {},
      groups: {
        diopter: "Diopter",
        correction: "Correction",
        sharpness: "Sharpness",
        refocusing: "Refocusing",
        motility: "Motility",
        accommodation: "Accommodation",
        flexibility: "Flexibility",
        contrastSensitivity: "Contrast Sensitivity",
        muscles: "Muscles",
        other: "Other",
      },
      values: {
        eyesight_diopter_right_eye: {
          title: "Right eye",
        },
        eyesight_diopter_left_eye: {
          title: "Left eye",
        },
        eyesight_correction_glasses: {
          title: "Glasses",
        },

        eyesight_correction_glasses_reading: {
          title: "Reading glasses",
        },
        eyesight_correction_contact_lenses_soft: {
          title: "Soft contact lenses",
        },
        eyesight_correction_contact_lenses_hard: {
          title: "Hard contact lenses",
        },

        eyesight_sharpness_both_eyes: {
          title: "Both eyes",
        },
        eyesight_sharpness_right_eye: {
          title: "Right eye",
        },
        eyesight_sharpness_left_eye: {
          title: "Left eye",
        },
        eyesight_sharpness_depth: {
          title: "Sharpness depth",
        },

        eyesight_refocusing_both_eyes: {
          title: "Both eyes",
        },
        eyesight_refocusing_side: {
          title: "Side",
        },
        eyesight_refocusing_right_eye: {
          title: "Right eye",
        },
        eyesight_refocusing_left_eye: {
          title: "Left eye",
        },

        eyesight_motility_both_eyes: {
          title: "Both eyes",
        },
        eyesight_motility_right_eye: {
          title: "Right eye",
        },
        eyesight_motility_left_eye: {
          title: "Left eye",
        },

        eyesight_accommodation_both_eyes: {
          title: "Both eyes",
        },
        eyesight_accommodation_right_eye: {
          title: "Right eye",
        },
        eyesight_accommodation_left_eye: {
          title: "Left eye",
        },

        eyesight_flexibility_both_eyes: {
          title: "Both eyes",
        },

        eyesight_contrastSensitivity_both_eyes: {
          title: "Both eyes",
        },
        eyesight_contrastSensitivity_right_eye: {
          title: "Right eye",
        },
        eyesight_contrastSensitivity_left_eye: {
          title: "Left eye",
        },

        eyesight_muscles_far: {
          title: "Far",
        },
        eyesight_muscles_close: {
          title: "Close",
        },
        eyesight_other_system_understanding: {
          title: "System understanding",
        },
      },
    },
  },
};

export const diagnosticSk = {
  diagnostics: {
    toolTips: {
      edit: "Upraviť diagnostiku {diagnostic}",
      delete: "Odstrániť diagnostiku {diagnostic}",
    },
    editTitle: "Upraviť diagnostiku",
    newTitle: "Nová diagnostika",
    addDiagnostic: "Pridať diagnostiku",
    errors: {
      notFound: "Diagnostika s id {id} nebola nájdená",
      notYourRole: "Nemáte právo zobraziť diagnostiku s id {id}",
      nameExists: "Diagnostika s názvom `{name}` už existuje",
      commonGET_DIAGNOSTIC_BY_ID: "Chyba pri získavaní údajov o diagnostike",
      commonDELETE_DIAGNOSTIC_BY_ID: "Chyba pri odstraňovaní diagnostiky",
      commonDELETE_DIAGNOSTICS: "Nepodarilo sa odstrániť diagnostiky",
      cantGetDiagnostics: "Chyba pri získavaní údajov o diagnostikách",
    },
    form: {
      name: "Názov",
      active: "Aktívna",
      diagnosticChanged: "Diagnostika bola upravená",
      diagnosticChangedFail: "Nepodarilo sa upraviť diagnostiku",
      diagnosticAdded: "Nová diagnostika bola vytvorená",
      diagnosticAddFail: "Nepodarilo sa vytvoriť novú diagnostiku",
    },
    table: {
      header: {
        name: "Názov",
        active: "Aktívna",
        actions: "Akcie",
      },
      deleteDiagnosticQuestion:
        "Naozaj chcete odstrániť diagnostiku <strong>{diagnostic}</strong>?",
      searchDiagnostics: "Hľadať diagnostiku",
      diagnosticDeleted: "Diagnostika {diagnostic} bola odstránená",
      diagnosticsDeleted: "Diagnostiky boli odstránené",
      noRecords: "Neboli nájdené žiadne diagnostiky",
    },
  },

  diagnostic: {
    common: {
      yes: "Áno",
      no: "Nie",
      value: "Hodnota",
      pickADay: "Deň záznamu",
      fileName: "Názov",
    },
    history: {
      table: {
        delete: "Odstrániť hodnotu {value}",
        deletePrompt: "Naozaj chcete odstrániť hodnotu {value}?",
        header: {
          date: "Dátum",
          value: "Hodnota",
          actions: "Akcie",
        },
      },
    },
    import: {
      diagnosticImportBatchSuccess: "Údaje boli úspešne importované",
    },
    export: {
      diagnosticExportSuccess: "Údaje boli úspešne exportované",
    },
    toolTips: {
      s2board: { detail: "Zobraziť mezičasy" },
      "vision-energy": { detail: "Zobraziť výsledky úloh" },
      "in-office": { detail: "Zobraziť výsledky úloh" },
      eye_flexibility: { detail: "Zobraziť výsledky úloh" },
      eye_stability: { detail: "Zobraziť výsledky úloh" },
    },
    valueNotSet: "Táto hodnota ešte nie je nastavená",
    addHistoricValueFor:
      "<span class='small'>Nový záznam pre hodnotu</span> {name}",
    listHistoricValueFor:
      "<span class='small'>Záznamy pre hodnotu</span> {name}",
    title: "Diagnostika",
    note: {
      title: "Poznámka",
    },
    anamnesis: {
      title: "Anamnéza",
    },
    documents: {
      title: "Dokumenty",
      values: {
        anamnesis_docs: {
          title: "Dokumenty",
        },
      },
    },
    dominance: {
      noData: "Žiadne údaje na zobrazenie",
      title: "Dominancia",
      feelTheLayout: "Pocitovo zvoľte rozloženie",
      common: {
        left: "L",
        right: "P",
        yes: "Áno",
        no: "Nie",
        leftFull: "Ľavá",
        rightFull: "Pravá",
      },
      help:
        ' <h5 class="mt-10">Nápoveda:</h5>\n' +
        '            <h6 class="mt-5">Oko</h6>\n' +
        "            <div>\n" +
        "              Namierte ukazovákom na predmet priamo pred Vami. Potom zatvorte jedno\n" +
        "              a druhé oko. Zaznamenajte, pri ktorom otvorenom oku Vám zostal\n" +
        "              prst smerovať na zvolený predmet.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Ruka</h6>\n' +
        "            <div>\n" +
        "              Zaznamenajte ruku, ktorou máte šikovnejšiu na jemnú prácu.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Noha</h6>\n' +
        "            <div>\n" +
        "              Zaznamenajte nohu, ktorou by ste ako prvý kopli do balóna.\n" +
        "            </div>\n" +
        '            <h6 class="mt-5">Rytmus</h6>\n' +
        "            <div>Slyšíte rytmus v hudbe?</div>",
      values: {
        dominance_eye: {
          title: "Oko",
        },
        dominance_hand: {
          title: "Ruka",
        },
        dominance_leg: {
          title: "Noha",
        },
        dominance_ear: {
          title: "Rytmus",
        },
      },
    },
    s2board: {
      newMeasurement: "Nové meranie",
      valueDetail: {
        title: "Čas jednotlivých úloh",
      },
      resultDetail: {
        title: "Detail merania",
      },
      resultDescription:
        "<strong>Rýchlosť zraku</strong><br />\n" +
        "                Rýchlosť očných svalov + kognitívne schopnosti<br />\n" +
        "<br />\n" +
        "                <strong>Presnosť zraku</strong><br />\n" +
        "                Presnosť očných svalov + kognitívne schopnosti.<br />\n" +
        "<br />\n" +
        "                <strong>Neovízia</strong><br />Celková schopnosť zraku a\n" +
        "                kognitívnych schopností získavať informácie z okolia",
      noData: "Žiadne údaje na zobrazenie",
      measurementStored: "Nové meranie uložené",
      measurementStoredFailed: "Nepodarilo sa uložiť nové meranie",
      yourResult: "Váš výsledok",
      title: "SensesBoard",
      task: "Úloha",
      tasks: {
        "focus-far-close": "Zameranie ďaleko blízko",
        "focus-side-top": "Bokové zameranie zhora",
        "focus-side-bottom": "Bokové zameranie zdola",
        "lookup-from-one": "Hľadanie od 1",
        "lookup-random": "Hľadanie od náhodného čísla",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        s2board_enabled: {
          title: "Povolené",
        },
        s2board_measurements: {
          title: "Merania",
        },
      },
    },
    "vision-energy": {
      newMeasurement: "Nové meranie",
      valueDetail: {
        title: "Čas jednotlivých úloh",
      },
      noData: "Žiadne údaje na zobrazenie",
      measurementStored: "Nové meranie uložené",
      measurementStoredFailed: "Nepodarilo sa uložiť nové meranie",
      yourResult: "Váš výsledok",
      title: "Diagnostika na diaľku",
      task: "Úloha",
      words: "slov",
      seconds: "s",
      enterReadWords: "Zadajte počet prečítaných slov",
      tasks: {
        task1: "Preostrenie oboch očí vzdialenosť - blízko",
        task2: "Preostrenie pravého oka vzdialenosť - blízko",
        task3: "Preostrenie ľavého oka vzdialenosť - blízko",
        task4: "Flipr pravé oko",
        task5: "Flipr ľavé oko",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        vision_energy_enabled: {
          title: "Povolené",
        },
        vision_energy_measurements: {
          title: "Merania",
        },
      },
    },
    "potentials-legend": {
      sportLevel: "športová úroveň",
      commonLife: "pre bežný život",
      workOn: "je na čom pracovať",
      healthIssues: "funkční problémy",
      worldClass: "Svetová úroveň",
      europeanClass: "Európska úroveň",
      noteOne: "Hranica trénovaných a netrénovaných jedincov.",
      noteTwo: "Môže spôsobovať ťažkosti v bežnom živote.",
    },
    "in-office": {
      newMeasurement: "Nové meranie",
      valueDetail: {
        title: "Čas jednotlivých úloh",
      },
      resultDetail: {
        title: "Detail merania",
      },
      resultDescription:
        "                <strong>Očné svaly</strong><br />Schopnosť zraku udržať pohľad dlhšie na jednom mieste a získavať informácie.<br />\n" +
        "                <br />\n" +
        "<strong>Flexibilita</strong><br />\n" +
        "                Schopnosť očných svalov prispôsobovať sa zmene vzdialenosti pohľadu.<br/>\n" +
        "                <br />\n" +
        "                <strong>Pravé oko</strong><br />\n" +
        "                Svalová rýchlosť pravého oka.<br />\n" +
        "                <br />\n" +
        "                <strong>Ľavé oko</strong><br />Svalová rýchlosť ľavého oka.",

      noData: "Žiadne údaje na zobrazenie",
      measurementStored: "Nové meranie uložené",
      measurementStoredFailed: "Nepodarilo sa uložiť nové meranie",
      yourResult: "Váš výsledok",
      title: "Vyšetrenie funkčnosti očných svalov",
      task: "Úloha",
      words: "slov",
      seconds: "s",
      turns: "otočenie",
      enterValue: "Zadajte nameranú hodnotu",
      enterNrOfTurns: "Zadajte počet otočení",
      tasks: {
        task1: "Očné svaly do diaľky",
        task2: "Očné svaly do blízka",
        task3: "Flexibilita",
        task4: "Pravé oko",
        task5: "Ľavé oko",
      },
      common: {
        left: "L",
        leftFull: "Ľavá",
        right: "P",
        rightFull: "Pravá",
      },
      values: {
        in_office_enabled: {
          title: "Povolené",
        },
        in_office_measurements: {
          title: "Merania",
        },
      },
    },
    eye_flexibility: {
      newMeasurement: "Nové meranie",
      valueDetail: {
        title: "Čas jednotlivých úloh",
      },
      resultDetail: {
        title: "Detail merania",
      },
      resultDescription:
        "<strong>Flexibilita</strong><br />\n" +
        "                Schopnosť očných svalov prispôsobovať sa zmene vzdialenosti pohľadu.<br/>\n" +
        "                <br />\n" +
        "                <strong>Pravé oko</strong><br />\n" +
        "                Svalová rýchlosť pravého oka.<br />\n" +
        "                <br />\n" +
        "                <strong>Ľavé oko</strong><br />Svalová rýchlosť ľavého oka.",
      noData: "Žiadne údaje na zobrazenie",
      measurementStored: "Nové meranie uložené",
      measurementStoredFailed: "Nepodarilo sa uložiť nové meranie",
      yourResult: "Váš výsledok",
      title: "Meranie rýchlosti očných svalov",
      task: "Úloha",
      words: "slov",
      seconds: "s",
      turns: "otočenie",
      enterValue: "Zadajte nameranú hodnotu",
      enterNrOfTurns: "Zadajte počet otočení",
      tasks: {
        task1: "Flexibilita",
        task2: "Pravé oko",
        task3: "Ľavé oko",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        eye_flexibility_enabled: {
          title: "Povolené",
        },
        eye_flexibility_measurements: {
          title: "Merania",
        },
      },
    },
    eye_stability: {
      newMeasurement: "Nové meranie",
      valueDetail: {
        title: "Čas jednotlivých úloh",
      },
      resultDetail: {
        title: "Detail merania",
      },
      resultDescription:
        "<strong>Stabilita zraku</strong><br />\n" +
        "<span>Schopnosť zraku udržať pohľad dlhšie na jednom mieste a získavať informácie.</span>",
      noData: "Žiadne údaje na zobrazenie",
      measurementStored: "Nové meranie uložené",
      measurementStoredFailed: "Nepodarilo sa uložiť nové meranie",
      yourResult: "Váš výsledok",
      title: "Meranie stability zraku",
      task: "Úloha",
      words: "slov",
      seconds: "s",
      turns: "otočenie",
      enterValue: "Zadajte nameranú hodnotu",
      enterNrOfTurns: "Zadajte počet otočení",
      tasks: {
        task1: "Očné svaly do diaľky",
        task2: "Očné svaly do blízka",
      },
      common: {
        left: "L",
        right: "P",
      },
      values: {
        eye_stability_enabled: {
          title: "Povolené",
        },
        eye_stability_measurements: {
          title: "Merania",
        },
      },
    },
    neurovision: {
      noData: "Žiadne údaje na zobrazenie",
      title: "Neurovizuálna diagnostika",
      common: {},
      resultDescription:
        "<strong>Krátkodobá pamäť</strong><br />\n" +
        "                Uchovávanie informácií na krátku dobu pre aktuálne spracovanie.<br/>\n" +
        "                <br />\n" +
        "<strong>Efektivita zorného poľa</strong><br />\n" +
        "                Schopnosť vnímať a rozlišovať objekty v širom vizuálnom poli.<br/>\n" +
        "                <br />\n" +
        "<strong>Kontrast citlivosť</strong><br />\n" +
        "                Schopnosť rozpoznávať detaily na základe kontrastu.<br />\n" +
        "                <br />\n" +
        "<strong>Neurovizuálna koordinácia</strong><br />\n" +
        "                Schopnosť spolupracovať očné svaly pri pohyboch očí.",
      values: {
        neurovision_enabled: {
          title: "Povolené",
        },
        neurovision_measurements: {
          title: "Merania",
        },
      },
    },
    results: {
      history: {
        title: "História výsledkov",
        noRecords: "Žiadne údaje na zobrazenie",
        measurementDeleted: "Hodnota {value} bola odstránená",
        measurementDeletedFail: "Nepodarilo sa odstrániť hodnotu {value}",
      },
      title: "Výsledky diagnostiky",
      age: "Vek",
      sex: "Pohlavie",
      notes: "Poznámky",
      addNote: "Pridať poznámku",
      deleteNote: "Odstrániť poznámku",
      saveNote: "Uložiť poznámku",
      deleteNotePrompt: "Naozaj chcete odstrániť túto poznámku?",
      noteDeleted: "Poznámka bola odstránená",
      noteDeletedFail: "Nepodarilo sa odstrániť poznámku",
      noteAdded: "Poznámka bola pridaná",
      noteAddFail: "Nepodarilo sa pridať poznámku",
      addResultToDashboard: "Pridať výsledky na dashboard",
      resultAddedToDashboard: "Výsledky boli pridané na dashboard",
      resultAddToDashboardFail: "Nepodarilo sa pridať výsledky na dashboard",
      addResultToProject: "Pridať výsledky na projekt",
      resultAddedToProject: "Výsledky boli pridané na projekt",
      resultAddToProjectFail: "Nepodarilo sa pridať výsledky na projekt",
      pdfExportSuccess: "PDF úspešne exportované",
      pdfExportFail: "Nepodarilo sa exportovať PDF",
      addResultToCompare: "Pridať výsledky na porovnanie",
      resultAddedToCompare: "Výsledky boli pridané na porovnanie",
      resultAddToCompareFail: "Nepodarilo sa pridať výsledky na porovnanie",
      addToCompareSuccess: "Výsledky úspešne pridané na porovnanie",
      addToCompareFail: "Nepodarilo sa pridať výsledky na porovnanie",
      removeFromCompareSuccess: "Výsledky úspešne odstránené z porovnávania",
      removeFromCompareFail: "Nepodarilo sa odstrániť výsledky z porovnávania",
      chartTitle: "Výsledky diagnostiky",
      chartSubtitle:
        "Zobrazené hodnoty predstavujú priemerné hodnoty z doteraz uložených meraní.",
      measurement: "Meranie",
      measurements: {
        words: "slov",
        seconds: "s",
        turns: "otočenie",
      },
      values: {
        date: {
          title: "Dátum merania",
        },
        age: {
          title: "Vek",
        },
        sex: {
          title: "Pohlavie",
        },
        word_count: {
          title: "Počet slov",
        },
        words_per_minute: {
          title: "Slov za minútu",
        },
        seconds: {
          title: "Sekundy",
        },
        turns: {
          title: "Otočenie",
        },
      },
      noResults: "Neboli uložené žiadne výsledky",
    },
  },
};

export const dashboardCs = {
  dashboard: {
    title: "Nástěnka",
    noModules: "Nemáte přiřazeny žádné moduly nástěnky ke zobrazení",
    nrOfClients: "Počet klientů",
    errors: {
      cantGetDashboard: "Nepodařilo se stáhnout data pro nástěnku",
      cantGetDashboardModules:
        "Nepodařilo se stáhnout seznam modulů pro nástěnku",
    },
    form: {
      modules: "Povolené moduly",
    },
    widgets: {
      results: {
        title: "Výsledky",
        noRecords: "Momentálně nejsou k dispozici žádné výsledky.",
      },
      diagnostics: {
        title: "Diagnostika",
        noRecords: "Momentálně nejsou k dispozici žádné diagnostiky.",
      },
      games: {
        title: "Neurokognitivní trénink",
        noRecords: "Momentálně nejsou k dispozici žádné hry.",
        newGamesAvailable: "Jsou k dispozici nové hry",
        play: "Nová hra",
        last_gameplay: "Naposledy hráno",
        no_gameplay_record: "Tuto hru jste ještě nehráli",
      },
      training: {
        title: "Neurovizuální trenink",
        noRecords: "Momentálně není k dispozici žádný trénink.",
      },
      senaptec: {
        title: "Senaptec",
        measurements: "Výsledky měření",
        historyListTitle: "Historie měření",
      },
      senses_board: {
        title: "SensesBoard",
        measurements: "Výsledky měření",
        historyListTitle: "Historie měření",
      },
      "in-office": {
        title: "Vyšetření funkčnosti okohybných svalů",
        measurements: "Výsledky měření",
        historyListTitle: "Historie měření",
      },
      eye_flexibility: {
        title: "Flexibilita zraku",
        measurements: "Výsledky měření",
        historyListTitle: "Historie měření",
      },
      eye_stability: {
        title: "Stabilita zraku",
        measurements: "Výsledky měření",
        historyListTitle: "Historie měření",
      },
    },
  },
};

import store from "@/store";
import { User } from "@/store/interfaces/User";
import { Actions } from "@/store/enums/StoreEnums";

export const validateNotUserIdAndRoles = (to, from, next, minRole = 5) => {
  const user = store.getters["currentUser"] as User;
  if (user.id) {
    if (user.id.toString() !== to.params.id && user.role_id >= minRole) {
      next();
    } else {
      next("unauthorized");
    }
  } else {
    store.dispatch(Actions.VERIFY_AUTH).then(() => {
      const user = store.getters["currentUser"] as User;
      if (user.id.toString() == to.params.id || user.role_id >= minRole) {
        next();
      } else {
        next("unauthorized");
      }
    });
  }
};

export const validateUserIdAndRoles = (to, from, next, minRole = 5) => {
  const user = store.getters["currentUser"] as User;
  if (user.id) {
    if (user.id.toString() == to.params.id || user.role_id >= minRole) {
      next();
    } else {
      next("unauthorized");
    }
  } else {
    store.dispatch(Actions.VERIFY_AUTH).then(() => {
      const user = store.getters["currentUser"] as User;
      if (user.id.toString() == to.params.id || user.role_id >= minRole) {
        next();
      } else {
        next("unauthorized");
      }
    });
  }
};

export const validateUserRoles = (to, from, next, minRole = 5, maxRole = 7) => {
  const user = store.getters["currentUser"] as User;
  if (user.id) {
    if (
      (!user.custom_menu &&
        user.role_id >= minRole &&
        user.role_id <= maxRole) ||
      (user.custom_menu && user.custom_menu.indexOf(to.name) > -1)
    ) {
      next();
    } else {
      next("unauthorized");
    }
  } else {
    store.dispatch(Actions.VERIFY_AUTH).then(() => {
      const user = store.getters["currentUser"] as User;
      if (
        (!user.custom_menu &&
          user.role_id >= minRole &&
          user.role_id <= maxRole) ||
        (user.custom_menu && user.custom_menu.indexOf(to.name) > -1)
      ) {
        next();
      } else {
        next("unauthorized");
      }
    });
  }
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Training } from "@/store/interfaces/Training";

@Module
export default class TrainingModule extends VuexModule {
  redirectTo = "";
  loadedTraining: Training = {} as Training;
  trainings: Training[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedTraining(): Training {
    return {
      ...this.loadedTraining,
      ...{ specializations: this.loadedTraining.specializations ?? [] },
    } as Training;
  }

  /**
   * Retreive current loaded trainings
   */
  get getTrainings(): Training[] {
    return this.trainings;
  }

  /**
   * Get last pagination setup
   */
  get getTrainingsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getTrainingsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_TRAINING](training: Training) {
    this.loadedTraining = training;
  }

  @Mutation
  [Mutations.SET_TRAININGS](data) {
    this.trainings = data.data;
  }

  @Mutation
  [Mutations.SET_TRAININGS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_TRAININGS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_TRAININGS](payload) {
    payload = payload ?? { per_page: 1000 };
    ApiService.setHeader();
    return new Promise<Training[]>((resolve, reject) => {
      ApiService.post("trainings", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_TRAININGS, data["trainings"]);
            this.context.commit(
              Mutations.SET_TRAININGS_PAGINATION,
              data["trainings"].pagination
            );
            resolve(data["trainings"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainings.errors.cantGetTrainings"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_TRAINING_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Training>((resolve) => {
        const training = {} as Training;
        this.context.commit(Mutations.SET_LOADED_TRAINING, training);
        resolve(training);
      });
    }
    return new Promise<Training>((resolve, reject) => {
      ApiService.get(`training/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING,
              data["training"]
            );
            resolve(data["training"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainings.errors.commonGET_TRAINING_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TRAINING_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Training>((resolve, reject) => {
      ApiService.delete(`training/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING,
              data["training"]
            );
            resolve(data["training"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainings.errors.commonDELETE_TRAINING_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TRAINING](training: Training) {
    const payload = JSON.parse(JSON.stringify(training));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`training/${training.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING,
              data["training"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id: training.id, name: training.name };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainings.form.trainingChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TRAINING](training: Training) {
    const payload = JSON.parse(JSON.stringify(training));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`training`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING,
              data["training"]
            );
            resolve(data["training"]);
          } else {
            const i18string = data.i18;
            const i18params = { id: training.id, name: training.name };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainings.form.trainingAddFail"
          );
          reject();
        });
    });
  }
}

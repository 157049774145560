import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Diagnostic } from "@/store/interfaces/Diagnostic";

@Module
export default class DiagnosticModule extends VuexModule {
  redirectTo = "";
  loadedDiagnostic: Diagnostic = {} as Diagnostic;
  Diagnostics: Diagnostic[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedDiagnostic(): Diagnostic {
    return { ...this.loadedDiagnostic } as Diagnostic;
  }

  /**
   * Retreive current loaded diagnostics
   */
  get getDiagnostics(): Diagnostic[] {
    return this.Diagnostics;
  }

  /**
   * Get last pagination setup
   */
  get getDiagnosticsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getDiagnosticsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_DIAGNOSTIC](Diagnostic: Diagnostic) {
    this.loadedDiagnostic = Diagnostic;
  }

  @Mutation
  [Mutations.SET_DIAGNOSTICS](data) {
    this.Diagnostics = data.data;
  }

  @Mutation
  [Mutations.SET_DIAGNOSTICS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_DIAGNOSTICS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_DIAGNOSTICS](payload) {
    ApiService.setHeader();
    return new Promise<Diagnostic[]>((resolve, reject) => {
      ApiService.post("diagnostics", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_DIAGNOSTICS, data["diagnostics"]);
            this.context.commit(
              Mutations.SET_DIAGNOSTICS_PAGINATION,
              data["diagnostics"].pagination
            );
            resolve(data["diagnostics"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "diagnostics.errors.cantGetDiagnostics"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_DIAGNOSTIC_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Diagnostic>((resolve) => {
        const Diagnostic = {} as Diagnostic;
        this.context.commit(Mutations.SET_LOADED_DIAGNOSTIC, Diagnostic);
        resolve(Diagnostic);
      });
    }
    return new Promise<Diagnostic>((resolve, reject) => {
      ApiService.get(`diagnostic/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DIAGNOSTIC,
              data["diagnostic"]
            );
            resolve(data["diagnostic"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "diagnostics.errors.commonGET_DIAGNOSTIC_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DIAGNOSTIC_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Diagnostic>((resolve, reject) => {
      ApiService.delete(`diagnostic/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DIAGNOSTIC,
              data["diagnostic"]
            );
            resolve(data["diagnostic"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "diagnostics.errors.commonDELETE_DIAGNOSTIC_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DIAGNOSTIC](Diagnostic: Diagnostic) {
    const payload = JSON.parse(JSON.stringify(Diagnostic));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`diagnostic/${Diagnostic.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DIAGNOSTIC,
              data["diagnostic"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Diagnostic.id,
              name: Diagnostic.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "diagnostics.form.diagnosticChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_DIAGNOSTIC](Diagnostic: Diagnostic) {
    const payload = JSON.parse(JSON.stringify(Diagnostic));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`diagnostic`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_DIAGNOSTIC,
              data["diagnostic"]
            );
            resolve(data["diagnostic"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Diagnostic.id,
              name: Diagnostic.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "diagnostics.form.diagnosticAddFail"
          );
          reject();
        });
    });
  }
}

// PickUniqueObjectGame.js

import React, { useState, useEffect, useRef } from "react";
import { Ellipse, Rectangle, Triangle } from "./resources/Shapes";

const getRandomSvg = () => {
  const svgs = [Ellipse, Rectangle, Triangle];
  return svgs[Math.floor(Math.random() * svgs.length)];
};

const getRandomColor = () => {
  const colors = ["#368f58", "#1a4e8b", "#F1416C", "#FFC700", "#000000"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const getRandomSvgAndColor = () => {
  let SvgComponent = getRandomSvg();
  const color = getRandomColor();
  return { SvgComponent, color };
};

const scenarios = [
  (gridSize, uniqueIndex, amountOfCharacters) => {
    const getRandomSvgAndColor = (unique = false) => {
      let SvgComponent = getRandomSvg();

      if (unique) {
        while (SvgComponent === CommonSvg) {
          SvgComponent = getRandomSvg();
        }
      }

      const color = getRandomColor();
      return { SvgComponent, color };
    };

    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();
    let filledCells = 0;

    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    const { SvgComponent: UniqueSvg, color: uniqueColor } =
      getRandomSvgAndColor(true);
    grid[uniqueIndex] = {
      SvgComponent: UniqueSvg,
      color: uniqueColor,
      isUnique: true,
    };

    return grid.map((cell) => ({
      ...cell,
      content: cell.SvgComponent ? (
        <cell.SvgComponent fill={cell.color} />
      ) : (
        " "
      ),
    }));
  },

  (gridSize, uniqueIndex, amountOfCharacters) => {
    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));
    let filledCells = 0;
    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    let UniqueSvg, uniqueColor;
    do {
      ({ SvgComponent: UniqueSvg, color: uniqueColor } =
        getRandomSvgAndColor(true));
    } while (UniqueSvg === Ellipse); // Ensure the unique shape isn't an Ellipse

    grid[uniqueIndex] = {
      SvgComponent: UniqueSvg,
      color: uniqueColor,
      isUnique: true,
    };

    return grid.map((cell) => ({
      ...cell,
      content: cell.SvgComponent ? (
        <cell.SvgComponent
          fill={cell.color}
          style={cell.isUnique ? { transform: "rotate(45deg)" } : null}
        />
      ) : (
        " "
      ),
    }));
  },

  (gridSize, uniqueIndex, amountOfCharacters) => {
    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));
    let filledCells = 0;
    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    grid[uniqueIndex] = {
      SvgComponent: CommonSvg,
      color: commonColor,
      isUnique: true,
    };

    return grid.map((cell) => ({
      ...cell,
      content: cell.SvgComponent ? (
        <cell.SvgComponent
          fill={cell.color}
          style={cell.isUnique ? { transform: "scale(0.5)" } : {}}
        />
      ) : (
        " "
      ),
    }));
  },
];

const PickUniqueObjectGame = ({
  settings: { size, amountOfCharacters },
  onGameEnd,
}) => {
  const [grid, setGrid] = useState([]);
  const [score, setScore] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [animate, setAnimate] = useState(false);

  const totalTime = 30;
  const [timer, setTimer] = useState(30);
  const timerIdRef = useRef(null);

  useEffect(() => {
    if (timer > 0 && timerIdRef.current === null) {
      timerIdRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };
  }, [timer]);

  useEffect(() => {
    initializeGame();
  }, [size]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
      onGameEnd({
        games: [
          {
            id: "PickUniqueObjectGame",
            score,
            right: rightAnswers,
            wrong: wrongAnswers,
          },
        ],
      });
    }
  }, [timer, score, rightAnswers, wrongAnswers, onGameEnd]);

  const initializeGame = () => {
    const gridSize = size;
    const uniqueIndex = Math.floor(Math.random() * gridSize * gridSize);
    const scenarioIndex = Math.floor(Math.random() * scenarios.length);
    const newGrid = scenarios[scenarioIndex](
      gridSize,
      uniqueIndex,
      amountOfCharacters
    );

    setGrid(newGrid);

    setAnimate(false);
    setTimeout(() => setAnimate(true), 10);
  };

  const handleSelection = (index) => {
    if (grid[index].SvgComponent) {
      if (grid[index].isUnique) {
        setScore((prevScore) => prevScore + 1);
        setRightAnswers((prevRightAnswers) => prevRightAnswers + 1);
      } else {
        setWrongAnswers((prevWrongAnswers) => prevWrongAnswers + 1);
      }

      setAnimate(false);
      setTimeout(initializeGame, 300);
    }
  };

  const timeBarWidth = (timer / totalTime) * 100;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <style>
        {`
          @keyframes scaleDown {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(0);
            }
          }
          @keyframes scaleUp {
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
          }
          .scale-down {
            animation: scaleDown 0.3s forwards;
          }
          .scale-up {
            animation: scaleUp 0.3s forwards;
          }
        `}
      </style>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            width: "120px",
            height: "20px",
            borderRadius: "5px",
            backgroundColor: "#EFEFEF",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${timeBarWidth}%`,
              backgroundColor: "#368f58",
              transition: "width 1s linear",
            }}
          ></div>
        </div>
        <div>Score: {score}</div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${size}, 50px)`,
          gap: "10px",
          backgroundColor: "#EFEFEF",
          padding: "15px",
          borderRadius: "10px",
        }}
      >
        {grid.map((item, index) => (
          <div
            key={index}
            className={animate ? "scale-up" : "scale-down"}
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: item.SvgComponent ? "pointer" : "default",
            }}
            onClick={() => handleSelection(index)}
          >
            {item.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PickUniqueObjectGame;

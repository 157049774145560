import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade modal-fullscreen",
  id: "common_modal",
  "aria-modal": "true",
  role: "dialog",
  ref: "fileSelectorModalRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-800px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "common_modal_header"
}
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "modal-footer flex-center" }
const _hoisted_10 = {
  type: "button",
  id: "kt_modal_new_address_cancel",
  class: "btn btn-white me-3",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "scroll-y me-n7 pe-7",
            id: "common_modal_scroll",
            "data-kt-scroll": "true",
            "data-kt-scroll-activate": "{default: false, lg: true}",
            "data-kt-scroll-max-height": "auto",
            "data-kt-scroll-dependencies": "#common_modal_header",
            "data-kt-scroll-wrappers": "#common_modal_scroll",
            "data-kt-scroll-offset": "300px",
            innerHTML: _ctx.body
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.translate("back", "common")), 1)
        ])
      ])
    ])
  ], 512))
}
export const fileEn = {
  files: {
    selector: {
      toolTips: {
        upload: "Upload",
        select: "Select from list",
        cancel: "Remove",
        preview: "Preview",
      },
    },
    toolTips: {
      edit: "Edit file {file}",
      delete: "Delete file {file}",
      preview: "Preview file {file}",
    },
    editTitle: "Edit file",
    newTitle: "New file",
    addFile: "Add file",
    fileSelector: {
      title: "Select a file to insert inside the text",
    },
    errors: {
      notFound: "File {id} not found",
      notYourRole: "Cant access data for file {id}",
      nameExists: "File named `{name}` already exists",
      commonGET_FILE_BY_ID: "Error retrieving file data",
      commonDELETE_FILE_BY_ID: "Error deleting file",
      commonDELETE_FILES: "Error deleting files",
      cantGetFiles: "Failed to fetch files data",
      fileUploadFailed: "Failed to upload file",
      diagnosticImportBatchFailed: "Failed to import diagnostics data",
      resultsExportFailed: "Failed to export diagnostics data",
      leadsImportBatchFailed: "Failed to import leads",
      notAllowedFiletype: "Uploaded file type not allowed",
    },
    types: {
      pdf: "PDF",
      image: "Image",
      video: "Video",
      audio: "Audio",
      icon: "Icon",
      document: "Document",
    },
    form: {
      name: "Name",
      company: "Company",
      file: "File",
      description: "Description",
      specializations: "Specializations",
      language: "Language",
      fileChanged: "File was stored",
      fileChangedFail: "Failed to store file",
      fileAdded: "New file was created",
      fileAddFail: "Failed to add new file",
    },
    table: {
      header: {
        name: "Name",
        company: "Company",
        type: "Typ",
        language: "Language",
        specializations: "Specializations",
        actions: "Actions",
      },
      deleteFileQuestion:
        "Do you really want to delete file <strong>{file}</strong>?",
      searchFiles: "Search for files",
      fileDeleted: "File {file} was deleted",
      filesDeleted: "Files were deleted",
      noRecords: "No files found",
    },
  },
};

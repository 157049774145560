// Shapes.js

import React from "react";
import "./Shapes.css";

export const Ellipse = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="-17 -17 100 100"
    style={style}
  >
    <defs>
      <filter id="ellipseFilter" x="-20%" y="-20%" width="120%" height="120%">
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <circle cx="34" cy="31" r="25" fill={fill} />
  </svg>
);

export const Rectangle = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="-17 -17 100 100"
    style={style}
  >
    <defs>
      <filter id="rectangleFilter" x="-20%" y="-20%" width="120%" height="120%">
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <rect width="50" height="50" x="9" y="6" fill={fill} />
  </svg>
);

export const Triangle = ({ fill, className, style }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="-17 -17 100 100"
    style={style}
  >
    <defs>
      <filter id="triangleFilter" x="-20%" y="-20%" width="120%" height="120%">
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <path d="M 34 6 L 59 56 L 9 56 Z" fill={fill} />
  </svg>
);

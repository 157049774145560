<style lang="scss"></style>
<template>
  <RememberTheGridMultipleGame
    :settings="prepareSettings(tool)"
    :onGameEnd="gameFinished"
  ></RememberTheGridMultipleGame>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { Tool } from "@/store/interfaces/Tool";
import { useToolFormater } from "@/components/tools/composables/toolFormater";
import { applyReactInVue } from "veaury";
import RememberTheGridMultipleGame from "@/react_app/games/RememberTheGridMultipleGame";

export default defineComponent({
  name: "remember-the-grid-multiple-game",
  props: {
    tool: {
      type: Object as () => Tool,
      required: true,
    },
  },
  emits: ["game-finished"],
  components: {
    RememberTheGridMultipleGame: applyReactInVue(RememberTheGridMultipleGame),
  },
  setup(props, { emit }) {
    const { prepareSettings, printName } = useToolFormater(props.tool);

    const gameComponentName = computed<string>(() => {
      return props.tool.settings["react_component"];
    });

    const gameFinished = (data: object) => {
      emit("game-finished", data);
    };

    return { prepareSettings, gameFinished, gameComponentName, printName };
  },
});
</script>

import { EmailTemplates } from "@/store/enums/EmailTemplateEnums";
export const mailCs = {
  email_templates: {
    [EmailTemplates.COMMON]: "Výchozí",
    [EmailTemplates.NEWSLETTER_BIRTHDAY]: "Newsletter - narozeniny",
  },
  mails: {
    passwordReset: {
      subject: "iSenses: Obnovení hesla",
      body: {
        title: "Obnovení hesla",
        text: "Pokud jste zapoměli své heslo nebo si ho přejete obnovit, použijte prosím odkaz níže.",
        button: {
          text: "Obnovit heslo",
          link: "password-change/",
        },
        textAfter:
          "Pokud jste si obnovení nevyžádali, tento email ignorujte. Pouze osoba s přístupem do Vaší emailové schránky může heslo změnit.",
        companyId: "i",
      },
    },
    inviteUser: {
      subject: "iSenses: Pozvánka",
      body: {
        title: "Vítejte v isenses",
        text: "Právě jsme Vám vytvořily účet v systému iSenses. Prosím nastavte si Vaše heslo a poté se přihlašte.",
        button: {
          text: "Nastavit Heslo",
          link: "password-change/",
        },
        textAfter:
          "Pokud nemáte zájem o registraci do systému iSenses, tento e-mail ignorujte. Pouze osoba s přístupem do Vaší e-mailové schránky může nastavit heslo.",
        companyId: "i",
      },
    },
  },
};

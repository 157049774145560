export const languageCs = {
  languages: {
    languageSelector: {
      title: "Vyberte jazyk",
    },
    toolTips: {
      edit: "Upravit jazyk {language}",
      delete: "Odstranit jazyk {language}",
    },
    editTitle: "Upravit jazyk",
    newTitle: "Nový jazyk",
    addLanguage: "Přidat jazyk",
    errors: {
      notFound: "Jazyk id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit jazyk id {id}",
      nameExists: "Jazyk s nazvem `{name}` již existuje",
      commonGET_LANGUAGE_BY_ID: "Chyba při získávání dat o jazycích",
      commonDELETE_LANGUAGE_BY_ID: "Chyba při odstraňování jazyků",
      commonDELETE_LANGUAGES: "Nepodařilo se odstranit jazyky",
      cantGetLanguages: "Chyba při získávání dat o jazycích",
    },
    form: {
      name: "Název",
      code: "Kód",
      flag: "Vlajka",
      languageChanged: "Jazyk byl upraven",
      languageChangedFail: "Nepodařilo se upravit jazyk",
      languageAdded: "Nový jazyk byl vytvořen",
      languageAddFail: "Nepodařilo se vytvořit nový jazyk",
    },
    table: {
      header: {
        name: "Název",
        code: "Kód",
        flag: "Vlajka",
        actions: "Akce",
      },
      deleteLanguageQuestion:
        "Opravdu chcete odstranit language <strong>{language}</strong>?",
      searchLanguages: "Hledat jazyk",
      languageDeleted: "Jazyk {language} byl odstraněn",
      languagesDeleted: "Jazyky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné jazyky",
    },
  },
};

<template>
  <router-view />
  <common-modal></common-modal>
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/theme-chalk/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>

<script lang="ts">
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import i18n from "@/core/plugins/i18n";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { useToast } from "vue-toastification";
import router from "@/router/clean";
import { useRoute } from "vue-router";
import CommonModal from "@/components/modals/CommonModal.vue";

export default defineComponent({
  name: "app",
  components: { CommonModal },
  data() {
    return {
      polling: 0,
    };
  },
  methods: {
    pollData() {
      const { t } = i18n.global;
      this.polling = window.setInterval(() => {
        store.dispatch(Actions.VERIFY_AUTH).catch(() => {
          useToast().warning(t("jwt.invalidToken"));
          router.push({ name: "sign-in" });
        });
      }, 7620000);
    },
  },
  created() {
    this.pollData();
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    watch(
      () => route.name,
      () => {
        const name = route.name as string;
        document.title = t("titles." + name) + " | " + process.env.VUE_APP_NAME;
      }
    );
    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      Promise.all([store.dispatch(Actions.LOAD_LANGUAGES)])
        .catch(() => {
          useToast().warning(store.getters.getLastError);
        })
        .finally(() => {
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        });
    });
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
});
</script>

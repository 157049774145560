export const notificationSk = {
  notifications: {
    welcome: "Vitajte v iSenses",
    welcomeBack: "Vitajte späť v iSenses {name}",
    newEshopOrder: "Ďakujeme za vašu objednávku na senses.zone č. {orderNr}",
    failedToStartPurchasedProgram:
      "Nepodarilo sa spustiť Váš zakúpený program na senses.zone obj. č. {orderNr}. Prosím, kontaktujte tím iSenses na {phone} alebo {email}",
    purchasedProgramStarted:
      "Bol spustený Váš zakúpený program {programName}, obj. č. {orderNr}. Môžete začať trénovať.",
    clientsMenuUpdated: "Menu klientov sa zmenilo",
    newSenaptecResultsReceived:
      "Boli prijaté nové merania z prístroja Senaptec",
    companyLogoChanged: "Logo firmy bolo aktualizované",
    title: "Pripomienky",
    toolTips: {
      edit: "Upraviť pripomienku {notification}",
      delete: "Odstrániť pripomienku {notification}",
    },
    editTitle: "Upraviť pripomienku",
    newTitle: "Nová pripomienka",
    addNotification: "Pridať pripomienku",
    errors: {
      notFound: "Pripomienka s ID {id} nebola nájdená",
      notYourRole: "Nemáte právo zobraziť pripomienku s ID {id}",
      commonGET_NOTIFICATION_BY_ID:
        "Chyba pri získavaní údajov o pripomienkach",
      commonDELETE_NOTIFICATION_BY_ID: "Chyba pri odstraňovaní pripomienky",
      commonDELETE_NOTIFICATIONS: "Nepodarilo sa odstrániť pripomienku",
      cantGetNotifications: "Chyba pri získavaní údajov o pripomienkach",
    },
    form: {
      name: "Názov",
      company: "Firma",
      notificationChanged: "Pripomienka bola upravená",
      notificationChangedFail: "Nepodarilo sa upraviť pripomienku",
      notificationAdded: "Nová pripomienka bola vytvorená",
      notificationAddFail: "Nepodarilo sa vytvoriť novú pripomienku",
    },
    table: {
      history: "Historie záznamů",
      header: {
        date_start: "Dátum",
        message: "Text",
        closed: "Vyriešené",
      },
      deleteNotificationQuestion:
        "Naozaj chcete odstrániť pripomienku <strong>{notification}</strong>?",
      searchNotifications: "Hľadať pripomienku",
      notificationDeleted: "Pripomienka {notification} bola odstránená",
      notificationsDeleted: "Pripomienky boli odstránené",
      noRecords: "Neboli nájdené žiadne pripomienky",
    },
  },
};

export const userSk = {
  users: {
    trainingInfo: "Informácie k tréningu",
    fillInvoiceDetails: "Vyplniť údaje pre fakturáciu",
    fillInvoiceDetailsNote:
      "Prosíme o kontrolu a vyplnenie fakturačných údajov.",
    toolTips: {
      loggedThisWeek: "Bol prihlásený tento týždeň?",
      programsStatus: "Stav programu",
      programPaidStatus: "Stav zaplatenia programu",
      trainingsStatus: "Stav tréningu",
      roleChange: "Zmena role pre užívateľa {user}",
      edit: "Upraviť užívateľa {user}",
      delete: "Odstrániť užívateľa {user}",
      invite: "Odoslať pozvánku pre užívateľa {user}",
      inviteAgain: "Opätovne odoslať pozvánku pre užívateľa {user}",
      createInvoice: "Vystaviť faktúru",
      privacyChanged:
        "Užívateľ zmenil nastavenie súkromia. Zmenu potvrdíte kliknutím na túto ikonu",
    },
    roleChange: {
      modal: {
        title: "Zmena role pre užívateľa {user}",
      },
    },
    errors: {
      notFound: "Užívateľ id {id} nebol nájdený",
      notYourRole: "Nemáte práva zobraziť užívateľa id {id}",
      emailExists: "Užívateľ s emailom `{email}` už existuje",
      commonGET_USER_BY_ID: "Chyba pri získavaní údajov o užívateľovi",
      commonDELETE_USER_BY_ID: "Chyba pri odstránení užívateľa",
      commonINVITE_USER: "Chyba pri odosielaní pozvánky pre užívateľa",
      commonDELETE_USERS: "Nepodarilo sa odstrániť užívateľa",
      commonCHECK_PRIVACY_CHANGE: "Nepodarilo sa schváliť zmenu súkromia",
      commonGDPR_CONSENT:
        "Nepodarilo sa spracovať Váš súhlas. Skúste prosím neskôr",
      avatarUploadFailed: "Nepodarilo sa nahrať obrázok avatara",
      cantGetUsers: "Chyba pri získavaní údajov o užívateľoch",
      cantGetOverseers: "Chyba při získávání dat o odpovědných osobách",
      cantGetClients: "Chyba pri získavaní údajov o klientoch",
      cantGetGuests: "Chyba pri získavaní údajov o hostiteľoch",
      cantGetConversation: "Chyba pri získavaní správ chatu",
      cantAddMessages: "Nepodarilo sa pridať správu",
      cantMarkMessages: "Nepodarilo sa označiť správy ako prečítané",
      cantSendBulkMessage: "Nepodarilo sa odoslať hromadnú správu",
      cantGetSmsBalance: "Nepodarilo sa získat informácie o kredite Sms služby",
      cantGetActiveUserPrograms:
        "Nepodarilo sa získat aktívne programy užívateľa",
    },
    relationships: {
      father: {
        title: "Otec",
      },
      mother: {
        title: "Matka",
      },
      employer: {
        title: "Zamestnávateľ",
      },
    },
    overseers: {
      title: "Odpovědné osoby",
      roles: {
        coordinator: { title: "Koordinátor" },
        owner: { title: "Vlastník" },
        recommender: { title: "Doporučitel" },
        guarantor: { title: "Garant" },
        coach: { title: "Trenér" },
      },
    },
    roles: {
      legalRepresentative: {
        title: "Zák. zástupca",
        shortcut: "ZZS",
      },
      admin: {
        title: "Administrátor",
        shortcut: "ADM",
      },
      therapist: {
        title: "Garant",
        shortcut: "GAR",
      },
      coach: {
        title: "Tréner",
        shortcut: "TRE",
      },
      dealer: {
        title: "Obchodník",
        shortcut: "OBK",
      },
      specialist: {
        title: "Koordinátor",
        shortcut: "SPC",
      },
      client: {
        title: "Klient",
        shortcut: "KLI",
      },
      guest: {
        title: "Host",
        shortcut: "HST",
      },
    },
    newUser: "Nový užívateľ",
    newUserCreated: "Nový užívateľ bol vytvorený",
    table: {
      addUser: "Pridať užívateľa",
      header: {
        firstName: "Meno",
        lastName: "Priezvisko",
        newClient: "Nový klient",
        email: "Email",
        company: "Firma",
        phone: "Telefón",
        role: "Rola užívateľa",
        actions: "Akcie",
      },
      deleteUserQuestion:
        "Naozaj chcete odstrániť užívateľa <strong>{user}</strong>?",
      inviteUserQuestion:
        "Naozaj chcete odoslať pozvánku pre užívateľa <strong>{user}</strong>?",
      deleteUsersQuestion:
        "Naozaj chcete odstrániť užívateľa(<strong>{count}</strong>)?",
      searchUsers: "Hľadať užívateľa",
      userDeleted: "Užívateľ {user} bol odstránený",
      userInvited: "Pozvánka pre užívateľa {user} bola odoslaná",
      usersDeleted: "Užívatelia boli odstránení",
      selected: "Vybrané",
      deleteSelected: "Vymazať vybrané",
      noRecords: "Neboli nájdení žiadni užívatelia",
    },
    account: {
      myTrainings: {
        title: "Moje tréningy",
        week: "Týždeň",
        training: "Tréning",
        noWeekEvents: "Pre tento týždeň nemáte priradené žiadne tréningy",
        contactUs: "Kontaktujte nás pre ďalší postup",
        oops_line1: "Nezobrazujú sa Vám tréningy",
        oops_line2:
          "Skontrolujte si, prosím, svoje platby a či je všetko v poriadku, kontaktujte nás.",
        upcomingEvents:
          "Čaká na Vás tréning <strong class='text-primary'>{name}</strong>, ktorý začne <strong class='text-primary'>{start}</strong>.",
        eventPaused: "Tréning je pozastavený. Bude pokračovať od {date}.",
        note: "Napíšte nám ... ",
      },
      loginHistory: {
        modal: {
          title: "História prihlásení",
          total: "Celkom",
          last30: "Posledných 30 prihlásení",
        },
      },
      lastLogin: "Naposledy prihlásený",
      lastContacted: "Naposledy kontaktovaný",
      lastActivity: "Naposledy aktívny",
      badges: {
        training: "Tréning",
      },
      overview: {
        title: "Detail účtu",
        editButton: "Upraviť účet",
        form: {
          fullName: "Meno a priezvisko",
          contactPhone: "Kontaktný telefón",
          secondaryPhone: "Ďalší telefón",
          address: "Adresa pre doručenie pomôcok",
          age: "Vek",
          invAddress: "Fakturačná adresa",
          documentGenerated: "Dokument je vygenerován a připraven ke stažení",
          failedToGeneratDoc: "Nepodařilo se vygenereóvat dokument",
        },
      },
      otherContacts: {
        title: "Ďalšie kontakty",
        addButton: "Pridať kontakt",
        noContacts: "Nejsou zadány žádné další kontakty",
        missingLegalRepresentative:
          "Medzi ďalšími kontaktmi musíte vytvoriť osobu zákonného zástupcu (otec / matka)",
        modal: {
          titleAdd: "Nový kontakt",
          titleUpdate: "Upraviť kontakt `{name}`",
          form: {
            relationship: "Vzťah",
            name: "Meno",
            phone: "Telefón",
            email: "Email",
            sameEmailAsClient: "Email nesmie byť rovnaký ako email klienta",
          },
        },
      },
      assignedTrainings: {
        title: "Priradené tréningy",
        addButton: "Priradiť nový",
        noEvents: "Momentálne nemá užívateľ priradené žiadne tréningy",
        deleteTrainingEvent:
          "Naozaj chcete odstrániť tréning<br/><strong>{name}</strong> ?",
        states: {
          created: "Čaká na začiatok",
          active: "Aktívny",
          paused: "Pozastavený",
          finished: "Dokončený",
        },
        until: "do",
        modal: {
          titleAdd: "Priradiť nový tréning",
          titleUpdate: "Upraviť tréning `{name}`",
          form: {
            startAt: "Začiatok od",
            training: "Tréning",
            paused: "Pozastavené",
            pausedUntil: "Pozastavené do",
            continueWithWeek: "Pokračovať týždňom",
          },
        },
      },
      training: {
        title: "Tréning",
        form: {
          training: "Tréning Áno / Nie",
        },
      },
      chat: {
        title: "Chat",
        send: "Odoslať",
        balance: "Kredit SMS služby",
        typeMessage: "Napíšte správu",
        bulkMessageSent: "Hromadná správa bola odoslaná.",
        form: {
          filterByTraining: "Iba klienti v tréningu",
          company: "Firmy",
          userGroup: "Skupiny",
          clients: "Klienti",
          reciever: "Príjemca",
          twoFiveRecievers: "Príjemcovia",
          recievers: "Príjemcov",
          selectCompany: "Vyberte firmu",
          selectGroup: "Vyberte skupinu",
          emailSubject: "Predmet",
          emailTitle: "Nadpis",
          otherEmails: "Ďalšie emaily",
          otherPhones: "Ďalšie telefónne čísla",
          emailTemplate: "Šablóna",
        },
      },
      diagnostics: {
        title: "Diagnostika",
        form: {
          note: "Poznámka",
        },
      },
      privacy: {
        title: "Súkromia",
        form: {},
      },
      notifications: {
        title: "Pripomienky",
        form: {},
      },
      programs: {
        title: "Produkty",
        finished: "Dokončené",
        active: "Aktivní",
        addButton: "Priradiť nový",
        noPrograms: "Momentálne nemá užívateľ priradené žiadne produkty",
        deleteUserProgram:
          "Naozaj chcete odstrániť produkt<br/><strong>{name}</strong> ?",
        modal: {
          titleAdd: "Priradiť nový produkt",
          titleUpdate: "Upraviť produkt `{name}`",
          form: {
            startAt: "Začiatok od",
            program: "Produkt",
            invoice_sent: "FA odoslaná",
            invoice_paid: "Zaplatené",
            training_template: "Šablona tréninku",
            start_by_client: "Spuštění klientem",
            activate_training: "Aktivovať tréning",
            price: "Cena",
            currency: "Mena",
            nr_of_months: "Počet mesiacov",
            eshop_product: "Program na eshope",
            noEshopProduct:
              "K produktu nie je priradený žiadny aktívny produkt na eshope",
            activateTheseTrainings: "Aktivuje tieto tréningy",
          },
        },
      },
      results: {
        title: "Výsledky",
        lastMeasurement: "Aktuálne meranie",
        prevLastMeasurement: "Predchádzajúce meranie",
        initialMeasurement: "Vstupní měření",
        createdBy: "Měření provedl",
        form: {
          description: "Popis",
          userChanged: "Užívateľ bol upravený!",
          userChangedFail: "Nepodarilo sa upraviť užívateľa!",
          sendToLegalRepresentative: "Odoslať zákonnému zástupcovi",
          sendToClient: "Odeslat klientovi",
          download: "Stiahnuť",
          generatePdf: "Vygenerovať Pdf a ",
          pdfGenerateFailed: "Nepodarilo sa vygenerovať Pdf",
          pdfGenerated: "Pdf s výsledkami je pripravené na stiahnutie",
          pdfMailed: "Pdf s výsledkami bolo odoslané",
        },
        noRecords: "Neboli nájdené žiadne výsledky",
      },
      invoices: {
        title: "Faktúry vydané",
        noRecords: "Neboli nájdené žiadne faktúry",
      },
      assignment: {
        title: "Začlenenie",
        form: {
          userGroup: "Skupina",
          company: "Firma",
          specializations: "Špecializácie",
          diagnostics: "Diagnostiky",
          sports: "Šport",
          specialists: "Priradení koordinátori",
          specialist_groups: "Skupiny koordinátora",
          specialist_groups_as: "Ako koordinátor pre",
          role: "Rola",
          userChanged: "Užívateľ bol upravený!",
          userChangedFail: "Nepodarilo sa upraviť užívateľa!",
        },
      },
      assignedClients: {
        title: "Priradení klienti",
        form: {
          company: "Firmy",
          userGroup: "Skupiny",
          clients: "Klienti",
          selectCompany: "Vyberte firmu",
          selectGroup: "Vyberte skupinu",
        },
      },
      settings: {
        title: "Nastavenie účtu",
        form: {
          basicInfo: "Základné informácie",
          invoiceCompany: "Fakturovať za firmu",
          address: "Adresa pre doručenie pomôcok",
          avatar: "Avatar",
          employment: "Zamestnanie",
          specialists: "Priradení koordinátori",
          changeAvatar: "Zmeniť avatar",
          removeAvatar: "Odstrániť avatar",
          allowedFileTypes: "Povolené typy obrázkov",
          fullName: "Meno a priezvisko",
          firstName: "Meno",
          lastName: "Priezvisko",
          contactPhone: "Kontaktný telefón",
          secondaryPhone: "Ďalší telefón",
          email: "Email",
          language: "Jazyk tréningu",
          email_legal_representative: "Email zákonného zástupcu",
          password: "Heslo",
          confirmPassword: "Heslo znovu",
          country: "Krajina",
          street: "Ulica",
          city: "Obec",
          zip: "PSČ",
          birthDate: "Rok narodenia",
          pickADay: "Vyberte deň",
          pickAYear: "Vyberte rok",
          userChanged: "Užívateľ bol upravený!",
          userChangedFail: "Nepodarilo sa upraviť užívateľa!",
          isCompany: "Právnická osoba",
          invoiceDetails: "Fakturačné údaje",
          in: "IČ",
          role: "Rola",
          tin: "DIČ",
          companyName: "Názov spoločnosti",
        },
        signInMethod: {
          title: "Prihlasovacie údaje",
          email: {
            title: "Email",
            enterNewEmailAddress: "Zadajte nový email",
            confirmPassword: "Zadajte heslo",
            updateEmail: "Uložiť Email",
            changeEmail: "Zmeniť Email",
            emailChanged: "Email bol úspešne zmenený!",
            emailChangedFail: "Nepodarilo sa zmeniť email!",
            passwordFail: "Chybné heslo!",
          },
          password: {
            title: "Heslo",
            currentPassword: "Staré heslo",
            newPassword: "Nové heslo",
            confirmNewPassword: "Nové heslo znovu",
            updatePassword: "Uložiť Heslo",
            resetPassword: "Zmeniť Heslo",
            passwordChanged: "Heslo bolo úspešne zmenené!",
            passwordChangedFail: "Nepodarilo sa zmeniť heslo!",
            passwordFail: "Chybné heslo!",
          },
        },
      },
    },
  },
};

export const trainingEn = {
  trainings: {
    toolTips: {
      edit: "Edit training {training}",
      delete: "Delete training {training}",
    },
    contentPreview: {
      title: "Content preview",
    },
    contentEditor: {
      title: "Content editor",
    },
    editTitle: "Edit training",
    newTitle: "New training",
    addTraining: "Add training",
    errors: {
      notFound: "Training {id} not found",
      notYourRole: "Cant access data for training {id}",
      nameExists: "Training named `{name}` already exists",
      commonGET_TRAINING_BY_ID: "Error retrieving training data",
      commonDELETE_TRAINING_BY_ID: "Error deleting training",
      commonDELETE_TRAININGS: "Error deleting trainings",
      cantGetTrainings: "Failed to fetch trainings data",
    },
    form: {
      name: "Name",
      name_client: "Name for client",
      nrOfWeeks: "Duration of training",
      weeks: "weeks",
      week: "Week",
      company: "Company",
      specialization: "Specialization",
      specializations: "Specializations",
      content: "Content",
      language: "Language",
      files: "Attachments",
      trainingChanged: "Training was stored",
      trainingChangedFail: "Failed to store training",
      trainingAdded: "New training was created",
      trainingAddFail: "Failed to add new training",
      weekFilePlaceholder: "Name of the atachment",
    },
    table: {
      header: {
        name: "Name",
        company: "Company",
        language: "Language",
        name_client: "Name for client",
        nrOfWeeks: "Duration of training",
        specialization: "Specialization",
        specializations: "Specializations",
        actions: "Actions",
      },
      deleteTrainingQuestion:
        "Do you really want to delete training <strong>{training}</strong>?",
      searchTrainings: "Search trainings",
      trainingDeleted: "Training {training} was deleted",
      trainingsDeleted: "Trainings were deleted",
      noRecords: "No trainings found",
    },
  },
};

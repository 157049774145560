export const paymentCs = {
  payments: {
    title: "Platby",
    toolTips: {
      edit: "Upravit platbu {payment}",
      delete: "Odstranit platbu {payment}",
      show: "Zobrazit platbu {payment}",
      unlink: "Zrušit vazbu na platbu",
    },
    editTitle: "Upravit platbu",
    showTitle: "Zobrazit platbu",
    newTitle: "Nová platba",
    addPayment: "Přidat platbu",
    errors: {
      notFound: "Platba id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit platbu id {id}",
      commonGET_PAYMENT_BY_ID: "Chyba při získávání dat o platbách",
      commonDELETE_PAYMENT_BY_ID: "Chyba při odstraňování platby",
      commonDELETE_PAYMENTS: "Nepodařilo se odstranit platbu",
      cantGetPayments: "Chyba při získávání dat o platbách",
    },
    form: {
      addPayment: "Přidat platbu",
      paymentChanged: "Platba byla upravena",
      paymentChangedFail: "Nepodařilo se upravit platbu",
      paymentAdded: "Nová platba byla vytvořena",
      paymentAddFail: "Nepodařilo se vytvořit novou platbu",
      date: "Datum ",
      senderAccountNumber: "Protistrana",
      receiverAccountNumber: "Účet",
      amount: "Částka",
      variable_symbol: "Var. symbol",
      constant_symbol: "Konst. symbol",
      specific_symbol: "Spec. symbol",
      user_message: "Poznámka",
      comment: "Komentář",
      transaction_type: "Druh pohybu",
    },
    table: {
      header: {
        date: "Datum ",
        senderAccountNumber: "Protistrana",
        receiverAccountNumber: "Účet",
        amount: "Částka",
        variable_symbol: "Var. symbol",
        transaction_type: "Druh pohybu",
        actions: "Akce",
      },
      deletePaymentQuestion:
        "Opravdu chcete odstranit platbu <strong>{payment}</strong>?",
      searchPayments: "Hledat platbu",
      paymentDeleted: "Platba {payment} byla odstraněna",
      paymentsDeleted: "Platby byly odstraněny",
      noRecords: "Nebyly nalezeny žádné platby",
    },
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { File } from "@/store/interfaces/File";
import { DiagnosticImportBatchResult } from "@/store/interfaces/DiagnosticImportBatch";

@Module
export default class FileModule extends VuexModule {
  redirectTo = "";
  loadedFile: File = {} as File;
  Files: File[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedFile(): File {
    return {
      ...this.loadedFile,
      ...{ specializations: this.loadedFile.specializations ?? [] },
    } as File;
  }

  /**
   * Retreive current loaded files
   */
  get getFiles(): File[] {
    return this.Files;
  }

  /**
   * Get last pagination setup
   */
  get getFilesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getFilesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_FILE](File: File) {
    this.loadedFile = File;
  }

  @Mutation
  [Mutations.SET_FILES](data) {
    this.Files = data.data;
  }

  @Mutation
  [Mutations.SET_FILES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_FILES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.UPLOAD_DIAGNOSTIC_IMPORT_FILE](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`diagnostic-import-batch/process-file`, data.formData)
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.fileUploadFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.PROCESS_DIAGNOSTIC_IMPORT_BATCH](id) {
    ApiService.setHeader();
    return new Promise<DiagnosticImportBatchResult>((resolve, reject) => {
      ApiService.get(`diagnostic-import-batch/process-batch/${id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.diagnosticImportBatchFailed"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UPLOAD_LEADS_IMPORT_FILE](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`leads-import-batch/process-file`, data.formData)
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.fileUploadFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.PROCESS_LEADS_IMPORT_BATCH](id) {
    ApiService.setHeader();
    return new Promise<DiagnosticImportBatchResult>((resolve, reject) => {
      ApiService.get(`leads-import-batch/process-batch/${id}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.leadsImportBatchFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DIAGNOSTICS_EXPORT](payload) {
    ApiService.setHeader();
    return new Promise<string>((resolve, reject) => {
      ApiService.post(`diagnostic-records/results-export`, payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.file);
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.resultsExportFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_FILE](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      data.folder = data.folder !== undefined ? data.folder : "media";
      data.storeImage = data.storeImage !== undefined ? data.storeImage : 0;
      data.saveAsType = data.saveAsType !== undefined ? data.saveAsType : "all";
      ApiService.post(
        `file/upload/${data.folder}/${data.storeImage}/${data.saveAsType}`,
        data.formData
      )
        .then(({ data }) => {
          if (data.success) {
            resolve(data.file);
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.fileUploadFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_FILES](payload) {
    ApiService.setHeader();
    return new Promise<File[]>((resolve, reject) => {
      ApiService.post("files", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_FILES, data["files"]);
            this.context.commit(
              Mutations.SET_FILES_PAGINATION,
              data["files"].pagination
            );
            resolve(data["files"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "files.errors.cantGetFiles");
          reject();
        });
    });
  }
  @Action
  [Actions.GET_FILE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<File>((resolve) => {
        const File = {} as File;
        this.context.commit(Mutations.SET_LOADED_FILE, File);
        resolve(File);
      });
    }
    return new Promise<File>((resolve, reject) => {
      ApiService.get(`file/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_FILE, data["file"]);
            resolve(data["file"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.commonGET_FILE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_FILE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<File>((resolve, reject) => {
      ApiService.delete(`file/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_FILE, data["file"]);
            resolve(data["file"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.errors.commonDELETE_FILE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_FILE](File: File) {
    const payload = JSON.parse(JSON.stringify(File));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`file/${File.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_FILE, data["file"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: File.id,
              name: File.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "files.form.fileChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_FILE](File: File) {
    const payload = JSON.parse(JSON.stringify(File));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`file`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_FILE, data["file"]);
            resolve(data["file"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: File.id,
              name: File.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "files.form.fileAddFail");
          reject();
        });
    });
  }
}

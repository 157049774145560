export const exerciseExecutionCs = {
  exerciseExecutions: {
    title: "Způsoby provádění",
    toolTips: {
      edit: "Upravit způsob provádění {exerciseExecution}",
      delete: "Odstranit způsob provádění {exerciseExecution}",
    },
    editTitle: "Upravit způsob provádění",
    newTitle: "Nový způsob provádění",
    addExerciseExecution: "Přidat způsob provádění",
    errors: {
      notFound: "Způsob provádění id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit způsob provádění id {id}",
      commonGET_EXERCISE_EXECUTION_BY_ID:
        "Chyba při získávání dat o způsobech provádění",
      commonDELETE_EXERCISE_EXECUTION_BY_ID:
        "Chyba při odstraňování způsobu provádění",
      commonDELETE_EXERCISE_EXECUTIONS:
        "Nepodařilo se odstranit způsob provádění",
      cantGetExerciseExecutions:
        "Chyba při získávání dat o způsobech provádění",
    },
    form: {
      addExerciseExecution: "Přidat způsob provádění",
      name: "Název",
      company: "Firmy",
      exerciseExecutionChanged: "Způsob provádění byl upraven",
      exerciseExecutionChangedFail: "Nepodařilo se upravit způsoby provádění",
      exerciseExecutionAdded: "Nový způsob provádění byl vytvořen",
      exerciseExecutionAddFail: "Nepodařilo se vytvořit nový způsob provádění",
    },
    table: {
      header: {
        name: "Název",
        company: "Firmy",
        actions: "Akce",
      },
      deleteExerciseExecutionQuestion:
        "Opravdu chcete odstranit způsob provádění <strong>{exerciseExecution}</strong>?",
      searchExerciseExecutions: "Hledat způsob provádění",
      exerciseExecutionDeleted:
        "Způsob provádění {exerciseExecution} byl odstraněn",
      exerciseExecutionsDeleted: "Způsoby provádění byly odstraněny",
      noRecords: "Nebyly nalezeny žádné způsoby provádění",
    },
  },
};

export const companyBankAccountCs = {
  companyBankAccounts: {
    toolTips: {
      edit: "Upravit bankovní účet {account}",
      delete: "Odstranit bankovní {account}",
      lastSyncState: "Poslední synchronizace {date}",
    },
    editTitle: "Upravit bankovní účet",
    newTitle: "Nový bankovní účet",
    addCompanyBankAccount: "Přidat bankovní účet",
    errors: {
      notFound: "Bankovní účet id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit bankovní účet id {id}",
      currencyExists: "Bankovní účet pro měnu `{currency_id}` již existuje",
      commonGET_COMPANY_BANK_ACCOUNT_BY_ID:
        "Chyba při získávání dat o bankovním účtu",
      commonDELETE_COMPANY_BANK_ACCOUNT_BY_ID:
        "Chyba při odstraňování bankovního účtu",
      commonDELETE_COMPANY_BANK_ACCOUNTS:
        "Nepodařilo se odstranit bankovní účet",
      cantGetCompanyBankAccounts: "Chyba při získávání dat o bankovních účtech",
    },
    log: {
      table: {
        date: "Datum",
        type: "Stav",
        text: "Text",
      },
    },
    form: {
      company: "Firma",
      currency: "Měna",
      bank_name: "Název banky",
      account: "Č.účtu",
      iban: "IBAN",
      swift_bic: "SWIFT/BIC",
      remote_access_data: "Nastavení vzdáleného přistupu",
      companyBankAccountChanged: "Bankovní účet byl upraven",
      companyBankAccountChangedFail: "Nepodařilo se upravit bankovní účet",
      companyBankAccountAdded: "Nový bankovní účet byl vytvořen",
      companyBankAccountAddFail: "Nepodařilo se vytvořit nový bankovní účet",
    },
    modal: {
      titleAdd: "Přiřadit nový bankovní účet",
      titleUpdate: "Upravit bankovní účet `{account}`",
    },
    table: {
      header: {
        company: "Firma",
        currency: "Měna",
        bank_name: "Název banky",
        account: "č.účtu",
        syncState: "API přístup",
        iban: "IBAN",
        actions: "Akce",
      },
      runSync: "Spustit synchronizaci",
      getSyncLog: "Zobrazit log poslední synchronizace",
      deleteCompanyBankAccountQuestion:
        "Opravdu chcete odstranit bankovní účet <strong>{account}</strong>?",
      searchCompanyBankAccounts: "Hledat bankovní účet",
      companyBankAccountDeleted: "Bankovní účet {account} byl odstraněn",
      companyBankAccountsDeleted: "Bankovní účty byly odstraněny",
      noRecords: "Nebyly nalezeny žádné bankovní účty",
    },
  },
};

export const companySk = {
  companies: {
    unassigned: "Bez firmy",
    toolTips: {
      edit: "Upraviť firmu {company}",
      delete: "Odstrániť firmu {company}",
    },
    editTitle: "Upraviť firmu",
    newTitle: "Nová firma",
    addCompany: "Pridať firmu",
    errors: {
      notFound: "Firma s id {id} nebola nájdená",
      notYourRole: "Nemáte práva zobraziť firmu s id {id}",
      nameExists: "Firma s názvom `{name}` už existuje",
      commonGET_COMPANY_BY_ID: "Chyba pri získavaní údajov o firme",
      commonDELETE_COMPANY_BY_ID: "Chyba pri odstraňovaní firmy",
      commonDELETE_COMPANIES: "Nepodarilo sa odstrániť firmy",
      cantGetCompanies: "Chyba pri získavaní údajov o firmách",
      logoUploadFailed: "Nepodarilo sa nahrať obrázok loga",
    },
    form: {
      name: "Názov",
      companyChanged: "Firma bola upravená",
      companyChangedFail: "Nepodarilo sa upraviť firmu",
      companyAdded: "Nová firma bola vytvorená",
      companyAddFail: "Nepodarilo sa vytvoriť novú firmu",
      logo: "Logo",
      trainingInfo: "Info k tréningu",
      ad_banner: "Reklamný banner",
      diagnostics: "Diagnostiky",
      changeLogo: "Zmeniť logo",
      addLogo: "Pridať logo",
      removeLogo: "Odstrániť logo",
      allowedFileTypes: "Povolené typy obrázkov",
      invoiceDetails: "Fakturačné údaje",
      invoiceCompanies: "Možnosť fakturácie aj na tieto firmy",
      type: "Typ",
      types: { company: "Firma", organization: "Organizácia" },
      email: "Email",
      country: "Krajina",
      street: "Ulica",
      city: "Mesto",
      zip: "PSČ",
      in: "IČ",
      tin: "DIČ",
    },
    table: {
      header: {
        name: "Názov",
        diagnostics: "Diagnostiky",
        type: "Typ",
        actions: "Akcie",
      },
      deleteCompanyQuestion:
        "Naozaj chcete odstrániť firmu <strong>{company}</strong>?",
      searchCompanies: "Hľadať firmy",
      companyDeleted: "Firma {company} bola odstránená",
      companiesDeleted: "Firmy boli odstránené",
      noRecords: "Neboli nájdené žiadne firmy",
    },
  },
};

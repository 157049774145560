import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type { Language } from "@/store/interfaces/Language";
import store from "@/store";

export const getLanguageFlagById = (id: number): string => {
  return store.getters.getLanguages.filter((language: Language) => {
    return language.id === id;
  })[0]?.flag;
};

export const getLanguageFlagByCode = (code: string): string => {
  return store.getters.getLanguages.filter((language: Language) => {
    return language.code === code;
  })[0]?.flag;
};
export const getLanguageByCode = (code: string): string => {
  return store.getters.getLanguages.filter((language: Language) => {
    return language.code === code;
  })[0];
};

export const getLanguageCodeById = (id: number): string => {
  return store.getters.getLanguages.filter((language: Language) => {
    return language.id === id;
  })[0]?.code;
};

@Module
export default class LanguageModule extends VuexModule {
  redirectTo = "";
  loadedLanguage: Language = {} as Language;
  Languages: Language[] = [];

  get getLoadedLanguage(): Language {
    return { ...this.loadedLanguage } as Language;
  }

  /**
   * Retreive current loaded languages
   */
  get getLanguages(): Language[] {
    return this.Languages;
  }

  /**
   * Retreive language flag by Id
   */

  @Mutation
  [Mutations.SET_LOADED_LANGUAGE](Language: Language) {
    this.loadedLanguage = Language;
  }

  @Mutation
  [Mutations.SET_LANGUAGES](data) {
    this.Languages = data;
  }

  @Action
  [Actions.LOAD_LANGUAGES](payload) {
    ApiService.setHeader();
    return new Promise<Language[]>((resolve, reject) => {
      ApiService.post("languages", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LANGUAGES, data["languages"]);
            resolve(data["languages"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "languages.errors.cantGetLanguages"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_LANGUAGE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Language>((resolve) => {
        const Language = {} as Language;
        this.context.commit(Mutations.SET_LOADED_LANGUAGE, Language);
        resolve(Language);
      });
    }
    return new Promise<Language>((resolve, reject) => {
      ApiService.get(`language/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_LANGUAGE,
              data["language"]
            );
            resolve(data["language"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "languages.errors.commonGET_LANGUAGE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_LANGUAGE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Language>((resolve, reject) => {
      ApiService.delete(`language/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_LANGUAGE,
              data["language"]
            );
            resolve(data["language"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "languages.errors.commonDELETE_LANGUAGE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_LANGUAGE](Language: Language) {
    const payload = JSON.parse(JSON.stringify(Language));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`language/${Language.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_LANGUAGE,
              data["language"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Language.id,
              name: Language.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "languages.form.languageChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_LANGUAGE](Language: Language) {
    const payload = JSON.parse(JSON.stringify(Language));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`language`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_LANGUAGE,
              data["language"]
            );
            resolve(data["language"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Language.id,
              name: Language.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "languages.form.languageAddFail"
          );
          reject();
        });
    });
  }
}

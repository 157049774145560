export const bodyPartEn = {
  bodyParts: {
    title: "Body parts",
    toolTips: {
      edit: "Edit body part {bodyPart}",
      delete: "Delete body part {bodyPart}",
    },
    editTitle: "Edit body part",
    newTitle: "New body part",
    addBodyPart: "Add body part",
    errors: {
      notFound: "Body part {id} not found",
      notYourRole: "Cant access data for body part {id}",
      nameExists: "Body part named `{name}` already exists",
      commonGET_BODY_PART_BY_ID: "Error retrieving body part data",
      commonDELETE_BODY_PART_BY_ID: "Error deleting body part",
      commonDELETE_BODY_PARTS: "Error deleting body parts",
      cantGetBodyParts: "Failed to fetch body parts data",
    },
    form: {
      addAccesory: "Add body part",
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      code: "Code",
      description: "Description",
      image: "Image",
      icon: "Icon",
      company: "Companies",
      programs: "Programs",
      bodyPartChanged: "Body part was stored",
      bodyPartChangedFail: "Failed to store body part",
      bodyPartAdded: "New body part was created",
      bodyPartAddFail: "Failed to add new body part",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        icon: "Icon",
        company: "Companies",
        actions: "Actions",
      },
      deleteBodyPartQuestion:
        "Do you really want to delete  body part <strong>{bodyPart}</strong>?",
      searchBodyParts: "Search body parts",
      bodyPartDeleted: "BodyPart {bodyPart} was deleted",
      bodyPartsDeleted: "Body parts were deleted",
      noRecords: "No body parts found",
    },
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { ChatMessage, MessageCount } from "@/store/interfaces/ChatMessage";

@Module
export default class ChatMessageModule extends VuexModule {
  redirectTo = "";
  ChatMessages: ChatMessage[] = [];
  ChatMessagesHistory: ChatMessage[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;
  messageCounts: MessageCount[] = [];
  smsApiBalance = 0;
  chatOpened = false;
  inChatTab = false;

  get getSmsApiBalance(): number {
    return this.smsApiBalance;
  }

  get getMessageCounts(): MessageCount[] {
    return this.messageCounts;
  }

  /**
   * Retreive current loaded ChatMessages
   */
  get getChatMessagesHistory(): ChatMessage[] {
    return this.ChatMessagesHistory;
  }

  /**
   * Retreive current loaded ChatMessages
   */
  get getChatMessages(): ChatMessage[] {
    return this.ChatMessages;
  }

  /**
   * Get last pagination setup
   */
  get getChatMessagesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getChatMessagesSorting() {
    return this.sorting;
  }

  get isChatOpened() {
    return this.chatOpened;
  }

  get insideChatTab() {
    return this.inChatTab;
  }

  @Mutation
  [Mutations.SET_SMS_BALANCE](balance) {
    this.smsApiBalance = balance;
  }

  @Mutation
  [Mutations.OPEN_CHAT](tab = false) {
    if (tab) {
      this.inChatTab = true;
    } else {
      this.chatOpened = true;
    }
  }

  @Mutation
  [Mutations.CLOSE_CHAT](tab = false) {
    if (tab) {
      this.inChatTab = false;
    } else {
      this.chatOpened = false;
    }
  }

  @Mutation
  [Mutations.SET_CHAT_MESSAGES](data) {
    this.ChatMessages = data.data;
  }

  @Mutation
  [Mutations.SET_CHAT_MESSAGES_HISTORY](data) {
    this.ChatMessagesHistory = data.data;
  }

  @Mutation
  [Mutations.SET_CHAT_MESSAGES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_CHAT_MESSAGES_SORTING](data) {
    this.sorting = data;
  }
  @Mutation
  [Mutations.SET_MESSAGE_COUNTS](count) {
    this.messageCounts = count;
  }
  //get-messages-history

  @Action
  [Actions.GET_MESSAGES_HISTORY](payload) {
    ApiService.setHeader();
    return new Promise<ChatMessage[]>((resolve, reject) => {
      ApiService.post("chat/get-messages-history", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_CHAT_MESSAGES_HISTORY,
              data["messages"]
            );
            this.context.commit(
              Mutations.SET_CHAT_MESSAGES_PAGINATION,
              data["messages"].pagination
            );
            resolve(data["messages"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetMessages"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CONVERSATION](payload) {
    ApiService.setHeader();
    return new Promise<ChatMessage[]>((resolve, reject) => {
      ApiService.post("chat/get-conversation", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CHAT_MESSAGES, data["messages"]);
            this.context.commit(
              Mutations.SET_CHAT_MESSAGES_PAGINATION,
              data["messages"].pagination
            );
            resolve(data["messages"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetMessages"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_MESSAGE](message: ChatMessage) {
    ApiService.setHeader();
    return new Promise<ChatMessage>((resolve, reject) => {
      ApiService.post("chat/add-message", { ...message })
        .then(({ data }) => {
          if (data.success && data.message) {
            resolve(data.message as ChatMessage);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantAddMessages"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_BULK_MESSAGE](payload: {
    message: ChatMessage;
    recipients: [];
  }) {
    ApiService.setHeader();
    return new Promise<boolean>((resolve, reject) => {
      ApiService.post("chat/send-bulk-message", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(true);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantSendBulkMessage"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.READ_ALL_MESSAGES](userFromId: number) {
    ApiService.setHeader();
    return new Promise<boolean>((resolve, reject) => {
      ApiService.get("chat/read-all-messages/" + userFromId)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.messageCounts);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantMarkMessages"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.MARK_AS_UNREAD](messageId: number) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("chat/mark-as-unread/" + messageId)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.messageCounts);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantMarkMessages"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SMS_BALANCE]() {
    ApiService.setHeader();
    return new Promise<boolean>((resolve, reject) => {
      ApiService.get("chat/get-vonage-balance")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_SMS_BALANCE, data.balance);
            resolve(data.balance);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetSmsBalance"
          );
          reject();
        });
    });
  }
}

export const sportEn = {
  sports: {
    toolTips: {
      edit: "Edit sport {sport}",
      delete: "Delete sport {sport}",
    },
    editTitle: "Edit sport",
    newTitle: "New sport",
    addSport: "Add sport",
    errors: {
      notFound: "Sport {id} not found",
      notYourRole: "Cant access data for sport {id}",
      nameExists: "Sport named `{name}` already exists",
      commonGET_SPORT_BY_ID: "Error retrieving sport data",
      commonDELETE_SPORT_BY_ID: "Error deleting sport",
      commonDELETE_SPORTS: "Error deleting sports",
      cantGetSports: "Failed to fetch sports data",
    },
    form: {
      name: "Name",
      company: "Company",
      sportChanged: "Sport was stored",
      sportChangedFail: "Failed to store sport",
      sportAdded: "New sport was created",
      sportAddFail: "Failed to add new sport",
    },
    table: {
      header: {
        name: "Name",
        company: "Company",
        actions: "Actions",
      },
      deleteSportQuestion:
        "Do you really want to delete  sport <strong>{sport}</strong>?",
      searchSports: "Search sports",
      sportDeleted: "Sport {sport} was deleted",
      sportsDeleted: "Sports were deleted",
      noRecords: "No sports found",
    },
  },
};

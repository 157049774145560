import { EmailTemplates } from "@/store/enums/EmailTemplateEnums";

export const mailEn = {
  email_templates: {
    [EmailTemplates.COMMON]: "Default",
    [EmailTemplates.NEWSLETTER_BIRTHDAY]: "Newsletter - birthday",
  },
  mails: {
    passwordReset: {
      subject: "isenses: Password reset",
      body: {
        title: "Password Reset",
        text: "If you`ve lost your password or wish to reset it, use the link bellow to get started.",
        button: {
          text: "Reset your password",
          link: "password-change/",
        },
        textAfter:
          "If you did not request a password reset, you can safely ignore this email. Only a person with access to your email can reset your account password.",
      },
    },
    inviteUser: {
      subject: "isenses: Invitation",
      body: {
        title: "Welcome to isenses",
        text: "We have just created an account for you in the isenses system. Please set your password and then log in.",
        button: {
          text: "Set Password",
          link: "password-change/",
        },
        textAfter:
          "If you are not interested in registering in the isenses system, ignore this email. Only the person with access to your e-mail box can set a password.",
      },
    },
  },
};

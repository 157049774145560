import React, { useState, useEffect } from "react";

// Testování reakční doby

const ReactionTimeGame = ({ onGameEnd }) => {
  const [gridColor, setGridColor] = useState("blue"); // Initial grid color
  const [startTime, setStartTime] = useState(null);
  const [clickTimes, setClickTimes] = useState([]); // Array to store click times for each round
  const [showStartButton, setShowStartButton] = useState(true);
  const [waitingForClick, setWaitingForClick] = useState(false);

  const [currentRound, setCurrentRound] = useState(0); // Current round
  const totalRounds = 5; // Total number of rounds

  useEffect(() => {
    if (currentRound === totalRounds && !waitingForClick) {
      // Game ends when all rounds are completed and not waiting for a click
      onGameEnd({
        games: [
          {
            id: "ReactionTimeGame",
            clickTimes: clickTimes,
          },
        ],
      });
    } else if (!showStartButton && currentRound < totalRounds) {
      // Proceed with the next round
      const delayBeforeGreen = 3000; // Wait 3 seconds before turning green
      setTimeout(() => {
        setGridColor("green"); // Change color to indicate start

        const delayBeforeRed = 1000 + Math.random() * 2000; // Random interval between 1s and 3s
        setTimeout(() => {
          setGridColor("red"); // Change color to red as a signal for the user to click
          setStartTime(performance.now());
          setWaitingForClick(true);
        }, delayBeforeRed);
      }, delayBeforeGreen);
    }
  }, [showStartButton, onGameEnd, clickTimes, currentRound]);

  const startGame = () => {
    setShowStartButton(false);
    setCurrentRound(0);
  };

  const makeGuess = () => {
    if (waitingForClick) {
      const endTime = performance.now();
      const reactionTime = endTime - startTime;
      setClickTimes([...clickTimes, reactionTime]);
      setWaitingForClick(false);
      setGridColor("blue");

      if (currentRound < totalRounds) {
        setCurrentRound(currentRound + 1);
      }
    }
  };

  const GameHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginBottom: "24px",
      }}
    >
      <div>
        Round: {currentRound + 1} / {totalRounds}
      </div>
    </div>
  );

  const GameGrid = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "24px",
        marginBottom: "24px",
      }}
    >
      {!showStartButton && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(5, 1fr)`,
            gap: "10px",
          }}
        >
          {[...Array(25)].map((_, index) => (
            <div
              key={index}
              onClick={makeGuess}
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: gridColor,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "black",
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );

  const GameFooter = () => (
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "24px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showStartButton && (
          <div style={{ padding: "10px" }}>
            Začátek pokusu je definován zelenou. Mezičas, tedy po kliku, je
            označen modrou. Uživatel kliká při změně na červenou.{" "}
          </div>
        )}
        {showStartButton && (
          <button
            onClick={startGame}
            style={{
              padding: "10px",
              width: "120px",
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor: "#368f58",
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
              border: "none",
            }}
          >
            Start
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <GameHeader />
      <GameGrid />
      <GameFooter />
    </div>
  );
};

export default ReactionTimeGame;

export const companyCs = {
  companies: {
    unassigned: "Bez firmy",
    toolTips: {
      edit: "Upravit firmu {company}",
      delete: "Odstranit firmu {company}",
    },
    editTitle: "Upravit firmu",
    newTitle: "Nová firma",
    addCompany: "Přidat firmu",
    errors: {
      notFound: "Firma id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit firmu id {id}",
      nameExists: "Firma s nazvem `{name}` již existuje",
      commonGET_COMPANY_BY_ID: "Chyba při získávání dat o firmě",
      commonDELETE_COMPANY_BY_ID: "Chyba při odstraňování firmy",
      commonDELETE_COMPANIES: "Nepodařilo se odstranit firmy",
      cantGetCompanies: "Chyba při získávání dat o firmách",
      logoUploadFailed: "Nepodařilo se nahrát obrázek loga",
    },
    form: {
      name: "Název",
      companyChanged: "Firma byla upravena",
      companyChangedFail: "Nepodařilo se upravit firmu",
      companyAdded: "Nová firma byla vytvořena",
      companyAddFail: "Nepodařilo se vytvořit novou firmu",
      logo: "Logo",
      trainingInfo: "Info k tréninku",
      ad_banner: "Reklamní banner",
      diagnostics: "Diagnostiky",
      changeLogo: "Změnit logo",
      addLogo: "Přidat logo",
      removeLogo: "Odstranit logo",
      allowedFileTypes: "Povolené typy obrázků",
      invoiceDetails: "Fakturační údaje",
      invoiceCompanies: "Možnost fakturace i na tyto firmy",
      type: "Typ",
      types: { company: "Firma", organization: "Organizace" },
      email: "Email",
      country: "Země",
      street: "Ulice",
      city: "Obec",
      zip: "PSČ",
      in: "IČ",
      tin: "DIČ",
    },
    table: {
      header: {
        name: "Název",
        diagnostics: "Diagnostiky",
        type: "Typ",
        actions: "Akce",
      },
      deleteCompanyQuestion:
        "Opravdu chcete odstranit firmu <strong>{company}</strong>?",
      searchCompanies: "Hledat firmy",
      companyDeleted: "Firma {company} byla odstraněna",
      companiesDeleted: "Firmy byly odstraněny",
      noRecords: "Nebyly nalezeny žádné firmy",
    },
  },
};

export const toolEn = {
  tools: {
    title: "Tools",
    toolTips: {
      edit: "Edit tool {tool}",
      delete: "Delete tool {tool}",
      gameplays: "Remaining daily attempts {counts}",
    },
    editTitle: "Edit tool",
    newTitle: "New tool",
    addTool: "Add tool",
    errors: {
      notFound: "Tool {id} not found",
      notYourRole: "Cant access data for tool {id}",
      nameExists: "Tool named `{name}` already exists",
      commonGET_TOOL_BY_ID: "Error retrieving tool data",
      commonDELETE_TOOL_BY_ID: "Error deleting tool",
      commonDELETE_TOOLS: "Error deleting tools",
      cantGetTools: "Failed to fetch tools data",
      cantStoreGameResults: "Failed to store game result",
      cantGetGameResults: "Error fetching user game data",
    },
    settings: {
      title: "Settings",
      keys: {
        size: "Size",
        bpm: "BPM",
        volume: "Volume",
        amountOfCharacters: "Amount Of Characters",
        storeResults: "Store Results",
        rows: "Rows",
        level: "Level",
        cols: "Cols",
        fieldCount: "Field count",
        customDescription: "Description",
        dailyAttempts: "Attempts / day",
      },
    },
    types: {
      game: "Game",
      measurement: "Measurement",
      other: "Other",
    },
    form: {
      addTool: "Add tool",
      name: "Name",
      code: "Code",
      client_name: "Name for client",
      type: "Type",
      settings: "Settings",
      description: "Description",
      company: "Companies",
      toolChanged: "Tool was stored",
      toolChangedFail: "Failed to store tool",
      toolAdded: "New tool was created",
      toolAddFail: "Failed to add new tool",
      gameResultStored: "Game result stored",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        type: "Type",
        company: "Companies",
        actions: "Actions",
      },
      deleteToolQuestion:
        "Do you really want to delete  tool <strong>{tool}</strong>?",
      searchTools: "Search tools",
      toolDeleted: "Tool {tool} was deleted",
      toolsDeleted: "Tools were deleted",
      noRecords: "No tools found",
    },
    modules: {
      export_diagnostics: {
        form: {
          dateFrom: "Date from",
          dateTo: "Date to",
        },
        export: "Export",
        result: "Result",
      },
      import_diagnostics: {
        form: {
          file: "Import file (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Task 1",
            task2: "Task 2",
            task3: "Task 3",
            task4: "Task 4",
            task5: "Task 5",
            dominance_eye: "Eye",
            dominance_hand: "Hand",
            dominance_leg: "Leg",
            dominance_ear: "Rhythm",
            eyesight_flexibility_both_eyes: "Flexibility",
            eyesight_accommodation_right_eye: "Accommodation R",
            eyesight_accommodation_left_eye: "Accommodation L",
            error: "Error",
          },
        },
        import: "Import",
        new_import: "New import",
        results: "Import results",
        status: "State",
        validValues: "Valid values",
        errorValues: "Error values",
        validations: {
          emailExists: "User with email {email} already exists",
        },
        result: {
          reasons: {
            value_inserted: "Lead created",
            value_not_inserted: "Failed to insert value",
            value_exists: "Value for provided day already exists",
          },
        },
      },
      import_leads: {
        form: {
          file: "Import file (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Task 1",
            task2: "Task 2",
            task3: "Task 3",
            task4: "Task 4",
            task5: "Task 5",
            error: "Error",
          },
        },
        import: "Import",
        new_import: "New import",
        results: "Import results",
        status: "State",
        validValues: "Valid values",
        errorValues: "Error values",
        type: "Type",
        date: "Date of measurement",
        valueTypes: {
          s2board_measurements: "SensesBoard measurements",
        },
        validations: {
          s2board_measurements: {
            email_not_found: "User with email {email} not found",
          },
        },
        result: {
          reasons: {
            value_inserted: "Value inserted",
            value_not_inserted: "Lead was not created",
            value_exists: "Value for provided day already exists",
          },
        },
      },
      sleep_diary: {
        title: "Sleep diary",
        days: {
          1: "Mo",
          2: "Tu",
          3: "We",
          4: "Th",
          5: "Fr",
          6: "Sa",
          7: "Su",
        },
        rows: {
          bed_time_at: {
            title: "Go to bed",
            note: "I go to bed but I do not have to sleep yet (e.g. I watch TV, I read)",
          },
          lights_off_at: {
            title: "Lights off",
            note: "The time when I am ready to fall asleep",
          },
          fall_asleep_in: {
            title: "Duration of falling asleep",
            note: "The time I fell asleep",
          },
          wake_up_at: {
            title: "Awakening time",
            note: "The time I woke up (I can still stay in bed)",
          },
          of_bed_at: {
            title: "Getting up time",
            note: "The time I got out of bed",
          },
          awake_moments_count: {
            title: "Number of night awakenings",
            note: "How many times I woke up in the night and I remember it (e.g. I could not sleep afterward)",
          },
          time_in_bed: {
            title: "Time spent in bed",
            note: "The time between going to bed and getting up",
          },
        },
      },
    },
  },
};

// ColorWordMatchGame.js

import React, { useState, useEffect, useRef } from "react";
import Color from "../../Components/Color";

const colorNameToHexMap = {
  Blue: Color.Blue,
  Orange: Color.Orange,
  Red: Color.Red,
  Teal: Color.Teal,
  LightBlue: Color.LightBlue,
};

const getRandomColorName = () => {
  const colorNames = Object.keys(colorNameToHexMap);
  return colorNames[Math.floor(Math.random() * colorNames.length)];
};

const ColorWordMatchGame = ({ onGameEnd }) => {
  const [leftColorName, setLeftColorName] = useState(getRandomColorName());
  const [rightColorName, setRightColorName] = useState(getRandomColorName());
  const [rightColorText, setRightColorText] = useState(getRandomColorName());
  const [feedback, setFeedback] = useState(Color.LightGray);
  const [score, setScore] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [showStartButton, setShowStartButton] = useState(true);

  const totalTime = 60;
  const [timer, setTimer] = useState(totalTime);
  const timerIdRef = useRef(null);

  const nextRound = () => {
    setLeftColorName(getRandomColorName());
    setRightColorName(getRandomColorName());
    setRightColorText(getRandomColorName());
    setFeedback(Color.LightGray);
  };

  useEffect(() => {
    if (timer > 0) {
      timerIdRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerIdRef.current);
      onGameEnd({
        games: [
          {
            id: "ColorWordMatchGame",
            score,
            right: rightAnswers,
            wrong: wrongAnswers,
          },
        ],
      });
    }

    return () => clearInterval(timerIdRef.current);
  }, [timer]);

  const handleStartGame = () => {
    setShowStartButton(false);
    setTimer(totalTime);
    setScore(0);
    setRightAnswers(0);
    setWrongAnswers(0);
    setFeedback(Color.LightGray);
    nextRound();
  };

  const handleAnswer = (isCorrect) => {
    if (feedback === Color.LightGray) {
      if (isCorrect) {
        setScore(score + 1);
        setRightAnswers(rightAnswers + 1);
        setFeedback(Color.Teal);
      } else {
        setWrongAnswers(wrongAnswers + 1);
        setFeedback(Color.Red);
      }

      setTimeout(() => {
        setFeedback(Color.LightGray);
        nextRound();
      }, 350);
    }
  };

  return (
    <div style={{ textAlign: "center", userSelect: "none" }}>
      <h1>Barva - Text</h1>
      <h2>
        Odpovídá barva textu napravo<br></br> názvu barvy nalevo?
      </h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "32px",
          marginBottom: "10px",
          borderRadius: "5px",
          backgroundColor: feedback,
          padding: "15px",
          minHeight: "50px",
        }}
      >
        <span
          style={{
            flex: 1,
            color: "black",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {!showStartButton && leftColorName}
        </span>
        <span
          style={{
            flex: 1,
            color: colorNameToHexMap[rightColorName],
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {!showStartButton && rightColorText}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "32px",
        }}
      >
        <span
          style={{
            flex: 1,
            fontWeight: "bold",
            textTransform: "uppercase",
            borderRadius: "5px",
            backgroundColor: Color.LightGray,
            marginBottom: "10px",
            padding: "15px",
            marginRight: "10px",
          }}
          onClick={() => handleAnswer(leftColorName !== rightColorName)}
        >
          Ne
        </span>
        <span
          style={{
            flex: 1,
            fontWeight: "bold",
            textTransform: "uppercase",
            borderRadius: "5px",
            backgroundColor: Color.LightGray,
            marginBottom: "10px",
            padding: "15px",
          }}
          onClick={() => handleAnswer(leftColorName === rightColorName)}
        >
          Ano
        </span>
      </div>

      {showStartButton && <button onClick={handleStartGame}>Start Game</button>}

      {!showStartButton && <h2>Zbývá čas: {timer}</h2>}
    </div>
  );
};

export default ColorWordMatchGame;

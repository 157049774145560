export const paymentEn = {
  payments: {
    title: "Payments",
    toolTips: {
      edit: "Edit payment {payment}",
      delete: "Delete payment {payment}",
      show: "Show payment {payment}",
      unlink: "Unlink payment",
    },
    editTitle: "Edit payment",
    newTitle: "New payment",
    showTitle: "Show payment",
    addPayment: "Add payment",
    errors: {
      notFound: "Payment {id} not found",
      notYourRole: "Cant access data for payment {id}",
      commonGET_PAYMENT_BY_ID: "Error retrieving payment data",
      commonDELETE_PAYMENT_BY_ID: "Error deleting payment",
      commonDELETE_PAYMENTS: "Error deleting payments",
      cantGetPayments: "Failed to fetch payments data",
    },
    form: {
      addAccesory: "Add payment",
      paymentChanged: "Payment was stored",
      paymentChangedFail: "Failed to store payment",
      paymentAdded: "New payment was created",
      paymentAddFail: "Failed to add new payment",
      date: "Date ",
      senderAccountNumber: "Counterparty",
      receiverAccountNumber: "Account",
      amount: "Amount",
      variable_symbol: "Var. symbol",
      constant_symbol: "Const. symbol",
      specific_symbol: "Spec. symbol",
      user_message: "User message",
      comment: "Comment",
      transaction_type: "Transaction type",
    },
    table: {
      header: {
        actions: "Actions",
        date: "Date ",
        senderAccountNumber: "Counterparty",
        receiverAccountNumber: "Account",
        amount: "Amount",
        variable_symbol: "Var. symbol",
        transaction_type: "Transaction type",
      },
      deletePaymentQuestion:
        "Do you really want to delete payment <strong>{payment}</strong>?",
      searchPayments: "Search payments",
      paymentDeleted: "Payment {payment} was deleted",
      paymentsDeleted: "Payments were deleted",
      noRecords: "No payments found",
    },
  },
};

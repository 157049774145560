export const registrationTokenEn = {
  registrationTokens: {
    toolTips: {
      edit: "Edit registration token {token}",
      delete: "Delete registration token {token}",
      link: "Show link for registrační kód {token}",
    },
    editTitle: "Edit registration token",
    newTitle: "New registration token",
    addRegistrationToken: "Add registration token",
    errors: {
      notFound: "Registration token id {id} not found",
      notYourRole: "Cant access data for registration token id {id}",
      nameExists: "Registration token `{token}` already exists",
      registrationTokenAddFail: "Failed to create registration token",
      commonGET_REGISTRATION_TOKEN_BY_ID:
        "Error retrieving registration token data",
      commonDELETE_REGISTRATION_TOKEN_BY_ID: "Error deletin registration token",
      commonDELETE_REGISTRATION_TOKENS: "Error deleting registration tokens",
      cantGetRegistrationTokens: "Failed to fetch registration tokens data",
    },
    form: {
      token: "Token",
      company: "Company",
      program: "Product",
      userGroup: "User group",
      events: "Events",
      totalUses: "Total uses",
      registrationTokenChanged: "Registration token was stored",
      registrationTokenChangedFail: "Failed to store registration token",
      registrationTokenAdded: "New registration token was created",
      registrationTokenAddFail: "Failed to add new registration token",
    },
    modal: {
      titleAdd: "Add new registration token",
      titleUpdate: "Edit registration token `{token}`",
      titleLink: "Link for registration token `{token}`",
    },
    table: {
      header: {
        token: "Token",
        company: "Company",
        program: "Product",
        userGroup: "User group",
        events: "Events",
        actions: "Actions",
        uses: "Usage",
      },
      deleteRegistrationTokenQuestion:
        "Do you really want to delete registration token <strong>{token}</strong>?",
      searchRegistrationTokens: "Search registration token",
      registrationTokenDeleted:
        "Registration token {registrationToken} was deleted",
      registrationTokensDeleted: "Registration tokens were deleted",
      noRecords: "No registration tokens found",
    },
    events: {
      S2boardEnable: {
        title: "Activate SBoard",
      },
      InOfficeEnable: {
        title: "Activate in office diag.",
      },
      EyeStabilityEnable: {
        title: "Activate eye stability diag.",
      },
      EyeFlexibilityEnable: {
        title: "Activate eye flexibility diag.",
      },
    },
  },
};

<template>
  <span :class="`btn file-content-icon file-icon--type-${file.type}`"></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { File } from "@/store/interfaces/File";

export default defineComponent({
  name: "file-content-icon",
  components: {},
  props: {
    file: {
      required: true,
      type: Object as () => File,
    },
  },
  setup() {
    return {};
  },
});
</script>

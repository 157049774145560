export const exerciseExecutionEn = {
  exerciseExecutions: {
    title: "Exercise Executions",
    toolTips: {
      edit: "Edit exercise Execution {exerciseExecution}",
      delete: "Delete exercise Execution {exerciseExecution}",
    },
    editTitle: "Edit exercise Execution",
    newTitle: "New exercise Execution",
    addExerciseExecution: "Add exercise Execution",
    errors: {
      notFound: "Exercise Execution {id} not found",
      notYourRole: "Cant access data for exercise Execution {id}",
      nameExists: "Exercise Execution named `{name}` already exists",
      commonGET_EXERCISE_EXECUTION_BY_ID:
        "Error retrieving exercise Execution data",
      commonDELETE_EXERCISE_EXECUTION_BY_ID:
        "Error deleting exercise Execution",
      commonDELETE_EXERCISE_EXECUTIONS: "Error deleting exercise Executions",
      cantGetExerciseExecutions: "Failed to fetch exercise Executions data",
    },
    form: {
      addAccesory: "Add exercise Execution",
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      code: "Code",
      description: "Description",
      image: "Image",
      icon: "Icon",
      company: "Companies",
      programs: "Programs",
      exerciseExecutionChanged: "Exercise Execution was stored",
      exerciseExecutionChangedFail: "Failed to store exercise Execution",
      exerciseExecutionAdded: "New exercise Execution was created",
      exerciseExecutionAddFail: "Failed to add new exercise Execution",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        icon: "Icon",
        company: "Companies",
        actions: "Actions",
      },
      deleteExerciseExecutionQuestion:
        "Do you really want to delete  exercise Execution <strong>{exerciseExecution}</strong>?",
      searchExerciseExecutions: "Search exercise Executions",
      exerciseExecutionDeleted:
        "ExerciseExecution {exerciseExecution} was deleted",
      exerciseExecutionsDeleted: "Exercise Executions were deleted",
      noRecords: "No exercise Executions found",
    },
  },
};

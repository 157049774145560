export const fileCs = {
  files: {
    selector: {
      toolTips: {
        upload: "Nahrát",
        select: "Vybrat ze seznamu",
        cancel: "Odebrat",
        preview: "Zobrazit",
      },
    },
    toolTips: {
      edit: "Upravit soubor {file}",
      delete: "Odstranit soubor {file}",
      preview: "Náhled souboru {file}",
    },
    editTitle: "Upravit soubor",
    newTitle: "Nový soubor",
    addFile: "Přidat soubor",
    fileSelector: {
      title: "Vyberte soubor pro vložení do textu",
    },
    errors: {
      notFound: "Soubor id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit soubor id {id}",
      nameExists: "Soubor s nazvem `{name}` již existuje",
      commonGET_FILE_BY_ID: "Chyba při získávání dat o souboru",
      commonDELETE_FILE_BY_ID: "Chyba při odstraňování souboru",
      commonDELETE_FILES: "Nepodařilo se odstranit soubory",
      cantGetFiles: "Chyba při získávání dat o souborech",
      fileUploadFailed: "Nepodařilo se nahrát soubor",
      diagnosticImportBatchFailed: "Chyba při importu diagnostických dat",
      resultsExportFailed: "Chyba při exportu diagnostických dat",
      leadsImportBatchFailed: "Chyba při importu leadů",
      notAllowedFiletype: "Nepovolený typ souboru",
    },
    types: {
      pdf: "PDF",
      image: "Obrázek",
      video: "Video",
      audio: "Audio",
      icon: "Ikona",
      document: "Dokument",
    },
    form: {
      name: "Název",
      company: "Firma",
      file: "Soubor",
      specializations: "Specializace",
      description: "Popis",
      language: "Jazyk",
      fileChanged: "Soubor byl upraven",
      fileChangedFail: "Nepodařilo se upravit soubor",
      fileAdded: "Nový soubor byl vytvořen",
      fileAddFail: "Nepodařilo se vytvořit nový soubor",
    },
    table: {
      header: {
        name: "Název",
        company: "Firma",
        language: "Jazyk",
        type: "Typ",
        specializations: "Specializace",
        actions: "Akce",
      },
      deleteFileQuestion:
        "Opravdu chcete odstranit soubor <strong>{file}</strong>?",
      searchFiles: "Hledat soubory",
      fileDeleted: "Soubor {file} byl odstraněn",
      filesDeleted: "Soubory byly odstraněny",
      noRecords: "Nebyly nalezeny žádné soubory",
    },
  },
};

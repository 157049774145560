export const unitSk = {
  units: {
    title: "Jednotky",
    toolTips: {
      edit: "Upraviť jednotku {unit}",
      delete: "Odstrániť jednotku {unit}",
    },
    editTitle: "Upraviť jednotku",
    newTitle: "Nová jednotka",
    addUnit: "Pridať jednotku",
    errors: {
      notFound: "Jednotka s id {id} nebola nájdená",
      notYourRole: "Nemáte práva zobraziť jednotku s id {id}",
      commonGET_UNIT_BY_ID: "Chyba pri získavaní údajov o jednotkách",
      commonDELETE_UNIT_BY_ID: "Chyba pri odstraňovaní jednotky",
      commonDELETE_UNITS: "Nepodarilo sa odstrániť jednotku",
      cantGetUnits: "Chyba pri získavaní údajov o jednotkách",
    },
    form: {
      addUnit: "Pridať jednotku",
      name: "Názov",
      type: "Typ",
      company: "Firma",
      unitChanged: "Jednotka bola upravená",
      unitChangedFail: "Nepodarilo sa upraviť jednotku",
      unitAdded: "Nová jednotka bola vytvorená",
      unitAddFail: "Nepodarilo sa vytvoriť novú jednotku",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firma",
        type: "Typ",
        actions: "Akcie",
      },
      deleteUnitQuestion:
        "Naozaj chcete odstrániť jednotku <strong>{unit}</strong>?",
      searchUnits: "Hľadať jednotku",
      unitDeleted: "Jednotka {unit} bola odstránená",
      unitsDeleted: "Jednotky boli odstránené",
      noRecords: "Neboli nájdené žiadne jednotky",
    },
    types: {
      time: "čas",
      count: "počet",
    },
  },
};

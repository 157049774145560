import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Unit } from "@/store/interfaces/Unit";

@Module
export default class UnitModule extends VuexModule {
  redirectTo = "";
  loadedUnit: Unit = {} as Unit;
  Units: Unit[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getUnitTypes(): Array<string> {
    return ["time", "count"];
  }

  get getLoadedUnit(): Unit {
    return this.loadedUnit.id
      ? this.loadedUnit
      : ({
          ...this.loadedUnit,
          ...{
            type: this.loadedUnit.type ?? "time",
            resources: this.loadedUnit.resources
              ? this.loadedUnit.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    unit_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as Unit);
  }

  /**
   * Retreive current loaded units
   */
  get getUnits(): Unit[] {
    return this.Units;
  }

  /**
   * Get last pagination setup
   */
  get getUnitsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getUnitsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_UNIT](Unit: Unit) {
    this.loadedUnit = Unit;
  }

  @Mutation
  [Mutations.SET_UNITS](data) {
    this.Units = data.data;
  }

  @Mutation
  [Mutations.SET_UNITS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_UNITS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_UNITS](payload) {
    ApiService.setHeader();
    return new Promise<Unit[]>((resolve, reject) => {
      ApiService.post("units", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_UNITS, data["units"]);
            this.context.commit(
              Mutations.SET_UNITS_PAGINATION,
              data["units"].pagination
            );
            resolve(data["units"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "units.errors.cantGetUnits");
          reject();
        });
    });
  }
  @Action
  [Actions.GET_UNIT_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Unit>((resolve) => {
        const Unit = {} as Unit;
        this.context.commit(Mutations.SET_LOADED_UNIT, Unit);
        resolve(Unit);
      });
    }
    return new Promise<Unit>((resolve, reject) => {
      ApiService.get(`unit/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_UNIT, data["unit"]);
            resolve(data["unit"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "units.errors.commonGET_UNIT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_UNIT_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Unit>((resolve, reject) => {
      ApiService.delete(`unit/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_UNIT, data["unit"]);
            resolve(data["unit"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "units.errors.commonDELETE_UNIT_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_UNIT](Unit: Unit) {
    const payload = JSON.parse(JSON.stringify(Unit));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`unit/${Unit.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_UNIT, data["unit"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Unit.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "units.form.unitChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_UNIT](Unit: Unit) {
    const payload = JSON.parse(JSON.stringify(Unit));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`unit`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_UNIT, data["unit"]);
            resolve(data["unit"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Unit.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "units.form.unitAddFail");
          reject();
        });
    });
  }
}

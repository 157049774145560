export const invoiceEn = {
  invoices: {
    linkedPaymentsModal: {
      title: "Linked payments for invoice {name}",
      noRecords: "No linked payments found",
      linkPayment: "Link a payment",
      deletePrompt:
        "Do you really want to unlink <strong>payment</strong> <strong>{payment}</strong>?",
      linkPrompt:
        "Do you really want to <strong>link</strong> payment <strong>{payment}</strong>?",
    },
    paymentSelectorModal: {
      title: "Select a payment for invoice {name} to link to",
      selectPayment: "Confirm",
    },
    paymentStates: {
      paid: "fully paid",
      "not-paid": "not paid",
      overpaid: "the amount paid is higher than the invoice value",
      underpaid: "the amount paid is lower than the invoice value",
    },
    toolTips: {
      edit: "Edit invoice {invoice}",
      delete: "Delete invoice {invoice}",
      cancel: "Cancel invoice {invoice}",
      restore: "Restore invoice {invoice}",
      clone: "Clone invoice {invoice}",
      client: "Show client for invoice {invoice}",
      download: "Download invoice pdf for {invoice}",
      sendEmail: "Send an email with an invoice {invoice} to client",
      setPaid: "Mark the {invoice} as `paid'",
      setUnPaid: "Mark invoice {invoice} as `unpaid'",
      linkedPayments:
        "Linked payments for invoice {invoice}  <br /> Payment status: <strong>{payment_state}</strong>",
    },
    editTitle: "Edit invoice",
    newTitle: "New invoice",
    addInvoice: "Add invoice",
    accountantExport: "Accounting export",
    errors: {
      notFound: "Invoice {id} not found",
      notYourRole: "Cant access data for invoice {id}",
      invoiceNumberExists: "Invoice nr `{name}` already exists",
      commonGET_INVOICE_BY_ID: "Error retrieving invoice data",
      commonGET_INVOICE_PDF_BY_ID: "Error retrieving invoice pdf",
      commonDELETE_INVOICE_BY_ID: "Error deleting invoice",
      commonCANCEL_INVOICE_BY_ID: "Error canceling invoice",
      commonDELETE_INVOICES: "Error deleting invoices",
      SEND_INVOICE_PDF_BY_ID: "Failed to send invoice",
      GET_INVOICE_PDF_BY_ID: "Failed to generate PDF invoice",
      GHANGE_INVOICE_PAID_STATUS_BY_ID:
        "Failed to edit payment status on invoice",
      cantGetInvoices: "Failed to fetch invoices data",
      cantGetNextInvoiceNumber: "Failed to generate new invoice number",
      cantGetOrganizationsByUserGroupId: "Failed to fetch client organizations",
      cantGetAccountantExport: "Failed to generate export for Accountant",
      cantGetExport: "Failed to generate export",
    },
    form: {
      number: "Number",
      company: "Company",
      organization: "Organization",
      userProgram: "Program",
      name: "Name",
      currency: "Currency",
      payment: "Payment",
      issueDate: "Date of issue",
      paymentDate: "Date of payment",
      taxDate: "Date of taxable supply",
      logo: "Logo",
      client: "Client",
      country: "Country",
      email: "Email",
      street: "Street",
      city: "City",
      zip: "Zip",
      in: "Company registration Id",
      tin: "Tax Id",
      invoiceChanged: "Invoice was stored",
      invoiceChangedFail: "Failed to store invoice",
      invoicePaymentsChangedFail: "Failed to change invoice payments",
      invoicePaymentUnlinked: "Invoice payment unlinked",
      invoicePaymentLinked: "New payment linked to invoice",
      invoiceAdded: "New invoice was created",
      invoiceSent: "Invoice was sent to `{email}`",
      invoicePaid: "The invoice has been marked as paid",
      invoiceUnPaid: "The invoice has been marked as unpaid",
      invoiceAddFail: "Failed to add new invoice",
      addItem: "Add Item",
      supplier: "Supplier",
      customer: "Customer",
      items: "Items",
      notes: "Notes",
      note_before: "Before items",
      note_after: "After items",
      bankAccount: "Bank account",
      company_bank_name: "Bank name",
      company_bank_account: "Account nr.",
      company_bank_iban: "IBAN",
      company_bank_swift_bic: "SWIFT/BIC",
      productName: "Name",
      quantity: "Quantity",
      vat_rate: "Vat rate",
      vat: "Vat",
      price: "price tax incl.",
      noItems: "You must enter at least one invoice item",
      findClient: "Search (name / surname)",
    },
    table: {
      header: {
        number: "Number",
        issueDate: "Date of issue",
        paymentDate: "Date of payment",
        company: "Company",
        organization: "Organization",
        issuer: "Issuer",
        client: "Client",
        supplier: "Supplier",
        customer: "Customer",
        totalPrice: "Total price vat incl.",
        actions: "Actions",
      },
      hideCanceled: "Hide canceled",
      showCanceled: "Show canceled",
      showAll: "Show all",
      showUnpaid: "Only unpaid",
      cancelInvoiceQuestion:
        "Do you really want to cancel invoice <strong>{invoice}</strong>?",
      restoreInvoiceQuestion:
        "Do you really want to restore invoice <strong>{invoice}</strong>?",
      deleteInvoiceQuestion:
        "Do you really want to delete invoice <strong>{invoice}</strong>?",
      sendInvoiceQuestion:
        "Do you really want to send invoice <strong>{invoice}</strong> to <strong>{email}</strong>?",
      searchInvoices: "Search invoices",
      invoiceDeleted: "Invoice {invoice} was deleted",
      invoiceCanceled: "Invoice {invoice} was canceled",
      invoiceRestored: "Invoice {invoice} was restored",
      invoicesDeleted: "Invoices were deleted",
      noRecords: "No invoices found",
    },
  },
};

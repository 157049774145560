import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import type {
  Schedule,
  ScheduleTrainingPlanItem,
  TrainingEvent,
} from "@/store/interfaces/TrainingEvent";
import { defaultSleepDiary } from "@/store/interfaces/TrainingEventOverride";
import type { TrainingEventOverride } from "@/store/interfaces/TrainingEventOverride";
import _ from "lodash";

@Module
export default class TrainingEventModule extends VuexModule {
  scheduledEvents: ScheduleTrainingPlanItem[] = [];

  get getScheduledEvents(): ScheduleTrainingPlanItem[] {
    return this.scheduledEvents;
  }
  @Mutation
  [Mutations.SET_LOADED_SCHEDULED_ITEMS](items: ScheduleTrainingPlanItem[]) {
    items = items.map((planItem: ScheduleTrainingPlanItem) => {
      planItem.training_week_override = _.merge(
        {
          id: null,
          training_event_id: planItem.training_event.id,
          training_week_id: planItem.training_week.id,
          note: "",
          content: planItem.training_week.content,
          additional_data: {
            sleep_diary: _.cloneDeep(defaultSleepDiary),
          },
          note_displayed: 0,
        } as TrainingEventOverride,
        planItem.training_week_override
      );
      planItem.training_week_override.note_changed = false;
      planItem.training_week_override.diary_changed = false;
      return planItem;
    });
    this.scheduledEvents = items;
  }
  @Action
  [Actions.GET_SCHEDULED_ITEMS_BY_WEEK](payload: {
    weekNr: number;
    year: number;
    userId: number;
  }) {
    ApiService.setHeader();
    return new Promise<ScheduleTrainingPlanItem[]>((resolve, reject) => {
      ApiService.post(`training-event/get-scheduled-items-by-week`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_SCHEDULED_ITEMS,
              data.scheduledEvents
            );
            resolve(data.scheduledEvents);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingEvents.errors.cantGetSchedule"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_SCHEDULE](trainingEvent: TrainingEvent) {
    ApiService.setHeader();
    return new Promise<Schedule>((resolve, reject) => {
      ApiService.post(`training-event/get-schedule`, trainingEvent)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.schedule);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingEvents.errors.cantGetSchedule"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.OVERRIDE_TRAINING_WEEK](
    trainingEventOverride: TrainingEventOverride
  ) {
    ApiService.setHeader();
    return new Promise<Schedule>((resolve, reject) => {
      ApiService.post(`training-event/override-week`, trainingEventOverride)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.trainingEventOverride);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingEvents.errors.cantGetTrainingEventOverride"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.MARK_NOTE_AS_DISPLAYED](
    trainingEventOverride: TrainingEventOverride
  ) {
    ApiService.setHeader();
    return new Promise<Schedule>((resolve, reject) => {
      ApiService.post(
        `training-event/mark-note-as-displayed`,
        trainingEventOverride
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.dispatch(Actions.LOAD_CLIENTS);
            resolve(data.trainingEventOverride);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingEvents.errors.cantGetTrainingEventOverride"
          );
          reject();
        });
    });
  }
}

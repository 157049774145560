export const unitEn = {
  units: {
    title: "Units",
    toolTips: {
      edit: "Edit unit {unit}",
      delete: "Delete unit {unit}",
    },
    editTitle: "Edit unit",
    newTitle: "New unit",
    addUnit: "Add unit",
    errors: {
      notFound: "Unit {id} not found",
      notYourRole: "Cant access data for unit {id}",
      nameExists: "Unit named `{name}` already exists",
      commonGET_UNIT_BY_ID: "Error retrieving unit data",
      commonDELETE_UNIT_BY_ID: "Error deleting unit",
      commonDELETE_UNITS: "Error deleting units",
      cantGetUnits: "Failed to fetch units data",
    },
    form: {
      addAccesory: "Add unit",
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      code: "Code",
      description: "Description",
      image: "Image",
      type: "Type",
      icon: "Icon",
      company: "Companies",
      programs: "Programs",
      unitChanged: "Unit was stored",
      unitChangedFail: "Failed to store unit",
      unitAdded: "New unit was created",
      unitAddFail: "Failed to add new unit",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        type: "Type",
        icon: "Icon",
        company: "Companies",
        actions: "Actions",
      },
      deleteUnitQuestion:
        "Do you really want to delete unit <strong>{unit}</strong>?",
      searchUnits: "Search units",
      unitDeleted: "Unit {unit} was deleted",
      unitsDeleted: "Units were deleted",
      noRecords: "No units found",
    },
    types: {
      time: "time",
      count: "count",
    },
  },
};

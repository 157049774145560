import React from "react";
import { usePressAllGame } from "./usePressAllGame";

const PressAllGame = ({ onGameEnd, settings }) => {
  const { gridComponent, showStartButton, handleStartGame, timer } =
    usePressAllGame(settings, onGameEnd);

  return (
    <div className="App">
      <h1>Zmáčkni vše (Rychlé dlaždice)</h1>

      {gridComponent}

      {showStartButton && <button onClick={handleStartGame}>Start Game</button>}
      {!showStartButton && <h2>Zbývá čas: {timer}</h2>}
    </div>
  );
};

export default PressAllGame;

// presets.js
export const presets = [
  {
    redLightTime: 2000,
    orangeLightTime: 1000,
    greenLightTime: 500,
    totalRounds: 12,
  },
  {
    redLightTime: 1800,
    orangeLightTime: 800,
    greenLightTime: 500,
    totalRounds: 14,
  },
  {
    redLightTime: 1600,
    orangeLightTime: 600,
    greenLightTime: 400,
    totalRounds: 16,
  },
  {
    redLightTime: 1400,
    orangeLightTime: 500,
    greenLightTime: 300,
    totalRounds: 18,
  },
  {
    redLightTime: 1200,
    orangeLightTime: 400,
    greenLightTime: 300,
    totalRounds: 20,
  },
  {
    redLightTime: 1000,
    orangeLightTime: 300,
    greenLightTime: 200,
    totalRounds: 22,
  },
  {
    redLightTime: 800,
    orangeLightTime: 200,
    greenLightTime: 200,
    totalRounds: 24,
  },
  {
    redLightTime: 600,
    orangeLightTime: 200,
    greenLightTime: 100,
    totalRounds: 26,
  },
  {
    redLightTime: 400,
    orangeLightTime: 100,
    greenLightTime: 100,
    totalRounds: 28,
  },
  {
    redLightTime: 300,
    orangeLightTime: 100,
    greenLightTime: 100,
    totalRounds: 30,
  },
];

export const sportSk = {
  sports: {
    toolTips: {
      edit: "Upraviť šport {sport}",
      delete: "Odstrániť šport {sport}",
    },
    editTitle: "Upraviť šport",
    newTitle: "Nový šport",
    addSport: "Pridať šport",
    errors: {
      notFound: "Šport s ID {id} nebol nájdený",
      notYourRole: "Nemáte oprávnenie zobraziť šport s ID {id}",
      nameExists: "Šport s názvom `{name}` už existuje",
      commonGET_SPORT_BY_ID: "Chyba pri získavaní údajov o športe",
      commonDELETE_SPORT_BY_ID: "Chyba pri odstraňovaní športu",
      commonDELETE_SPORTS: "Nepodarilo sa odstrániť šport",
      cantGetSports: "Chyba pri získavaní údajov o športoch",
    },
    form: {
      name: "Názov",
      company: "Firma",
      sportChanged: "Šport bol upravený",
      sportChangedFail: "Nepodarilo sa upraviť šport",
      sportAdded: "Nový šport bol vytvorený",
      sportAddFail: "Nepodarilo sa vytvoriť nový šport",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firma",
        actions: "Akcie",
      },
      deleteSportQuestion:
        "Naozaj chcete odstrániť šport <strong>{sport}</strong>?",
      searchSports: "Hľadať šport",
      sportDeleted: "Šport {sport} bol odstránený",
      sportsDeleted: "Športy boli odstránené",
      noRecords: "Neboli nájdené žiadne športy",
    },
  },
};

export const toolCs = {
  tools: {
    title: "Nástroje",
    toolTips: {
      edit: "Upravit nástroj {tool}",
      delete: "Odstranit nástroj {tool}",
      gameplays: "Zbývající počet denních pokusů: {counts}",
    },
    editTitle: "Upravit nástroj",
    newTitle: "Nový nástroj",
    addTool: "Přidat nástroj",
    errors: {
      notFound: "Nástroj id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit nástroj id {id}",
      nameExists: "Nástroj s nazvem `{name}` již existuje",
      codeExists: "Nástroj s kodem `{code}` již existuje",
      commonGET_TOOL_BY_ID: "Chyba při získávání dat o nástrojích",
      commonDELETE_TOOL_BY_ID: "Chyba při odstraňování nástroje",
      commonDELETE_TOOLS: "Nepodařilo se odstranit nástroj",
      cantGetTools: "Chyba při získávání dat o nástrojích",
      cantStoreGameResults: "Chyba při ukládání výsledků hry",
      cantGetGameResults: "Chyba při získávání dat o výsledcích her",
    },
    settings: {
      title: "Nastavení",
      keys: {
        bpm: "BPM",
        volume: "Hlasitost",
        size: "Velikost",
        rows: "Řádky",
        cols: "Sloupce",
        level: "Level",
        fieldCount: "Počet prvků",
        amountOfCharacters: "Počet znaků",
        storeResults: "Uložit výsledek",
        customDescription: "Popis",
        dailyAttempts: "Počet pokusů / den",
      },
    },
    types: {
      game: "Hra",
      measurement: "Měření",
      other: "Ostatní",
    },
    form: {
      addTool: "Přidat nástroj",
      name: "Název",
      client_name: "Název pro klienta",
      description: "Popis",
      code: "Kód",
      type: "Typ",
      settings: "Nastavení",
      company: "Firmy",
      toolChanged: "Nástroj byl upraven",
      toolChangedFail: "Nepodařilo se upravit nástroj",
      toolAdded: "Nový nástroj byl vytvořen",
      toolAddFail: "Nepodařilo se vytvořit nový nástroj",
      gameResultStored: "Výsledek hry byl uložen",
    },
    table: {
      header: {
        name: "Název",
        code: "Kód",
        type: "Typ",
        company: "Firmy",
        actions: "Akce",
      },
      deleteToolQuestion:
        "Opravdu chcete odstranit nástroj <strong>{tool}</strong>?",
      searchTools: "Hledat nástroj",
      toolDeleted: "Nástroj {tool} byl odstraněn",
      toolsDeleted: "Nástroje byly odstraněny",
      noRecords: "Nebyly nalezeny žádné nástroje",
    },
    modules: {
      import_leads: {
        form: {
          file: "Importní soubor (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Úkol 1",
            task2: "Úkol 2",
            task3: "Úkol 3",
            task4: "Úkol 4",
            task5: "Úkol 5",
            error: "Chyba",
          },
        },
        import: "Importovat",
        new_import: "Nový import",
        results: "Výsledek importu",
        status: "Status",
        validValues: "Validní hodnoty",
        errorValues: "Chybné hodnoty",
        validations: {
          emailExists: "Uživatel s emailem {email} neexistuje",
        },
        result: {
          reasons: {
            value_inserted: "Uživatel vložen",
            value_not_inserted: "Uživatel nebyl vložen",
            value_exists: "Hodnota pro zadaný den již existuje",
          },
        },
      },
      export_diagnostics: {
        form: {
          dateFrom: "Datum od",
          dateTo: "Datum do",
        },
        export: "Exportovat",
        result: "Výsledek",
      },
      import_diagnostics: {
        form: {
          file: "Importní soubor (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Úkol 1",
            task2: "Úkol 2",
            task3: "Úkol 3",
            task4: "Úkol 4",
            task5: "Úkol 5",
            error: "Chyba",
            dominance_eye: "Oko",
            dominance_hand: "Ruka",
            dominance_leg: "Noha",
            dominance_ear: "Rytmus",
            eyesight_flexibility_both_eyes: "Flexibilita",
            eyesight_accommodation_right_eye: "Akomodace P",
            eyesight_accommodation_left_eye: "Akomodace L",
          },
        },
        import: "Importovat",
        new_import: "Nový import",
        results: "Výsledek importu",
        status: "Status",
        validValues: "Validní hodnoty",
        errorValues: "Chybné hodnoty",
        type: "Typ",
        date: "Datum měření",
        valueTypes: {
          s2board_measurements: "Měření na desce SensesBoard",
          in_office_measurements: "Vyšetření funkčnosti okohybných svalů",
          eye_flexibility_measurements: "Flexibilita zraku",
          eye_stability_measurements: "Stabilita zraku",
        },
        validations: {
          s2board_measurements: {
            email_not_found: "Uživatel s emailem {email} neexistuje",
          },
        },
        result: {
          reasons: {
            value_inserted: "Hodnota vložena",
            value_exists: "Hodnota pro zadaný den již existuje",
            value_not_inserted: "Hodnotu se nepodařilo vložit",
          },
        },
      },
      sleep_diary: {
        title: "Spánkový deník",
        days: {
          1: "Po",
          2: "Út",
          3: "St",
          4: "Čt",
          5: "Pá",
          6: "So",
          7: "Ne",
        },
        rows: {
          bed_time_at: {
            title: "Čas ulehnutí do postele",
            note: "Jdu si lehnout, ale ještě spát nemusím (např. dívám se na TV, čtu si)",
          },
          lights_off_at: {
            title: "Čas zhasnutí",
            note: "Opravdu čas, kdy jsem připraven/a spát",
          },
          fall_asleep_in: {
            title: "Trvání usnutí",
            note: "Doba, za kterou jsem usnul/a",
          },
          wake_up_at: {
            title: "Čas probuzení",
            note: "Doba, kdy jsem se probudil/a (mohu ještě zůstat v posteli)",
          },
          of_bed_at: {
            title: "Čas vstávání",
            note: "Doba, kdy jsem vstal/a z postele",
          },
          awake_moments_count: {
            title: "Počet nočních probuzení",
            note: "Kolikrát jsem se za noc vzbudil/a a pamatuji si to (např. nemohl/a jsem poté usnout)",
          },
          time_in_bed: {
            title: "Doba strávená v posteli",
            note: "Doba mezi ulehnutím do postele a čas vstávání. Vypočtu z: čas ulehnutí do postele a čas vstávání (jdu si lehnout ve 22:00, vstanu v 6:00 – v posteli jsem strávil 8 hodin)",
          },
        },
      },
    },
  },
};

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { NormativeIndexLegendItem } from "@/store/interfaces/Statistics";

@Module
export default class SensesBoardModule extends VuexModule {
  NiLegend: NormativeIndexLegendItem[] = [];

  get getNiLegend(): NormativeIndexLegendItem[] {
    return this.NiLegend;
  }

  @Mutation
  [Mutations.SET_NI_LEGEND](data) {
    this.NiLegend = data;
  }

  @Action
  [Actions.LOAD_NORMATIVE_INDEX_LEGEND]() {
    ApiService.setHeader();
    return new Promise<NormativeIndexLegendItem[]>((resolve, reject) => {
      ApiService.get("senses-board/get-ni-legend")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_NI_LEGEND, data["result"]);
            resolve(data["result"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantLoadNiLegend"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.EXPORT_SB_STATS](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("senses-board/export-stats", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["filename"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantExportSensesBoardStats"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.EXPORT_SB_STATS_INDIVIDUAL_POTENTIAL](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("senses-board/export-stats-potential-individual", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["filename"]);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantExportSensesBoardStats"
          );
          reject();
        });
    });
  }
}

export const userGameDataCs = {
  userGameData: {
    errors: {
      cantGetData: "Chyba při získávání herních dat",
    },
    table: {
      header: {
        created_at: "Vytvořeno",
        level: "Level",
        data: "Data",
      },
      searchUserGameData: "Hledat data",
      noRecords: "Nebyly nalezeny žádná data",
    },
  },
};

import React, { useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import { useCountingGame } from "./useCountingGame";

const CountingGame = ({ onGameEnd, settings }) => {
  const gridRef = useRef(null);
  const { gridContent, example, startGame, handleCellClick, isRunning } =
    useCountingGame(settings, onGameEnd, gridRef);

  return (
    <div className="App">
      <h1>Počítání</h1>

      <Grid
        ref={gridRef}
        gridContent={gridContent}
        onCellClick={handleCellClick}
      />

      {!isRunning && <button onClick={startGame}>Start Game</button>}

      {isRunning && (
        <div>
          <h2>Vyberte správný výsledek:</h2>
          <h2>
            {example.num1} + {example.num2}
          </h2>
        </div>
      )}
    </div>
  );
};
export default CountingGame;

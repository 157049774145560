import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import Color from "../../Components/Color";

export const useReactionTimeGame = (settings, onGameEnd) => {
  const {
    rows,
    cols,
    redLightTime,
    orangeLightTime,
    greenLightTime,
    totalRounds,
  } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [gridColor, setGridColor] = useState(Color.Red);

  const [startTime, setStartTime] = useState(null);
  const [clickTimes, setClickTimes] = useState([]);
  const [showStartButton, setShowStartButton] = useState(true);
  const [waitingForClick, setWaitingForClick] = useState(false);

  const [currentRound, setCurrentRound] = useState(0);

  const timeouts = useRef([]);

  useEffect(() => {
    if (currentRound === totalRounds && !waitingForClick) {
      onGameEnd({
        games: [
          {
            id: "ReactionTimeGame",
            clickTimes: clickTimes,
          },
        ],
      });
    } else if (!showStartButton && currentRound < totalRounds) {
      const redTimeout = setTimeout(() => {
        setGridColor(Color.Orange);

        const orangeTimeout = setTimeout(() => {
          setGridColor(Color.Teal);
          setStartTime(Math.floor(performance.now()));
          setWaitingForClick(true);

          const greenTimeout = setTimeout(() => {
            setWaitingForClick((prevWaitingForClick) => {
              if (prevWaitingForClick) {
                setClickTimes((prevClickTimes) => [...prevClickTimes, null]);
                setGridColor(Color.Red);
                if (currentRound < totalRounds) {
                  setCurrentRound((prevRound) => prevRound + 1);
                }
              }
              return false;
            });
          }, greenLightTime);

          timeouts.current.push(greenTimeout);
        }, orangeLightTime);

        timeouts.current.push(orangeTimeout);
      }, redLightTime);

      timeouts.current.push(redTimeout);
    }

    return () => {
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      timeouts.current = [];
    };
  }, [showStartButton, clickTimes, currentRound]);

  useEffect(() => {
    setGridContent(
      Array.from({ length: rows }, () =>
        Array.from({ length: cols }, () => (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: gridColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ))
      )
    );
  }, [gridColor]);

  const handleStartGame = () => {
    setShowStartButton(false);
    setCurrentRound(0);
  };

  const handleCellClick = () => {
    if (waitingForClick) {
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      const endTime = Math.floor(performance.now());
      const reactionTime = endTime - startTime;
      setClickTimes([...clickTimes, reactionTime]);
      setWaitingForClick(false);
      setGridColor(Color.Red);

      if (currentRound < totalRounds) {
        setCurrentRound(currentRound + 1);
      }
    }
  };

  const gridComponent = (
    <Grid gridContent={gridContent} onCellClick={handleCellClick} />
  );

  return { gridComponent, showStartButton, handleStartGame, handleCellClick };
};

export const registrationTokenCs = {
  registrationTokens: {
    toolTips: {
      edit: "Upravit registrační kód {token}",
      delete: "Odstranit registrační kód {token}",
      link: "Zobrazit odkaz pro registrační kód {token}",
    },
    editTitle: "Upravit registrační kód",
    newTitle: "Nový registrační kód",
    addRegistrationToken: "Přidat registrační kód",
    errors: {
      notFound: "Registrační kód id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit registrační kód id {id}",
      nameExists: "Registrační kód `{token}` již existuje",
      registrationTokenAddFail: "Nepodařilo se vytvořit registrační kód",
      commonGET_REGISTRATION_TOKEN_BY_ID:
        "Chyba při získávání dat o registračním kódu",
      commonDELETE_REGISTRATION_TOKEN_BY_ID:
        "Chyba při odstraňování registračního kódu",
      commonDELETE_REGISTRATION_TOKENS:
        "Nepodařilo se odstranit registrační kód",
      cantGetRegistrationTokens:
        "Chyba při získávání dat o registračních kódech",
    },
    form: {
      token: "Kód",
      company: "Firma",
      program: "Produkt",
      events: "Události",
      totalUses: "Počet použití",
      userGroup: "Skupina",
      registrationTokenChanged: "Registrační kód byl upraven",
      registrationTokenChangedFail: "Nepodařilo se upravit registrační kód",
      registrationTokenAdded: "Nový registrační kód byl vytvořen",
      registrationTokenAddFail: "Nepodařilo se vytvořit nový registrační kód",
    },
    modal: {
      titleAdd: "Přiřadit nový registrační kód",
      titleUpdate: "Upravit registrační kód `{token}`",
      titleLink: "Odkaz pro registrační kód `{token}`",
    },
    table: {
      header: {
        token: "Kód",
        company: "Firma",
        program: "Produkt",
        userGroup: "Skupina",
        events: "Události",
        actions: "Akce",
        uses: "Počet použití",
      },
      deleteRegistrationTokenQuestion:
        "Opravdu chcete odstranit registrační kód <strong>{token}</strong>?",
      searchRegistrationTokens: "Hledat registrační kód",
      registrationTokenDeleted:
        "Registrační kód {registrationToken} byl odstraněn",
      registrationTokensDeleted: "Registrační kódy byly odstraněny",
      noRecords: "Nebyly nalezeny žádné Registrační kódy",
    },
    events: {
      S2boardEnable: {
        title: "Aktivace SBoard",
      },
      InOfficeEnable: {
        title: "Aktivace Diag. v ordinaci",
      },
      EyeStabilityEnable: {
        title: "Aktivace Diag. stabilita zraku",
      },
      EyeFlexibilityEnable: {
        title: "Aktivace Diag. rychlost okohybnych svalů",
      },
    },
  },
};

import React from "react";
import { useReactionTimeGame } from "./useReactionTimeGame";
import { presets } from "./presets";

const ReactionTimeGame = ({ onGameEnd, settings }) => {
  const gameSettings = { ...settings, ...presets[settings.level - 1] };
  const { gridComponent, showStartButton, handleStartGame } =
    useReactionTimeGame(gameSettings, onGameEnd);

  return (
    <div className="App">
      <h1>Semafor</h1>

      {gridComponent}

      {showStartButton && <button onClick={handleStartGame}>Start Game</button>}
    </div>
  );
};

export default ReactionTimeGame;

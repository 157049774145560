export const exerciseEn = {
  exercises: {
    toolTips: {
      edit: "Edit exercise {exercise}",
      clone: "Clone exercise {exercise}",
      delete: "Delete exercise {exercise}",
    },
    editTitle: "Edit exercise",
    newTitle: "New exercise",
    cloneTitle: "Copy of exercise `{exercise}`",
    addExercise: "Add exercise",
    errors: {
      notFound: "Exercise {id} not found",
      notYourRole: "Cant access data for exercise {id}",
      nameExists: "Exercise named `{name}` already exists",
      commonGET_EXERCISE_BY_ID: "Error retrieving exercise data",
      commonDELETE_EXERCISE_BY_ID: "Error deleting exercise",
      commonDELETE_EXERCISES: "Error deleting exercises",
      cantGetExercises: "Failed to fetch exercises data",
    },
    form: {
      name: "Name",
      client_name: "Name for client",
      color: "Color",
      code: "Code",
      sports: "Sports",
      description: "Implementation description",
      short_description: "Short description",
      setup_description: "Setup description",
      repeats: "Nr. of repeats",
      repeats_unit: "Repeats unit",
      duration: "Duration of exercise",
      duration_unit: "Unit",
      accessories: "Accessories",
      bodyParts: "Body parts",
      exerciseCategory: "Category",
      image: "Image",
      icon: "Icon",
      video: "Video",
      company: "Companies",
      exerciseChanged: "Exercise was stored",
      exerciseChangedFail: "Failed to store exercise",
      exerciseAdded: "New exercise was created",
      exerciseAddFail: "Failed to add new exercise",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        icon: "Icon",
        accessories: "Accessories",
        exerciseCategory: "Category",
        company: "Companies",
        actions: "Actions",
      },
      deleteExerciseQuestion:
        "Do you really want to delete  exercise <strong>{exercise}</strong>?",
      searchExercises: "Search exercises",
      exerciseDeleted: "Exercise {exercise} was deleted",
      exercisesDeleted: "Exercises were deleted",
      noRecords: "No exercises found",
    },
  },
};

export const languageEn = {
  languages: {
    languageSelector: {
      title: "Select a language",
    },
    toolTips: {
      edit: "Edit language {language}",
      delete: "Delete language {language}",
    },
    editTitle: "Edit language",
    newTitle: "New language",
    addLanguage: "Add language",
    errors: {
      notFound: "Language {id} not found",
      notYourRole: "Cant access data for language {id}",
      nameExists: "Language named `{name}` already exists",
      commonGET_LANGUAGE_BY_ID: "Error retrieving language data",
      commonDELETE_LANGUAGE_BY_ID: "Error deleting language",
      commonDELETE_LANGUAGES: "Error deleting languages",
      cantGetLanguages: "Failed to fetch languages data",
    },
    form: {
      name: "Name",
      code: "Code",
      flag: "Flag",
      languageChanged: "Language was stored",
      languageChangedFail: "Failed to store language",
      languageAdded: "New language was created",
      languageAddFail: "Failed to add new language",
    },
    table: {
      header: {
        name: "Name",
        code: "Code",
        flag: "Flag",
        actions: "Actions",
      },
      deleteLanguageQuestion:
        "Do you really want to delete  language <strong>{language}</strong>?",
      searchLanguages: "Search languages",
      languageDeleted: "Language {language} was deleted",
      languagesDeleted: "Languages were deleted",
      noRecords: "No languages found",
    },
  },
};

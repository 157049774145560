import React from "react";
import { Ellipse, Rectangle, Triangle } from "./Shapes";
import Color from "../../Components/Color";

const scenarios = [
  (gridSize, uniqueIndex, amountOfCharacters) => {
    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();
    let filledCells = 0;

    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    const { SvgComponent: UniqueSvg, color: uniqueColor } =
      getRandomSvgAndColor(true, CommonSvg);
    grid[uniqueIndex] = {
      SvgComponent: UniqueSvg,
      color: uniqueColor,
      isUnique: true,
    };

    return Array.from({ length: gridSize }, (_, rowIndex) =>
      grid
        .slice(rowIndex * gridSize, (rowIndex + 1) * gridSize)
        .map((cell) => ({
          ...cell,
          content: cell.SvgComponent ? (
            <cell.SvgComponent fill={cell.color} />
          ) : (
            " "
          ),
        }))
    );
  },

  (gridSize, uniqueIndex, amountOfCharacters) => {
    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();
    let filledCells = 0;

    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    let UniqueSvg, uniqueColor;
    do {
      ({ SvgComponent: UniqueSvg, color: uniqueColor } = getRandomSvgAndColor(
        true,
        CommonSvg
      ));
    } while (UniqueSvg === CommonSvg);

    grid[uniqueIndex] = {
      SvgComponent: UniqueSvg,
      color: uniqueColor,
      isUnique: true,
    };

    return Array.from({ length: gridSize }, (_, rowIndex) =>
      grid
        .slice(rowIndex * gridSize, (rowIndex + 1) * gridSize)
        .map((cell) => ({
          ...cell,
          content: cell.SvgComponent ? (
            <cell.SvgComponent
              fill={cell.color}
              style={cell.isUnique ? { transform: "rotate(45deg)" } : null}
            />
          ) : (
            " "
          ),
        }))
    );
  },

  (gridSize, uniqueIndex, amountOfCharacters) => {
    const { SvgComponent: CommonSvg, color: commonColor } =
      getRandomSvgAndColor();
    let filledCells = 0;

    let grid = Array.from({ length: gridSize * gridSize }, () => ({
      SvgComponent: null,
      color: "transparent",
      isUnique: false,
    }));

    while (filledCells < amountOfCharacters - 1) {
      let pos = Math.floor(Math.random() * gridSize * gridSize);
      if (!grid[pos].SvgComponent && pos !== uniqueIndex) {
        grid[pos] = {
          SvgComponent: CommonSvg,
          color: commonColor,
          isUnique: false,
        };
        filledCells++;
      }
    }

    grid[uniqueIndex] = {
      SvgComponent: CommonSvg,
      color: commonColor,
      isUnique: true,
    };

    return Array.from({ length: gridSize }, (_, rowIndex) =>
      grid
        .slice(rowIndex * gridSize, (rowIndex + 1) * gridSize)
        .map((cell) => ({
          ...cell,
          content: cell.SvgComponent ? (
            <cell.SvgComponent
              fill={cell.color}
              style={cell.isUnique ? { transform: "scale(0.5)" } : {}}
            />
          ) : (
            " "
          ),
        }))
    );
  },
];

const getRandomSvg = () => {
  const svgs = [Ellipse, Rectangle, Triangle];
  return svgs[Math.floor(Math.random() * svgs.length)];
};

const getRandomSvgAndColor = (excludeShape) => {
  let SvgComponent;
  do {
    SvgComponent = getRandomSvg();
  } while (SvgComponent === excludeShape);

  const color = Color.getRandomColor();
  return { SvgComponent, color };
};

export default scenarios;
